<template>
  <div>
    <section class="py-5 breadcrumbsSection">
      <div class="container">
        <ol class="breadcrumbs mb-0">
          <li><a href="/cn/" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
          <li><a href="/#/news" class="">{{this.$t('Pathyem.Home')}}</a></li>
          <li v-html="Newsdata.Menus.Menus"></li>
          <li v-html="Newsdata.News_list1.H5text"></li>
        </ol>
      </div>
    </section>
    <section class="text-left">
      <div class="container">
        <div class="row justify-content-start">
          <div class="col-md-9">
            <h1 class="h2" v-html="Newsdata.News_list1.H5text"></h1><em v-html="Newsdata.News_list1.Datetext"></em>
          </div>
        </div>
      </div>
    </section>
    <section class="text-center">
      <div class="container"><img :src="`${$FilesImg}${Newsdata.Imgsrc}`"
          :alt="Newsdata.News_list1.H5text" class="w-100 border--round"></div>
    </section>
    <section>
      <div class="container sub-lead" v-html="Newsdata.Text">
      </div>
    </section>
    <div>
      <Foorm></Foorm>
    </div>
  </div>
</template>
<script>
import Foorm from '../components/Foorm'
export default {
  data () {
    return {
      Newsdata: {
        Imgsrc: '',
        Text: '',
        Menus: [],
        News_list1: []
      }
    }
  },
  created () {
    this.data()
  },
  components: {
    Foorm
  },
  mounted () {

  },
  methods: {
    async data () {
      const { data: res } = await this.$http.get(`Newsdata?Id=${this.$route.params.id}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Newsdata = res.response
    },

    select_menus (data) {
      this.queryInfo.Id = data
      this.News()
      this.News_Menuns()
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
