<template>
  <div  class="article__body">
    <section  data-overlay="6" class="cover imagebg text-left space--md">
      <div  class="background-image-holder"
        :style="`background: url(${$FilesImg}${Family_data.Backgroundimg1}); opacity: 1;`"><img
           alt="background" :src="`${$FilesImg}${Family_data.Backgroundimg1}`"></div>
      <div  class="container">
        <div  class="row">
          <div  class="col-md-8 col-lg-7">
            <h1 v-html="Family_data.Title"></h1>
            <p  class="lead" v-html="Family_data.Text"></p>
          </div>
        </div>
      </div>
    </section>
    <section  class="py-5 breadcrumbsSection">
      <div  class="container">
        <ol  class="breadcrumbs">
          <li ><a  href="/#/Home" class="router-link-active">首页</a></li>
          <li ><a  href="/#/Career" class="">人才招募</a></li>
          <li ><a  href="/#/Nienyi" class="">年益人</a></li>
          <li v-html="Family_data.Title"></li>
        </ol>
      </div>
    </section>
    <section  class="py-0">
      <div  class="container">
        <div  class="row">
          <div  class="col-lg-1 col-3 text-center">
            <div  class="icon-circle"><i  class="icon icon--lg material-icons"><img
                   alt="Penny Huang" :src="`${$FilesImg}${Family_data.Imgsrc1}`"></i>
            </div>
          </div>
          <div  class="col-lg-11 col-7"><span  class="type--fine-print" v-html="Family_data.Zhiwei"></span>
          <span  class="block color--primary" v-html="Family_data.Name"></span></div>
        </div>
      </div>
    </section>
    <div >
      <div class="_editor-output" id="editor_description_lang_cn">
        <div class="_editor-container">
          <section class="_editor-clip editor-cliptype__param__1">
            <div class="container">
              <p class="sub-lead _editor-field__textarea" v-html="Family_data.Ptext"></p>
            </div>
          </section>
          <section v-for="(item,index) in Family_data.Family_list" :key="index" class="_editor-clip editor-cliptype__title-param__1">
            <div class="container">
              <h2 class="h3 _editor-field__textarea" v-html="item.Title"></h2>
              <p class="sub-lead _editor-field__textarea" v-html="item.Text"></p>
            </div>
          </section>
        </div>
      </div>
    </div>
    <section  data-overlay="6" class="cover imagebg text-left space--md">
      <div  class="background-image-holder"
        :style="`background: url(${$FilesImg}${Family_data.Backgroundimg2}); opacity: 1;`"><img
          alt="background" :src="Family_data.Backgroundimg2"></div>
      <div  class="container">
        <div  class="row">
          <div  class="col-md-8 col-lg-7">
            <h1 v-html="Family_data.Title1"></h1>
            <p  class="lead" v-html="Family_data.Text1"></p><a
               href="/#/Contact" class="btn btn--lg"><span
                class="btn__text" v-html="Family_data.Span"></span></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Family_data: {
        Backgroundimg1: '',
        Title: '',
        Text: '',
        Imgsrc1: '',
        Zhiwei: '',
        Name: '',
        Ptext: '',
        Backgroundimg2: '',
        Title1: '',
        Text1: '',
        Span: '',
        Family_list: []
      }
    }
  },
  created () {
    this.Family()
  },
  mounted () {

  },
  methods: {
    async Family () {
      const { data: res } = await this.$http.get(`Family?Id=${this.$route.params.id}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Family_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
