<template>
  <div>
    <section>
      <div class="container">
        <h2 v-html="Internship_data.H2text"></h2>
            <div v-for="(item,index) in Internship_data.Internship_list1" :key="index">
              <h4 v-html="item.Title"></h4>
              <p v-html="item.Text"></p>
              <br>
            </div>
      </div>
    </section>
    <section class="bg--secondary">
      <div class="container">
        <div class="row">
          <div class="col-md-4 ">
            <h2 v-html="Internship_data.H3text"></h2>
          </div>
          <div class="col-md-8">
            <div v-for="(item,index) in Internship_data.Internship_list2" :key="index">
              <h4 v-html="item.Title"></h4>
              <p v-html="item.Text"></p>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <h2 v-html="Internship_data.H4text"></h2>
        <ul>
          <li v-for="(item,index) in Internship_data.Internship_list3" :key="index" class="clearfix boxed boxed--border p-5">
            <div class="row ">
              <div class="col-sm-2 col-12 text-left"><img :alt="item.Name"
                  :src="`${$FilesImg}${item.Imgsrc}`"></div>
              <div class="col-sm-10 col-12"><strong class="d-block mb-0" v-html="item.Name"></strong><em class="d-block mb-2" v-html="item.Xname"></em>
                <p class="h3" v-html="item.H3text"></p>
                <hr>
                <div class="switchable__text">
                  <ul class="accordion accordion-2 inited">
                    <li>
                      <div ><span :class="item.Vishow?viclasss:viclass" @click="vivis(item)" style="cursor: pointer;" v-html="item.Title"></span></div>
                      <div v-if="item.Vishow == false">
                        <p class="lead" v-html="item.Text"></p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="imageblock switchable h-auto bg--dark">
      <div class="imageblock__content col-lg-6 col-md-4 pos-right">
        <div class="background-image-holder" :style="`background: url(${$FilesImg}${Internship_data.Imgsrc}); opacity: 1;`"><img
            alt="image" :src="`${$FilesImg}${Internship_data.Imgsrc}`"></div>
      </div>
      <div class="container pos-vertical-strat">
        <div class="row">
          <div class="col-lg-5 col-md-7 sub-lead">
            <h3 v-html="Internship_data.H5text"></h3>
            <ul>
              <li class="mt-5 mb-5" v-for="(item,index) in Internship_data.Internship_list4" :key="index">
                <strong v-html="item.Title"></strong>
                <p v-html="item.Text"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data () {
    return {
      viclass: 'h5 el-icon-arrow-up',
      viclasss: 'h5 el-icon-arrow-down',
      Internship_data: {
        Internship_list1: [],
        Internship_list2: [],
        Internship_list3: [],
        Internship_list4: []
      }
    }
  },
  created () {
    this.Internship()
  },
  mounted () {

  },
  methods: {
    async Internship () {
      const { data: res } = await this.$http.get('Internship')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Internship_data = res.response
    },
    vivis (item) {
      item.Vishow = !item.Vishow
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
