<template>
  <div>
    <el-card class="box-card">
      <span class="titletext">人物列表中文</span>
      <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
                <el-table :data="Nienyi_dataCn" border style="width: 100%; margin-bottom: auto; margin: auto;">
                  <el-table-column type="index" width="50" align="center"> </el-table-column>
                  <el-table-column prop="Imgsrc" label="图片" align="center"></el-table-column>
                  <el-table-column label="图片" align="center">
                    <template slot-scope="scope">
                          <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </template>
                  </el-table-column>
                  <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
                  <el-table-column prop="Title" label="标题" align="center"></el-table-column>
                  <el-table-column prop="Text" label="文本" align="center"></el-table-column>
                  <el-table-column prop="Class" label="样式" align="center"></el-table-column>
                  <el-table-column align="center"  width="240"  fixed="right">
                  <template slot="header">
                    <el-button type="primary" @click="list_addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                  </template>
                  <template slot-scope="scope">
                    <el-button type="primary" @click="list1uppfuncn(scope.row)" size="small">修改</el-button>
                    <el-button type="warning" @click="FamilyCn(scope.row.Id)" size="small">编辑</el-button>
                    <el-button type="danger"  @click="Nienyi_delcn(scope.row)" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <el-dialog title="添加中文列" :modal-append-to-body='false' :visible.sync="list_addcn" width="80%">
          <el-form :model="list">
            <el-form-item label="图片">
              <el-upload class="upload-demo" style="text-align: center;" ref="img1" action="" drag :limit="1"
                  :http-request="img1" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="list.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="list.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="样式">
              <el-radio v-model="list.Class" label="feature-large">图在左</el-radio>
              <el-radio v-model="list.Class" label="feature-large switchable">图在右</el-radio>
            </el-form-item>
            <el-form-item label="头部人物大图">
              <el-upload class="upload-demo" style="text-align: center;" ref="img2" action="" drag :limit="1"
                  :http-request="img2" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="人物小图标">
              <el-upload class="upload-demo" style="text-align: center;" ref="img3" action="" drag :limit="1"
                  :http-request="img3" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="底下图">
              <el-upload class="upload-demo" style="text-align: center;" ref="img4" action="" drag :limit="1"
                  :http-request="img4" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="人物职务">
              <el-input v-model="list.Zhiwei" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
              <el-input v-model="list.Ptext" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上标题文">
              <el-input v-model="list.Title1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上文字">
              <el-input v-model="list.Text1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上按钮">
              <el-input v-model="list.Span" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list_addcn = false">取 消</el-button>
              <el-button type="primary"  @click="Nienyi_addcn(list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="添加英文列" :modal-append-to-body='false' :visible.sync="list_adden" width="600px">
          <el-form :model="list">
            <el-form-item label="图片">
              <el-input v-model="list.Imgsrc" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="list.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="list.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="样式">
              <el-radio v-model="list.Class" label="feature-large">图在左</el-radio>
              <el-radio v-model="list.Class" label="feature-large switchable">图在右</el-radio>
            </el-form-item>
            <el-form-item label="头部人物大图">
              <el-input v-model="list.Backgroundimg1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="人物小图标">
              <el-input v-model="list.Imgsrc1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="人物职务">
              <el-input v-model="list.Zhiwei" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="底下图">
              <el-input v-model="list.Backgroundimg2" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
              <el-input v-model="list.Ptext" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上标题文">
              <el-input v-model="list.Title1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上文字">
              <el-input v-model="list.Text1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上按钮">
              <el-input v-model="list.Span" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list_adden = false">取 消</el-button>
              <el-button type="primary"  @click="Nienyi_adden(list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改中文列" :modal-append-to-body='false' :visible.sync="list_uppcn" width="80%">
          <el-form :model="listupp">
            <el-form-item label="图片">
              <el-upload class="upload-demo" style="text-align: center;" ref="img5" action="" drag :limit="1"
                  :http-request="img5" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="listupp.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="listupp.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="listupp.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="样式">
              <el-radio v-model="listupp.Class" label="feature-large">图在左</el-radio>
              <el-radio v-model="listupp.Class" label="feature-large switchable">图在右</el-radio>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list_uppcn = false">取 消</el-button>
              <el-button type="primary"  @click="Nienyi_uppcn(listupp)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改英文列" :modal-append-to-body='false' :visible.sync="list_uppen" width="600px">
          <el-form :model="listupp">
            <el-form-item label="图片">
              <el-input v-model="listupp.Imgsrc" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="listupp.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="listupp.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="listupp.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="样式">
              <el-radio v-model="listupp.Class" label="feature-large">图在左</el-radio>
              <el-radio v-model="listupp.Class" label="feature-large switchable">图在右</el-radio>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list_uppen = false">取 消</el-button>
              <el-button type="primary"  @click="Nienyi_uppen(listupp)" >确 定</el-button>
          </div>
        </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    <el-card class="box-card">
      <span class="titletext">人物列表英文</span>
      <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
                <el-table :data="Nienyi_dataEn" border style="width: 100%; margin-bottom: auto; margin: auto;">
                  <el-table-column type="index" width="50" align="center"> </el-table-column>
                  <el-table-column prop="Imgsrc" label="图片" align="center"></el-table-column>
                  <el-table-column  label="图片" align="center">
                    <template slot-scope="scope">
                          <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </template>
                  </el-table-column>
                  <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
                  <el-table-column prop="Title" label="标题" align="center"></el-table-column>
                  <el-table-column prop="Text" label="文本" align="center"></el-table-column>
                  <el-table-column prop="Class" label="样式" align="center"></el-table-column>
                  <el-table-column align="center"  width="240"  fixed="right">
                  <template slot="header">
                    <el-button type="primary" @click="list_adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                  </template>
                  <template slot-scope="scope">
                    <el-button type="primary" @click="list1uppfunen(scope.row)" size="small">修改</el-button>
                    <el-button type="warning" @click="FamilyEn(scope.row.Id)" size="small">编辑</el-button>
                    <el-button type="danger"  @click="Nienyi_delen(scope.row)" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <el-dialog title="中文详情" :modal-append-to-body='false' :visible.sync="list_listcn" width="80%">
          <el-form :model="Family_dataCn">
            <el-form-item label="头部人物大图">
                <el-image :src="`${$FilesImg}${Family_dataCn.Backgroundimg1}`">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              <el-input v-model="Family_dataCn.Backgroundimg1" disabled autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="头部人物大图">
              <el-upload class="upload-demo" style="text-align: center;" ref="img6" action="" drag :limit="1"
                  :http-request="img6" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="人物小图标">
              <el-image :src="`${$FilesImg}${Family_dataCn.Imgsrc1}`">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
              </el-image>
              <el-input v-model="Family_dataCn.Imgsrc1" disabled autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="人物小图标">
              <el-upload class="upload-demo" style="text-align: center;" ref="img7" action="" drag :limit="1"
                  :http-request="img7" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="人物职务">
              <el-input v-model="Family_dataCn.Zhiwei" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="底下图">
              <el-image :src="`${$FilesImg}${Family_dataCn.Backgroundimg2}`">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              <el-input v-model="Family_dataCn.Backgroundimg2" disabled autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="底下图">
              <el-upload class="upload-demo" style="text-align: center;" ref="img8" action="" drag :limit="1"
                  :http-request="img8" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="文章">
              <el-input v-model="Family_dataCn.Ptext" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上标题文">
              <el-input v-model="Family_dataCn.Title1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上文字">
              <el-input v-model="Family_dataCn.Text1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上按钮">
              <el-input v-model="Family_dataCn.Span" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align:center" class="dialog-footer">
              <el-button type="primary"  @click="Nienyi_listuppcn(Family_dataCn)" >修 改</el-button>
          </div>
          <hr class="w-100 mt-5 mb-5"/>
          <el-table :data="Family_dataCn.Family_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
                  <el-table-column type="index" width="50" align="center"> </el-table-column>
                  <el-table-column prop="Title" label="标题" align="center"></el-table-column>
                  <el-table-column prop="Text" label="文字" align="center"></el-table-column>
                  <el-table-column align="center"  width="240"  fixed="right">
                  <template slot="header">
                    <el-button type="primary" @click="list3addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                  </template>
                  <template slot-scope="scope">
                    <el-button type="primary" @click="list3funcn(scope.row)" size="small">修改</el-button>
                    <el-button type="danger"  @click="Nienyi_list3delcn(scope.row)" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
        </el-dialog>
        <el-dialog title="英文详情" :modal-append-to-body='false' :visible.sync="list_listen" width="80%">
          <el-form :model="Family_dataEn">
            <el-form-item label="头部人物大图">
                <el-image :src="`${$FilesImg}${Family_dataEn.Backgroundimg1}`">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              <el-input v-model="Family_dataEn.Backgroundimg1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="人物小图标">
              <el-image :src="`${$FilesImg}${Family_dataEn.Imgsrc1}`">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
              </el-image>
              <el-input v-model="Family_dataEn.Imgsrc1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="人物职务">
              <el-input v-model="Family_dataEn.Zhiwei" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="底下图">
              <el-image :src="`${$FilesImg}${Family_dataEn.Backgroundimg2}`">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              <el-input v-model="Family_dataEn.Backgroundimg2" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
              <el-input v-model="Family_dataEn.Ptext" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上标题文">
              <el-input v-model="Family_dataEn.Title1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上文字">
              <el-input v-model="Family_dataEn.Text1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图上按钮">
              <el-input v-model="Family_dataEn.Span" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align:center" class="dialog-footer">
              <el-button type="primary"  @click="Nienyi_listuppen(Family_dataEn)" >修 改</el-button>
          </div>
          <hr class="w-100 mt-5 mb-5"/>
          <el-table :data="Family_dataEn.Family_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
                  <el-table-column type="index" width="50" align="center"> </el-table-column>
                  <el-table-column prop="Title" label="标题" align="center"></el-table-column>
                  <el-table-column prop="Text" label="文字" align="center"></el-table-column>
                  <el-table-column align="center"  width="240"  fixed="right">
                  <template slot="header">
                    <el-button type="primary" @click="list3adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                  </template>
                  <template slot-scope="scope">
                    <el-button type="primary" @click="list3funen(scope.row)" size="small">修改</el-button>
                    <el-button type="danger"  @click="Nienyi_list3delen(scope.row)" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
        </el-dialog>
        <el-dialog title="添加中文" :modal-append-to-body='false' :visible.sync="list3addcn" width="60%">
          <el-form :model="list3add">
            <el-form-item label="标题">
              <el-input v-model="list3add.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input type="textarea" :rows="10" v-model="list3add.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list3addcn = false">取 消</el-button>
          <el-button @click="Nienyi_list3addcn(list3add)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="添加英文" :modal-append-to-body='false' :visible.sync="list3adden" width="60%">
          <el-form :model="list3add">
            <el-form-item label="标题">
              <el-input v-model="list3add.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input type="textarea" :rows="10" v-model="list3add.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list3adden = false">取 消</el-button>
          <el-button @click="Nienyi_list3adden(list3add)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改中文" :modal-append-to-body='false' :visible.sync="list3uppcn" width="60%">
          <el-form :model="list3upp">
            <el-form-item label="标题">
              <el-input v-model="list3upp.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input type="textarea" :rows="10" v-model="list3upp.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list3uppcn = false">取 消</el-button>
          <el-button @click="Nienyi_list3uppcn(list3upp)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改英文" :modal-append-to-body='false' :visible.sync="list3uppen" width="60%">
          <el-form :model="list3upp">
            <el-form-item label="标题">
              <el-input v-model="list3upp.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input type="textarea" :rows="10" v-model="list3upp.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list3uppen = false">取 消</el-button>
          <el-button @click="Nienyi_list3uppen(list3upp)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Nienyi_dataCn: [],
      Nienyi_dataEn: [],
      list_addcn: false,
      list_adden: false,
      listuppcn: false,
      listuppen: false,
      list: {
        Id: 0,
        Imgsrc: '',
        Name: '',
        Title: '',
        Text: '',
        Class: '',
        Backgroundimg1: '',
        Imgsrc1: '',
        Zhiwei: '',
        Ptext: '',
        Backgroundimg2: '',
        Title1: '成为我们的伙伴',
        Text1: '我们深信，是卓越的团队，造就了今天的年益，<br>我们不是在征人，而是在寻找一起打拼未来的好伙伴。',
        Span: '加入年益'
      },
      listupp: {
        Id: 0,
        Cid: 0,
        Imgsrc: '',
        Name: '',
        Title: '',
        Text: '',
        Class: '',
        Backgroundimg1: '',
        Imgsrc1: '',
        Zhiwei: '',
        Ptext: '',
        Backgroundimg2: '',
        Title1: '成为我们的伙伴',
        Text1: '我们深信，是卓越的团队，造就了今天的年益，<br>我们不是在征人，而是在寻找一起打拼未来的好伙伴。',
        Span: '加入年益'
      },
      Family_dataCn: {
        Id: 0,
        Cid: 0,
        Backgroundimg1: '',
        Title: '',
        Text: '',
        Imgsrc1: '',
        Zhiwei: '',
        Name: '',
        Ptext: '',
        Backgroundimg2: '',
        Title1: '',
        Text1: '',
        Span: '',
        Family_list: []
      },
      Family_dataEn: {
        Id: 0,
        Cid: 0,
        Backgroundimg1: '',
        Title: '',
        Text: '',
        Imgsrc1: '',
        Zhiwei: '',
        Name: '',
        Ptext: '',
        Backgroundimg2: '',
        Title1: '',
        Text1: '',
        Span: '',
        Family_list: []
      },
      list_listcn: false,
      list_listen: false,
      imgfile1: '',
      imgfile2: '',
      imgfile3: '',
      imgfile4: '',
      list_uppcn: false,
      list_uppen: false,
      list3add: {
        Cid: 0,
        Id: 0,
        Text: '',
        Title: ''
      },
      list3upp: {
        Cid: 0,
        Id: 0,
        Text: '',
        Title: ''
      },
      list3addcn: false,
      list3adden: false,
      list3uppcn: false,
      list3uppen: false
    }
  },
  created () {
    this.NienyiCn()
    this.NienyiEn()
  },
  mounted () {

  },
  methods: {
    async NienyiCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Nienyi`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Nienyi_dataCn = res.response
    },
    async NienyiEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Nienyi`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Nienyi_dataEn = res.response
    },
    async FamilyCn (row) {
      const { data: res } = await this.$http.get(`${this.$httpcn}Family?Id=${row}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Family_dataCn = res.response
      this.list_listcn = true
    },
    async FamilyEn (row) {
      const { data: res } = await this.$http.get(`${this.$httpen}Family?Id=${row}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Family_dataEn = res.response
      this.list_listen = true
    },
    listuppcnfun (row) {
      this.listupp = row
      this.listuppcn = true
    },
    listuppenfun (row) {
      this.listupp = row
      this.listuppen = true
    },
    img1 (param) {
      this.imgfile1 = param.file
    },
    img2 (param) {
      this.imgfile2 = param.file
    },
    img3 (param) {
      this.imgfile3 = param.file
    },
    img4 (param) {
      this.imgfile4 = param.file
    },
    async Nienyi_addcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.img1.submit()
      this.$refs.img2.submit()
      this.$refs.img3.submit()
      this.$refs.img4.submit()
      var File = new FormData()
      File.append('Img1', this.imgfile1)
      File.append('Img2', this.imgfile2)
      File.append('Img3', this.imgfile3)
      File.append('Img4', this.imgfile4)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Class', row.Class)
      File.append('Text', row.Text)
      File.append('Zhiwei', row.Zhiwei)
      File.append('Ptext', row.Ptext)
      File.append('Title1', row.Title1)
      File.append('Text1', row.Text1)
      File.append('Span', row.Span)
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_addcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.imgfile1 = ''
      this.imgfile2 = ''
      this.imgfile3 = ''
      this.imgfile4 = ''
      this.$refs.img1.clearFiles()
      this.$refs.img2.clearFiles()
      this.$refs.img3.clearFiles()
      this.$refs.img4.clearFiles()
      this.NienyiCn()
      this.list_addcn = false
    },
    async Nienyi_adden (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Imgsrc', row.Imgsrc)
      File.append('Imgsrc1', row.Imgsrc1)
      File.append('Backgroundimg1', row.Backgroundimg1)
      File.append('Backgroundimg2', row.Backgroundimg2)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Class', row.Class)
      File.append('Text', row.Text)
      File.append('Zhiwei', row.Zhiwei)
      File.append('Ptext', row.Ptext)
      File.append('Title1', row.Title1)
      File.append('Text1', row.Text1)
      File.append('Span', row.Span)
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_adden`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.NienyiEn()
      this.list_adden = false
    },
    img5 (param) {
      this.imgfile1 = param.file
    },
    list1uppfuncn (row) {
      this.listupp = row
      this.list_uppcn = true
    },
    list1uppfunen (row) {
      this.listupp = row
      this.list_uppen = true
    },
    async Nienyi_uppcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.img5.submit()
      var File = new FormData()
      File.append('Img1', this.imgfile1)
      File.append('Id', row.Id)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Class', row.Class)
      File.append('Text', row.Text)
      File.append('Zhiwei', row.Zhiwei)
      File.append('Ptext', row.Ptext)
      File.append('Title1', row.Title1)
      File.append('Text1', row.Text1)
      File.append('Span', row.Span)
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_uppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.imgfile1 = ''
      this.$refs.img5.clearFiles()
      this.NienyiCn()
      this.list_uppcn = false
    },
    async Nienyi_uppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Imgsrc1', row.Imgsrc1)
      File.append('Backgroundimg1', row.Backgroundimg1)
      File.append('Backgroundimg2', row.Backgroundimg2)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Class', row.Class)
      File.append('Text', row.Text)
      File.append('Zhiwei', row.Zhiwei)
      File.append('Ptext', row.Ptext)
      File.append('Title1', row.Title1)
      File.append('Text1', row.Text1)
      File.append('Span', row.Span)
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_uppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.NienyiEn()
      this.list_uppen = false
    },
    async Nienyi_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_delcn`, row)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.$message.success('删除成功')
      this.NienyiCn()
    },
    async Nienyi_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_delen`, row)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.$message.success('删除成功')
      this.NienyiEn()
    },
    img6 (param) {
      this.imgfile2 = param.file
    },
    img7 (param) {
      this.imgfile3 = param.file
    },
    img8 (param) {
      this.imgfile4 = param.file
    },
    async Nienyi_listuppcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.img6.submit()
      this.$refs.img7.submit()
      this.$refs.img8.submit()
      var File = new FormData()
      File.append('Img2', this.imgfile2)
      File.append('Img3', this.imgfile3)
      File.append('Img4', this.imgfile4)
      File.append('Id', row.Id)
      File.append('Text', row.Text)
      File.append('Zhiwei', row.Zhiwei)
      File.append('Ptext', row.Ptext)
      File.append('Title1', row.Title1)
      File.append('Text1', row.Text1)
      File.append('Span', row.Span)
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_listuppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.imgfile2 = ''
      this.imgfile3 = ''
      this.imgfile4 = ''
      this.$refs.img6.clearFiles()
      this.$refs.img7.clearFiles()
      this.$refs.img8.clearFiles()
      this.FamilyCn(this.Family_dataCn.Cid)
    },
    async Nienyi_listuppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Backgroundimg1', row.Backgroundimg1)
      File.append('Imgsrc1', row.Imgsrc1)
      File.append('Backgroundimg2', row.Backgroundimg2)
      File.append('Id', row.Id)
      File.append('Zhiwei', row.Zhiwei)
      File.append('Ptext', row.Ptext)
      File.append('Title1', row.Title1)
      File.append('Text1', row.Text1)
      File.append('Span', row.Span)
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_listuppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.FamilyEn(this.Family_dataEn.Cid)
    },
    async Nienyi_list3addcn (row) {
      row.Cid = this.Family_dataCn.Id
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_list3addcn`, row)
      if (res.status !== 200) return this.$message.error('添加失败')
      this.$message.success('添加成功')
      this.list3addcn = false
      this.FamilyCn(this.Family_dataCn.Cid)
    },
    async Nienyi_list3adden (row) {
      row.Cid = this.Family_dataEn.Id
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_list3adden`, row)
      if (res.status !== 200) return this.$message.error('添加失败')
      this.$message.success('添加成功')
      this.list3adden = false
      this.FamilyEn(this.Family_dataEn.Cid)
    },
    list3funcn (row) {
      this.list3upp = row
      this.list3uppcn = true
    },
    list3funen (row) {
      this.list3upp = row
      this.list3uppen = true
    },
    async Nienyi_list3uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_list3uppcn`, row)
      if (res.status !== 200) return this.$message.error('添加失败')
      this.$message.success('添加成功')
      this.list3uppcn = false
      this.FamilyCn(this.Family_dataCn.Cid)
    },
    async Nienyi_list3uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_list3uppen`, row)
      if (res.status !== 200) return this.$message.error('添加失败')
      this.$message.success('添加成功')
      this.list3uppen = false
      this.FamilyEn(this.Family_dataEn.Cid)
    },
    async Nienyi_list3delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_list3delcn`, row)
      if (res.status !== 200) return this.$message.error('添加失败')
      this.$message.success('添加成功')
      this.FamilyCn(this.Family_dataCn.Cid)
    },
    async Nienyi_list3delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Nienyi_list3delen`, row)
      if (res.status !== 200) return this.$message.error('添加失败')
      this.$message.success('添加成功')
      this.FamilyEn(this.Family_dataEn.Cid)
    }
  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
