<template>
  <div class="adminprod">
    <el-card class="box-card">
      <span class="titletext">TOP图</span>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-image style="background-color: #00558c;" :src="`${$FilesImg}${Capability_dataCn.Imgsrc}`">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-upload class="upload-demo" style="text-align: center;" ref="toptuquest" action="" drag :limit="1"
              :http-request="toptuquest" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </div>
        </el-col>
      </el-row>
      <div style="text-align:center">
        <el-button type="primary" @click="tijiao1">上传</el-button>
      </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">中图</span>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-image style="background-color: #00558c;" :src="`${$FilesImg}${Capability_dataCn.Imgsrc1}`">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-upload class="upload-demo" style="text-align: center;" ref="zhongtuquest" action="" drag :limit="1"
              :http-request="zhongtuquest" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </div>
        </el-col>
      </el-row>
      <div style="text-align:center">
        <el-button type="primary" @click="tijiao2">上传</el-button>
      </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">文本</span>
      <el-row>
        <el-form ref="form" :model="Capability_dataCn" label-width="150px">
          <el-form-item label="标题">
            <el-input v-model="Capability_dataCn.Title1"></el-input>
          </el-form-item>
          <el-form-item label="标题英文">
            <el-input v-model="Capability_dataEn.Title1"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input v-model="Capability_dataCn.Text1"></el-input>
          </el-form-item>
          <el-form-item label="文本英文">
            <el-input v-model="Capability_dataEn.Text1"></el-input>
          </el-form-item>
          <el-form-item label="标题1">
            <el-input v-model="Capability_dataCn.Title2"></el-input>
          </el-form-item>
          <el-form-item label="标题1英文">
            <el-input v-model="Capability_dataEn.Title2"></el-input>
          </el-form-item>
          <el-form-item label="标题2">
            <el-input v-model="Capability_dataCn.Text2"></el-input>
          </el-form-item>
          <el-form-item label="标题2英文">
            <el-input v-model="Capability_dataEn.Text2"></el-input>
          </el-form-item>
          <el-form-item label="文本1">
            <el-input v-model="Capability_dataCn.Title3"></el-input>
          </el-form-item>
          <el-form-item label="文本1英文">
            <el-input v-model="Capability_dataEn.Title3"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即修改</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">文案中英</span>
      <el-row>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <span class="titletext">中文</span>
            <el-table :data="Capability_dataCn.Capability_list1" border
              style="width: 500px; margin-bottom: auto; margin: auto;">
              <el-table-column prop="Text" label="文本">
              </el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <el-button type="primary" @click="dialogFormVisible3 = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="xg(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="del1(scope.row.Id)" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible3" width="500px">
          <el-form :model="dialog">
            <el-form-item label="新增文案">
              <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible3 = false">取 消</el-button>
            <el-button type="primary" @click="xg3(dialog)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible1" width="500px">
          <el-form :model="dialog">
            <el-form-item label="修改文案">
              <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible1 = false">取 消</el-button>
            <el-button type="primary" @click="xg1(dialog)">确 定</el-button>
          </div>
        </el-dialog>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <span class="titletext">英文</span>
            <el-table :data="Capability_dataEn.Capability_list1" border
              style="width: 500px; margin-bottom: auto; margin: auto;">
              <el-table-column prop="Text" label="文本">
              </el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <el-button type="primary" @click="dialogFormVisible4 = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="xga(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="del2(scope.row.Id)" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible4" width="500px">
        <el-form :model="dialog">
          <el-form-item label="新增文案">
            <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible4 = false">取 消</el-button>
          <el-button type="primary" @click="xg4(dialog)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible2" width="500px">
        <el-form :model="dialog">
          <el-form-item label="修改文案">
            <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="xg2(dialog)">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">文案中英</span>
      <el-row>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <span class="titletext">中文</span>
            <el-table :data="Capability_dataCn.Capability_list2" border
              style="width: 500px; margin-bottom: auto; margin: auto;">
              <el-table-column prop="Text" label="文本">
              </el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <el-button type="primary" @click="dialogFormVisible7 = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="xgaa(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="del3(scope.row.Id)" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-dialog title="提示英文" :modal-append-to-body='false' :visible.sync="dialogFormVisible7" width="500px">
              <el-form :model="dialog">
                <el-form-item label="修改文案">
                  <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible7 = false">取 消</el-button>
                <el-button type="primary" @click="xg7(dialog)">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible5" width="500px">
              <el-form :model="dialog">
                <el-form-item label="修改文案">
                  <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible5 = false">取 消</el-button>
                <el-button type="primary" @click="xg5(dialog)">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <span class="titletext">英文</span>
            <el-table :data="Capability_dataEn.Capability_list2" border
              style="width: 500px; margin-bottom: auto; margin: auto;">
              <el-table-column prop="Text" label="文本">
              </el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <el-button type="primary" @click="dialogFormVisible8 = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="xgaaa(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="del4(scope.row.Id)" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-dialog title="提示英文" :modal-append-to-body='false' :visible.sync="dialogFormVisible8" width="500px">
              <el-form :model="dialog">
                <el-form-item label="修改文案">
                  <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible8 = false">取 消</el-button>
                <el-button type="primary" @click="xg8(dialog)">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog title="提示英文" :modal-append-to-body='false' :visible.sync="dialogFormVisible6" width="500px">
              <el-form :model="dialog">
                <el-form-item label="修改文案">
                  <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible6 = false">取 消</el-button>
                <el-button type="primary" @click="xg6(dialog)">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">List</span>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark teshu">
            <el-table :data="Capability_dataCn.Capability_list3" border
              style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column prop="Title" label="标题"></el-table-column>
              <el-table-column prop="Title" label="标题"></el-table-column>
              <el-table-column prop="Text" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi" active-value="true" inactive-value="false" disabled></el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text1" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi1" active-value="true" inactive-value="false" disabled>
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text2" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi2" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text3" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi3" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Title2" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Titlevi2" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Title3" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Titlevi3" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Title4" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Titlevi4" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="是否显示Ul">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Ulvi" disabled active-value="true" inactive-value="false"></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="Imgsrc" label="图片名"></el-table-column>
              <el-table-column label="图片是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Imgsrcvi" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text4" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi4" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="225" align="center">
                <template slot="header">
                  <el-button type="primary" @click="addFormVisibleCn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="xgaaaa(scope.row)" size="small">修改</el-button>
                  <el-button type="success" @click="listulCn(scope.row)" size="small">编辑Ul</el-button>
                  <el-button type="danger" @click="ltis3dellCn(scope.row.Id)" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="FormVisible" width="80%">
        <el-form :model="dialog">
          <el-form-item label="修改链接">
            <el-input v-model="dialog.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" :rows="5" v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" :rows="5" v-model="dialog.Text1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi1" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" :rows="5" v-model="dialog.Text2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" :rows="5" v-model="dialog.Text3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input  v-model="dialog.Title2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Titlevi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="dialog.Title3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Titlevi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" :rows="5" v-model="dialog.Title4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Titlevi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="是否显示UL">
            <el-switch v-model="dialog.Ulvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="img">
            <el-image :src="`${$FilesImg}${dialog.Imgsrc}`">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-input v-model="dialog.Imgsrc" disabled autocomplete="off"></el-input>
            <el-upload class="upload-demo" style="text-align: center;" ref="list3img" action="" drag :limit="1"
              :http-request="list3img" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="是否显示图片">
            <el-switch v-model="dialog.Imgsrcvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="dialog.Text4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisible = false">取 消</el-button>
          <el-button type="primary" @click="list3(dialog)">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
    <el-card class="box-card">
      <span class="titletext">List英文</span>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark teshu">
            <el-table :data="Capability_dataEn.Capability_list3" border
              style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column prop="Title" label="标题"></el-table-column>
              <el-table-column prop="Title" label="标题"></el-table-column>
              <el-table-column prop="Text" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi" active-value="true" inactive-value="false" disabled></el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text1" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi1" active-value="true" inactive-value="false" disabled>
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text2" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi2" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text3" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi3" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Title2" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Titlevi2" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Title3" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Titlevi3" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Title4" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Titlevi4" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="是否显示Ul">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Ulvi" disabled active-value="true" inactive-value="false"></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="文本">
                <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="Imgsrc" label="图片名"></el-table-column>
              <el-table-column label="图片是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Imgsrcvi" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="Text4" label="文本"></el-table-column>
              <el-table-column label="是否显示">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Textvi4" disabled active-value="true" inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="225" align="center">
                <template slot="header">
                  <el-button type="primary" @click="addFormVisibleEn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="xgaaaa1(scope.row)" size="small">修改</el-button>
                  <el-button type="success" @click="listulEn(scope.row)" size="small">编辑Ul</el-button>
                  <el-button type="danger" @click="ltis3dellEn(scope.row.Id)" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="FormVisible1" width="80%">
        <el-form :model="dialog">
          <el-form-item label="修改链接">
            <el-input v-model="dialog.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="dialog.Text1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi1" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="dialog.Text2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="dialog.Text3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="dialog.Title2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Titlevi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="dialog.Title3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Titlevi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="dialog.Title4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Titlevi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="是否显示UL">
            <el-switch v-model="dialog.Ulvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="img">
            <el-input v-model="dialog.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示图片">
            <el-switch v-model="dialog.Imgsrcvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="dialog.Text4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Textvi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="list31(dialog)">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <!---中文新增ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="list3listCn" width="500px">
        <span class="titletext">增加文本</span>
        <el-input v-model="Text" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="list3listCn = false">取 消</el-button>
        <el-button type="primary" @click="addlistCn">确 定</el-button>
      </div>
    </el-dialog>
    <!---英文新增ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="list3listEn" width="500px">
      <span class="titletext">修改文本</span>
        <el-input v-model="Text" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="list3listEn = false">取 消</el-button>
        <el-button type="primary" @click="addlistEn">确 定</el-button>
      </div>
    </el-dialog>
    <!---中文修改ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="xgwlist3listCn" width="500px">
        <el-form :model="dialog">
          <el-form-item label="修改文本">
            <el-input v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="xgwlist3listCn = false">取 消</el-button>
        <el-button type="primary" @click="listxglistCn">确 定</el-button>
      </div>
    </el-dialog>
    <!---英文修改ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="xgwlist3listEn" width="500px">
       <el-form :model="dialog">
          <el-form-item label="修改文本">
            <el-input v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="xgwlist3listEn = false">取 消</el-button>
        <el-button type="primary" @click="listxglistEn">确 定</el-button>
      </div>
    </el-dialog>
    <!---中文编辑ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="xglist3listCn" width="500px">
      <el-table :data="dialog.Capability_list3_list" stripe style="width: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="Text" label="文本"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="listlistCn(scope.row)" size="small">修改</el-button>
            <el-button type="danger" @click="liisdellCn(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="list3listCn = true">新 增</el-button>
        <el-button type="primary" @click="xglist3listCn = false">取 消</el-button>
      </div>
    </el-dialog>
    <!---英文编辑ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="xglist3listEn" width="500px">
      <el-table :data="dialog.Capability_list3_list" stripe style="width: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="Text" label="文本"> </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="listlistEn(scope.row)" size="small">修改</el-button>
            <el-button type="danger" @click="liisdellEn(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="list3listEn = true">新 增</el-button>
        <el-button type="primary" @click="xglist3listEn = false">取 消</el-button>
      </div>
    </el-dialog>
     <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="addFormVisibleCn" width="80%">
        <el-form :model="addform">
          <el-form-item label="修改链接">
            <el-input v-model="addform.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi1" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Title2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Titlevi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Title3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Titlevi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Title4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Titlevi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="是否显示UL">
            <el-switch v-model="addform.Ulvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="img">
            <el-upload class="upload-demo" style="text-align: center;" ref="addformupd" action="" drag :limit="1"
              :http-request="addformupd" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="是否显示图片">
            <el-switch v-model="addform.Imgsrcvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Text4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addFormVisibleCn = false">取 消</el-button>
          <el-button type="primary" @click="addlist3Cn(addform)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="addFormVisibleEn" width="80%">
        <el-form :model="addform">
          <el-form-item label="修改链接">
            <el-input v-model="addform.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi1" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input type="textarea" v-model="addform.Text3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Title2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Titlevi2" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Title3" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Titlevi3" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Title4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Titlevi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="是否显示UL">
            <el-switch v-model="addform.Ulvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="img">
            <el-input v-model="addform.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示图片">
            <el-switch v-model="addform.Imgsrcvi" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="文案">
            <el-input v-model="addform.Text4" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="addform.Textvi4" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addFormVisibleEn = false">取 消</el-button>
          <el-button type="primary" @click="addlist3En(addform)">确 定</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Capability_dataCn: {
        Imgsrc: '',
        Title1: '',
        Text1: '',
        Imgsrc1: '',
        Title2: '',
        Text2: '',
        Title3: '',
        Capability_list1: [],
        Capability_list2: [],
        Capability_list3: []
      },
      Capability_dataEn: {
        Imgsrc: '',
        Title1: '',
        Text1: '',
        Imgsrc1: '',
        Title2: '',
        Text2: '',
        Title3: '',
        Capability_list1: [],
        Capability_list2: [],
        Capability_list3: []
      },
      toptu: '',
      zhongtu: '',
      dialog: [],
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      dialogFormVisible6: false,
      dialogFormVisible7: false,
      dialogFormVisible8: false,
      FormVisible: false,
      list3file: '',
      FormVisible1: false,
      list3listCn: false,
      list3listEn: false,
      xglist3listCn: false,
      xglist3listEn: false,
      Text: '',
      addFormVisibleCn: false,
      addFormVisibleEn: false,
      xgwlist3listCn: false,
      xgwlist3listEn: false,
      addform: [],
      addlist3Cnfile: ''
    }
  },
  mounted () {

  },
  created () {
    this.CapabilityCn()
    this.CapabilityEn()
  },
  methods: {
    listulCn (row) {
      this.dialog = row
      this.xglist3listCn = true
    },
    listulEn (row) {
      this.dialog = row
      console.log(row)
      this.xglist3listEn = true
    },
    listlistCn (row) {
      this.dialog = row
      this.xgwlist3listCn = true
    },
    listlistEn (row) {
      console.log(row)
      this.dialog = row
      this.xgwlist3listEn = true
    },
    async addlist3Cn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.addformupd.submit()
      var File = new FormData()
      File.append('Img', this.addlist3Cnfile)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('Textvi', row.Textvi)
      File.append('Text1', row.Text1)
      File.append('Textvi1', row.Textvi1)
      File.append('Text2', row.Text2)
      File.append('Textvi2', row.Textvi2)
      File.append('Text3', row.Text3)
      File.append('Textvi3', row.Textvi3)
      File.append('Title2', row.Title2)
      File.append('Titlevi2', row.Titlevi2)
      File.append('Title3', row.Title3)
      File.append('Titlevi3', row.Titlevi3)
      File.append('Title4', row.Title4)
      File.append('Titlevi4', row.Titlevi4)
      File.append('Ulvi', row.Ulvi)
      File.append('Imgsrcvi', row.Imgsrcvi)
      File.append('Textvi4', row.Textvi4)
      File.append('Text4', row.Text4)
      const { data: res } = await this.$http.post(`${this.$admin}AdminCapabilityaddudpCn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.addlist3Cnfile = ''
      this.addFormVisibleCn = false
      this.addform = []
      this.$refs.addformupd.clearFiles()
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async addlist3En (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Imgsrc', row.Imgsrc)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('Textvi', row.Textvi)
      File.append('Text1', row.Text1)
      File.append('Textvi1', row.Textvi1)
      File.append('Text2', row.Text2)
      File.append('Textvi2', row.Textvi2)
      File.append('Text3', row.Text3)
      File.append('Textvi3', row.Textvi3)
      File.append('Title2', row.Title2)
      File.append('Titlevi2', row.Titlevi2)
      File.append('Title3', row.Title3)
      File.append('Titlevi3', row.Titlevi3)
      File.append('Title4', row.Title4)
      File.append('Titlevi4', row.Titlevi4)
      File.append('Ulvi', row.Ulvi)
      File.append('Imgsrcvi', row.Imgsrcvi)
      File.append('Textvi4', row.Textvi4)
      File.append('Text4', row.Text4)
      const { data: res } = await this.$http.post(`${this.$admin}AdminCapabilityaddudpEn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.addFormVisibleEn = false
      this.addform = []
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async addlistCn () {
      var AdminCapability = {
        Id: this.dialog.Id,
        Text: this.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_addlistCn`, AdminCapability)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.Text = ''
      this.list3listCn = false
      this.xglist3listCn = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async addlistEn () {
      var AdminCapability = {
        Id: this.dialog.Id,
        Text: this.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_addlistEn`, AdminCapability)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.Text = ''
      this.list3listEn = false
      this.xglist3listEn = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async listxglistCn () {
      var AdminCapability = {
        Id: this.dialog.Id,
        Text: this.dialog.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_xglistCn`, AdminCapability)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.xgwlist3listCn = false
      this.xglist3listCn = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async listxglistEn () {
      var AdminCapability = {
        Id: this.dialog.Id,
        Text: this.dialog.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_xglistEn`, AdminCapability)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.xgwlist3listEn = false
      this.xglist3listEn = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async liisdellCn (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_dellistCn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.xglist3listCn = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async liisdellEn (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_dellistEn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.xglist3listEn = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async ltis3dellCn (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}AdminCapabilitylist3_deCn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.xglist3listCn = false
      this.$message.success('修改成功')
      this.CapabilityCn()
    },
    async ltis3dellEn (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}AdminCapabilitylist3_deEn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.xglist3listEn = false
      this.$message.success('修改成功')
      this.CapabilityEn()
    },
    async CapabilityCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Capability`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Capability_dataCn = res.response
    },
    async CapabilityEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Capability`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Capability_dataEn = res.response
    },
    toptuquest (param) {
      this.toptu = param.file
    },
    zhongtuquest (param) {
      this.zhongtu = param.file
    },
    list3img (param) {
      this.list3file = param.file
    },
    addformupd (param) {
      this.addlist3Cnfile = param.file
    },
    async tijiao1 () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.toptuquest.submit()
      var File = new FormData()
      File.append('Img', this.toptu)
      File.append('Id', this.Capability_dataCn.Id)
      const { data: res } = await this.$http.post(`${this.$admin}Capability_top`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.toptu = []
      this.$refs.toptuquest.clearFiles()
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async tijiao2 () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.zhongtuquest.submit()
      var File = new FormData()
      File.append('Img', this.zhongtu)
      File.append('Id', this.Capability_dataCn.Id)
      const { data: res } = await this.$http.post(`${this.$admin}Capability_zhong`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.zhongtu = []
      this.$refs.zhongtuquest.clearFiles()
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async onSubmit () {
      var AdminCapability = {
        Capabilitycn: this.Capability_dataCn,
        Capabilityen: this.Capability_dataEn
      }
      const { data: res } = await this.$axios.post(`${this.$admin}AdminCapability`, AdminCapability)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Home_dataEn = res.response
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    xg (row) {
      this.dialog = row
      this.dialogFormVisible1 = true
    },
    xga (row) {
      this.dialog = row
      this.dialogFormVisible2 = true
    },
    xgaa (row) {
      this.dialog = row
      this.dialogFormVisible5 = true
    },
    xgaaa (row) {
      this.dialog = row
      this.dialogFormVisible6 = true
    },
    xgaaaa (row) {
      this.dialog = row
      this.FormVisible = true
    },
    xgaaaa1 (row) {
      this.dialog = row
      this.FormVisible1 = true
    },
    async xg1 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list1`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible1 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async xg2 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list11`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible2 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async xg3 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list2`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible3 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async xg4 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list22`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible4 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async xg5 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list21`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible5 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async xg6 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list222`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible6 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async xg7 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list3`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible7 = false
      this.$message.success('增加成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async xg8 (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Text
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_list33`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible8 = false
      this.$message.success('增加成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async del1 (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_del1?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible4 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async del2 (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_del11?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible4 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async del3 (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_del2?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible4 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async del4 (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Capability_del22?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.dialog = []
      this.dialogFormVisible4 = false
      this.$message.success('修改成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async list3 (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.list3img.submit()
      var File = new FormData()
      File.append('Img', this.list3file)
      File.append('Id', row.Id)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('Textvi', row.Textvi)
      File.append('Text1', row.Text1)
      File.append('Textvi1', row.Textvi1)
      File.append('Text2', row.Text2)
      File.append('Textvi2', row.Textvi2)
      File.append('Text3', row.Text3)
      File.append('Textvi3', row.Textvi3)
      File.append('Title2', row.Title2)
      File.append('Titlevi2', row.Titlevi2)
      File.append('Title3', row.Title3)
      File.append('Titlevi3', row.Titlevi3)
      File.append('Title4', row.Title4)
      File.append('Titlevi4', row.Titlevi4)
      File.append('Ulvi', row.Ulvi)
      File.append('Imgsrcvi', row.Imgsrcvi)
      File.append('Textvi4', row.Textvi4)
      File.append('Text4', row.Text4)
      const { data: res } = await this.$http.post(`${this.$admin}AdminCapabilitylist3Cn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list3file = ''
      this.FormVisible = false
      this.dialog = []
      this.$refs.list3img.clearFiles()
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async list31 (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Imgsrc', row.Imgsrc)
      File.append('Id', row.Id)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('Textvi', row.Textvi)
      File.append('Text1', row.Text1)
      File.append('Textvi1', row.Textvi1)
      File.append('Text2', row.Text2)
      File.append('Textvi2', row.Textvi2)
      File.append('Text3', row.Text3)
      File.append('Textvi3', row.Textvi3)
      File.append('Title2', row.Title2)
      File.append('Titlevi2', row.Titlevi2)
      File.append('Title3', row.Title3)
      File.append('Titlevi3', row.Titlevi3)
      File.append('Title4', row.Title4)
      File.append('Titlevi4', row.Titlevi4)
      File.append('Ulvi', row.Ulvi)
      File.append('Imgsrcvi', row.Imgsrcvi)
      File.append('Textvi4', row.Textvi4)
      File.append('Text4', row.Text4)
      const { data: res } = await this.$http.post(`${this.$admin}AdminCapabilitylist3En`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.FormVisible1 = false
      this.dialog = []
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async list3Cn (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}AdminCapabilitylist3_deCn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.$message.success('删除成功')
      this.CapabilityCn()
      this.CapabilityEn()
    },
    async list3En (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}AdminCapabilitylist3_deEn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.$message.success('删除成功')
      this.CapabilityCn()
      this.CapabilityEn()
    }
  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }

  .el-form {
    margin: 0 auto;
    width: 80%;
  }

  .el-row {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }

  .titletext {
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
