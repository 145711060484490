<template>
<div>
    <el-card class="box-card">
      <span class="titletext">背景图</span>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                  <div>
                    <span class="demonstration">原背景图</span>
                    <div style="text-align: center;">
                        <el-image style="background-color: #00558c;"  :src="`${$FilesImg}${Global_dataCn.Backgroundimg}`"> <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i></div></el-image>
                    </div>
                  </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <span class="demonstration">修改图标</span>
                <el-upload class="upload-demo" style="text-align: center;" ref="logofileref" action="" drag :limit="1"
                  :http-request="logofileref" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <div style="text-align:center">
            <el-button type="primary" @click="logoup">上传</el-button>
          </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">List中文</span>
      <el-row>
        <el-col :span="24"><div class="grid-content bg-purple-dark">
          <el-table :data="Global_dataCn.Global_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="国家" align="center"></el-table-column>
            <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="addlistCn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="fxglistCn(scope.row)" size="small">修改</el-button>
              <el-button type="primary" @click="listCn(scope.row.Id)" size="small">编辑</el-button>
              <el-button type="danger" @click="Global_listdelCn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
          </el-table>
        </div></el-col>
      </el-row>
    </el-card>
      <!---中文List添加ul-->
      <el-dialog title="中文添加国家" :modal-append-to-body='false' :visible.sync="addlistCn" width="500px">
        <el-form :model="addlist">
           <el-form-item label="添加国家">
             <el-input v-model="addlist.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="addlistCn = false">取 消</el-button>
         <el-button type="primary" @click="Global_listaddCn(addlist)">确 定</el-button>
       </div>
     </el-dialog>
     <!---英文List添加ul-->
     <el-dialog title="英文添加国家" :modal-append-to-body='false' :visible.sync="addlistEn" width="500px">
      <el-form :model="addlist">
         <el-form-item label="添加国家">
           <el-input v-model="addlist.Title" autocomplete="off"></el-input>
         </el-form-item>
       </el-form>
     <div slot="footer" class="dialog-footer">
       <el-button @click="addlistEn = false">取 消</el-button>
       <el-button type="primary" @click="Global_listaddEn(addlist)">确 定</el-button>
     </div>
   </el-dialog>
   <!---中文List修改-->
   <el-dialog title="中文修改国家" :modal-append-to-body='false' :visible.sync="xglistCn" width="500px">
    <el-form :model="addlist">
       <el-form-item label="国家">
         <el-input v-model="addlist.Title" autocomplete="off"></el-input>
       </el-form-item>
     </el-form>
   <div slot="footer" class="dialog-footer">
     <el-button @click="xglistCn = false">取 消</el-button>
     <el-button type="primary" @click="Global_listgxCn(addlist)">确 定</el-button>
   </div>
 </el-dialog>
 <!---英文List修改-->
 <el-dialog title="英文修改国家" :modal-append-to-body='false' :visible.sync="xglistEn" width="500px">
  <el-form :model="addlist">
     <el-form-item label="国家">
       <el-input v-model="addlist.Title" autocomplete="off"></el-input>
     </el-form-item>
   </el-form>
 <div slot="footer" class="dialog-footer">
   <el-button @click="xglistEn = false">取 消</el-button>
   <el-button type="primary" @click="Global_listgxEn(addlist)">确 定</el-button>
 </div>
</el-dialog>
     <!--中文修改详情页-->
     <el-dialog title="中文详情页" :modal-append-to-body='false' :before-close="handleClose" :visible.sync="listvieCn" width="80%">
      <el-table :data="Global_listCn" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="标题" align="center"></el-table-column>
            <el-table-column prop="Text" label="文本" align="center"></el-table-column>
            <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="addlist_listCn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="listuppCn(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Global_list_listdelCn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
          </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="listvieCn = false">取 消</el-button>
      </span>
  </el-dialog>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">List英文</span>
      <el-row>
        <el-col :span="24"><div class="grid-content bg-purple-dark">
          <el-table :data="Global_dataEn.Global_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="国家" align="center"></el-table-column>
            <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="addlistEn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="fxglistEn(scope.row)" size="small">修改</el-button>
              <el-button type="primary" @click="listEn(scope.row.Id)" size="small">编辑</el-button>
              <el-button type="danger" @click="Global_listdelEn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
          </el-table>
        </div></el-col>
      </el-row>
    </el-card>
    <!---中文List_list添加ul-->
    <el-dialog title="添加中文公司" :modal-append-to-body='false' :visible.sync="addlist_listCn" width="500px">
      <el-form :model="addlist_list">
        <el-form-item label="添加国家">
          <el-input v-model="addlist_list.Title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详情">
         <el-input v-model="addlist_list.Text" autocomplete="off"></el-input>
       </el-form-item>
       </el-form>
     <div slot="footer" class="dialog-footer">
       <el-button @click="addlist_listCn = false">取 消</el-button>
       <el-button type="primary" @click="Global_list_listaddCn(addlist_list)">确 定</el-button>
     </div>
   </el-dialog>
   <!---中文List_list添加ul-->
   <el-dialog title="添加英文公司" :modal-append-to-body='false' :visible.sync="addlist_listEn" width="500px">
    <el-form :model="addlist_list">
       <el-form-item label="添加国家">
         <el-input v-model="addlist_list.Title" autocomplete="off"></el-input>
       </el-form-item>
       <el-form-item label="详情">
        <el-input v-model="addlist_list.Text" autocomplete="off"></el-input>
      </el-form-item>
     </el-form>
   <div slot="footer" class="dialog-footer">
     <el-button @click="addlist_listEn = false">取 消</el-button>
     <el-button type="primary" @click="Global_list_listaddEn(addlist_list)">确 定</el-button>
   </div>
 </el-dialog>
     <!--英文修改详情页-->
     <el-dialog title="英文详情页" :modal-append-to-body='false' :before-close="handleClose" :visible.sync="listvieEn" width="80%">
      <el-table :data="Global_listEn" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="标题" align="center"></el-table-column>
            <el-table-column prop="Text" label="文本" align="center"></el-table-column>
            <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="addlist_listEn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="listuppEn(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Global_list_listdelEn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
          </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="listvieEn = false">取 消</el-button>
      </span>
  </el-dialog>
      <!---中文List_list修改ul-->
      <el-dialog title="修改中文公司" :modal-append-to-body='false' :visible.sync="addlist_listuppCn" width="500px">
        <el-form :model="addlist_list">
          <el-form-item label="修改国家">
            <el-input v-model="addlist_list.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="修改详情">
           <el-input v-model="addlist_list.Text" autocomplete="off"></el-input>
         </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="addlist_listuppCn = false">取 消</el-button>
         <el-button type="primary" @click="Global_list_listuppCn(addlist_list)">确 定</el-button>
       </div>
     </el-dialog>
     <!---英文List_list修改ul-->
     <el-dialog title="修改英文公司" :modal-append-to-body='false' :visible.sync="addlist_listuppEn" width="500px">
      <el-form :model="addlist_list">
         <el-form-item label="修改国家">
           <el-input v-model="addlist_list.Title" autocomplete="off"></el-input>
         </el-form-item>
         <el-form-item label="修改详情">
          <el-input v-model="addlist_list.Text" autocomplete="off"></el-input>
        </el-form-item>
       </el-form>
     <div slot="footer" class="dialog-footer">
       <el-button @click="addlist_listuppEn = false">取 消</el-button>
       <el-button type="primary" @click="Global_list_listuppEn(addlist_list)">确 定</el-button>
     </div>
   </el-dialog>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Global_dataCn: {
        Backgroundimg: '',
        H1text: '',
        Global_list1: []
      },
      Global_dataEn: {
        Backgroundimg: '',
        H1text: '',
        Global_list1: []
      },
      Global_listCn: [],
      Global_listEn: [],
      listvieCn: false,
      listvieEn: false,
      xglistCn: false,
      xglistEn: false,
      addlist: {
        Title: ''
      },
      addlistCn: false,
      addlistEn: false,
      addlist_listCn: false,
      addlist_listEn: false,
      addlist_list: {
        Title: '',
        Text: '',
        Cid: ''
      },
      Id: '',
      addlist_listuppEn: false,
      addlist_listuppCn: false,
      logofile: ''
    }
  },
  created () {
    this.GlobalCn()
    this.GlobalEn()
  },
  mounted () {

  },
  methods: {
    async GlobalCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Global`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Global_dataCn = res.response
    },
    async GlobalEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Global`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Global_dataEn = res.response
    },
    listCn (row) {
      this.Global_listleCn(row)
      this.Id = row
      this.listvieCn = true
    },
    listEn (row) {
      this.Id = row
      this.Global_listleEn(row)
      this.listvieEn = true
    },
    async Global_listleCn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listCn?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listCn = res.response
    },
    async Global_listleEn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listEn?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listEn = res.response
    },
    async Global_listaddCn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_listaddCn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.addlistCn = false
      this.GlobalCn()
    },
    async Global_listaddEn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_listaddEn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.addlistEn = false
      this.GlobalEn()
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    fxglistCn (row) {
      this.addlist = row
      this.xglistCn = true
    },
    fxglistEn (row) {
      this.addlist = row
      this.xglistEn = true
    },
    async Global_listgxCn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_listgxCn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.xglistCn = false
      this.GlobalCn()
    },
    async Global_listgxEn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_listgxEn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.xglistEn = false
      this.GlobalEn()
    },
    async Global_listdelCn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_listdelCn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.GlobalCn()
    },
    async Global_listdelEn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_listdelEn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.GlobalEn()
    },
    async Global_list_listaddCn (row) {
      row.Cid = this.Id
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listaddCn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listleCn(row.Cid)
      this.addlist_listCn = false
    },
    async Global_list_listaddEn (row) {
      row.Cid = this.Id
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listaddEn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listleEn(row.Cid)
      this.addlist_listEn = false
    },
    listuppCn (row) {
      this.addlist_list = row
      this.addlist_listuppCn = true
    },
    listuppEn (row) {
      this.addlist_list = row
      this.addlist_listuppEn = true
    },
    async Global_list_listuppCn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listuppCn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listleCn(row.Cid)
      this.addlist_listuppCn = false
    },
    async Global_list_listuppEn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listuppEn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listleEn(row.Cid)
      this.addlist_listuppEn = false
    },
    async Global_list_listdelCn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listdelCn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listleCn(row.Cid)
    },
    async Global_list_listdelEn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Global_list_listdelEn`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Global_listleEn(row.Cid)
    },
    logofileref (param) {
      this.logofile = param.file
    },
    async logoup () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.logofileref.submit()
      var File = new FormData()
      File.append('file', this.logofile)
      const { data: res } = await this.$http.post(`${this.$admin}Global_logoup`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.logofile = ''
      this.$refs.logofileref.clearFiles()
      this.GlobalCn()
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
