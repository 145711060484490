<template>
  <div class="adminprod">
    <el-card class="box-card">
      <span class="titletext">产品菜单</span>
      <el-row>
        <el-col :span="12"><div class="grid-content bg-purple">
          <span class="titletext">中文</span>
           <el-table :data="MenunsCn" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Menus" label="名称" align="center"></el-table-column>
          <el-table-column align="center">
          <template slot="header">
            <el-button type="primary" @click="menusvisiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="menusxgcn(scope.row)" size="small">修改</el-button>
            <el-button type="primary" @click="productaddcn(scope.row)" size="small">添加产品</el-button>
            <el-button type="danger"  @click="menusdelcn(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-light">
          <span class="titletext">英文</span>
           <el-table :data="MenunsEn" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Menus" label="名称" align="center"></el-table-column>
          <el-table-column align="center">
          <template slot="header">
            <el-button type="primary" @click="menusvisibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="menusxgen(scope.row)" size="small">修改</el-button>
            <el-button type="primary" @click="productadden(scope.row)" size="small">添加产品</el-button>
            <el-button type="danger" @click="menusdelen(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">中文产品列表</span>
      <el-row class="adminprod">
        <div style="float: right;">
          <span class="titletext">类别：</span>
          <el-select v-model="valuecn"  @change="select_menuscn" placeholder="请选择">
              <el-option
                  v-for="item in MenunsCn"
                  :key="item.Id"
                  :label="item.Menus"
                  :value="item.Id">
              </el-option>
            </el-select>
        </div>
          <el-table :data="ProductCn" border style="width:100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column  label="产品图" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Imgsrc" label="产品图名" align="center"></el-table-column>
          <el-table-column  label="详细大图" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc_1}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Imgsrc_1" label="详细大图名" align="center"></el-table-column>
          <el-table-column prop="Title" label="标题" align="center"></el-table-column>
          <el-table-column prop="Yongtu" label="文本" align="center"></el-table-column>
          <el-table-column align="center"  width="240"  fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" @click="xgprodunccn(scope.row)" size="small">修改</el-button>
            <el-button type="warning" @click="Product_listselecn(scope.row.Id)" size="small">编辑</el-button>
            <el-button type="danger" @click="productdelcn(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
      </el-row>
      <div class="container">
        <div class="pagination">
            <!---->
            <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="queryInfo1.pagenum"
                :page-sizes="[12, 15, 21, 30]"
                :page-size="queryInfo1.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total1">
            </el-pagination>
        </div>
    </div>
     <el-dialog title="中文详情" :modal-append-to-body='false' :visible.sync="list1xqcn" width="80%">
        <el-table :data="list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="标题" align="center"></el-table-column>
            <el-table-column align="center"  width="240"  fixed="right">
            <template slot="header">
              <el-button type="primary" @click="list1addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list1_addcn(scope.row)" size="small">修改</el-button>
              <el-button type="warning" @click="Product_list2selecn(scope.row.Id)" size="small">编辑</el-button>
              <el-button type="danger"  @click="Product_listdelcn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        </el-dialog>
        <el-dialog title="英文详情" :modal-append-to-body='false' :visible.sync="list1xqen" width="80%">
        <el-table :data="list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="标题" align="center"></el-table-column>
            <el-table-column align="center"  width="240"  fixed="right">
            <template slot="header">
              <el-button type="primary" @click="list1adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list1_adden(scope.row)" size="small">修改</el-button>
              <el-button type="warning" @click="Product_list2seleen(scope.row.Id)" size="small">编辑</el-button>
              <el-button type="danger"  @click="Product_listdelen(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        </el-dialog>
        <el-dialog title="中文详情二" :modal-append-to-body='false' :visible.sync="list2xqcn" width="60%">
        <el-table :data="list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Text" label="标题" align="center"></el-table-column>
            <el-table-column align="center"  width="240"  fixed="right">
            <template slot="header">
              <el-button type="primary" @click="list2addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list2_addcn(scope.row)" size="small">修改</el-button>
              <el-button type="danger"  @click="Product_list2delcn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        </el-dialog>
        <el-dialog title="英文详情二" :modal-append-to-body='false' :visible.sync="list2xqen" width="80%">
        <el-table :data="list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Text" label="标题" align="center"></el-table-column>
            <el-table-column align="center"  width="240"  fixed="right">
            <template slot="header">
              <el-button type="primary" @click="list2adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list2_adden(scope.row)" size="small">修改</el-button>
              <el-button type="danger"  @click="Product_list2delen(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        </el-dialog>
         <el-dialog title="新增中文列表二" :modal-append-to-body='false' :visible.sync="list2addcn" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list2add.Text" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list2addcn = false">取 消</el-button>
            <el-button type="primary" @click="Product_list2addcn(list2add)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="新增英文列表二" :modal-append-to-body='false' :visible.sync="list2adden" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list2add.Text" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list2adden = false">取 消</el-button>
            <el-button type="primary" @click="Product_list2adden(list2add)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改中文列表二" :modal-append-to-body='false' :visible.sync="list2uppcn" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list2upp.Text" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list2uppcn = false">取 消</el-button>
            <el-button type="primary" @click="Product_list2uppcn(list2upp)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改英文列表二" :modal-append-to-body='false' :visible.sync="list2uppen" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list2upp.Text" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list2uppen = false">取 消</el-button>
            <el-button type="primary" @click="Product_list2uppen(list2upp)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="新增中文列表" :modal-append-to-body='false' :visible.sync="list1addcn" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list1add.Title" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list1addcn = false">取 消</el-button>
            <el-button type="primary" @click="Product_listaddcn(list1add)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="新增英文列表" :modal-append-to-body='false' :visible.sync="list1adden" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list1add.Title" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list1adden = false">取 消</el-button>
            <el-button type="primary" @click="Product_listadden(list1add)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改中文列表" :modal-append-to-body='false' :visible.sync="list1uppcn" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list1upp.Title" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list1uppcn = false">取 消</el-button>
            <el-button type="primary" @click="Product_listuppcn(list1upp)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改英文列表" :modal-append-to-body='false' :visible.sync="list1uppen" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="list1upp.Title" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="list1uppen = false">取 消</el-button>
            <el-button type="primary" @click="Product_listuppen(list1upp)">确 定</el-button>
          </div>
        </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">英文产品列表</span>
      <el-row class="adminprod">
        <div style="float: right;">
          <span class="titletext">类别：</span>
          <el-select v-model="valueen"  @change="select_menusen" placeholder="请选择">
              <el-option
                  v-for="item in MenunsEn"
                  :key="item.Id"
                  :label="item.Menus"
                  :value="item.Id">
              </el-option>
            </el-select>
        </div>
          <el-table :data="ProductEn" border style="width:100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column  label="产品图" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Imgsrc" label="产品图名" align="center"></el-table-column>
          <el-table-column  label="详细大图" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc_1}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Imgsrc_1" label="详细大图名" align="center"></el-table-column>
          <el-table-column prop="Title" label="标题" align="center"></el-table-column>
          <el-table-column prop="Yongtu" label="文本" align="center"></el-table-column>
          <el-table-column align="center"  width="240"  fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="xgproduncen(scope.row)" size="small">修改</el-button>
            <el-button type="warning" @click="Product_listseleen(scope.row.Id)" size="small">编辑</el-button>
            <el-button type="danger" @click="productdelen(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
      </el-row>
      <div class="container">
        <div class="pagination">
            <!---->
            <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="queryInfo2.pagenum"
                :page-sizes="[12, 15, 21, 30]"
                :page-size="queryInfo2.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total2">
            </el-pagination>
        </div>
    </div>
     <el-dialog title="新增中文菜单" :modal-append-to-body='false' :visible.sync="menusvisiblecn" width="500px">
          <span class="titletext">增加文本</span>
          <el-input v-model="menustext" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="menusvisiblecn = false">取 消</el-button>
            <el-button type="primary" @click="menusaddcn">确 定</el-button>
          </div>
        </el-dialog>
       <el-dialog title="新增英文菜单" :modal-append-to-body='false' :visible.sync="menusvisibleen" width="500px">
          <span class="titletext">增加文本</span>
        <el-input v-model="menustext" autocomplete="off"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="menusvisibleen = false">取 消</el-button>
            <el-button type="primary" @click="menusadden">确 定</el-button>
          </div>
        </el-dialog>
        <!---中文修改ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="menusxgvisvscn" width="500px">
        <el-form :model="dialog">
          <el-form-item label="修改文本">
            <el-input v-model="dialog.Menus" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menusxgvisvscn = false">取 消</el-button>
        <el-button @click="xgmenuscn(dialog)" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <!---英文修改ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="menusxgvisvsen" width="500px">
        <el-form :model="dialog">
          <el-form-item label="修改文本">
            <el-input v-model="dialog.Menus" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menusxgvisvsen = false">取 消</el-button>
        <el-button @click="xgmenusen(dialog)" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <!---添加中文产品-->
    <el-dialog title="添加中文产品" :modal-append-to-body='false' :visible.sync="productvisviblecn" width="80%">
        <el-form :model="form">
          <el-form-item label="List图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="producimg1cn" action="" drag :limit="1"
              :http-request="producimg1cn" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="详情大图">
            <el-upload class="upload-demo" style="text-align: center;" ref="producimg2cn" action="" drag :limit="1"
              :http-request="producimg2cn" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="List标题">
            <el-input v-model="form.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="List文本">
            <el-input v-model="form.Yongtu" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="productvisviblecn = false">取 消</el-button>
          <el-button @click="productxgformcn(form)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
       <!---添加英文产品-->
      <el-dialog title="添加英文产品" :modal-append-to-body='false' :visible.sync="productvisvibleen" width="80%">
        <el-form :model="form">
          <el-form-item label="List图片">
            <el-input v-model="form.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="详情大图">
            <el-input v-model="form.Imgsrc_1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="List标题">
           <el-input v-model="form.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="List文本">
            <el-input v-model="form.Yongtu" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="productvisvibleen = false">取 消</el-button>
          <el-button @click="productxgformen(form)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
      <!---修改中文产品-->
    <el-dialog title="添加中文产品" :modal-append-to-body='false' :visible.sync="productxgvisviblecn" width="80%">
        <el-form :model="form">
          <el-form-item label="List图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="producimg1cn" action="" drag :limit="1"
              :http-request="producimg1cn" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="详情大图">
            <el-upload class="upload-demo" style="text-align: center;" ref="producimg2cn" action="" drag :limit="1"
              :http-request="producimg2cn" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="List标题">
            <el-input v-model="form.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="List文本">
            <el-input v-model="form.Yongtu" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="productxgvisviblecn = false">取 消</el-button>
          <el-button @click="productgxformcn(form)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
       <!---修改英文产品-->
    <el-dialog title="添加中文产品" :modal-append-to-body='false' :visible.sync="productxgvisvibleen" width="80%">
        <el-form :model="form">
          <el-form-item label="List图片">
            <el-input v-model="form.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="详情大图">
            <el-input v-model="form.Imgsrc_1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="List标题">
            <el-input v-model="form.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="List文本">
            <el-input v-model="form.Yongtu" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="productxgvisvibleen = false">取 消</el-button>
          <el-button @click="productgxformen(form)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      MenunsCn: {},
      MenunsEn: [],
      ProductCn: [],
      ProductEn: [],
      total1: 0,
      total2: 0,
      queryInfo1: {
        Id: 0,
        Pagenum: 1, // 当前的页数
        Pagesize: 12// 当前每页显示多少条
      },
      queryInfo2: {
        Id: 0,
        Pagenum: 1, // 当前的页数
        Pagesize: 12// 当前每页显示多少条
      },
      menusvisiblecn: false,
      menusvisibleen: false,
      menusxgvisvscn: false,
      menusxgvisvsen: false,
      menustext: '',
      dialog: [],
      productvisviblecn: false,
      productvisvibleen: false,
      productimgfile1cn: [],
      productimgfile2cn: [],
      form: {
        Id: '',
        Imgsrc: '',
        Imgsrc_1: '',
        Title: '',
        Yongtu: ''
      },
      list1: {
        Id: 0,
        Cid: 0,
        Title: ''
      },
      list1add: {
        Id: 0,
        Cid: 0,
        Title: ''
      },
      list2add: {
        Id: 0,
        Cid: 0,
        Text: ''
      },
      list2upp: {
        Id: 0,
        Cid: 0,
        Text: ''
      },
      list1upp: {
        Id: 0,
        Cid: 0,
        Title: ''
      },
      list1xqcn: false,
      list1xqen: false,
      list2: {
        Id: 0,
        Cid: 0,
        Text: ''
      },
      list2xqcn: false,
      list2xqen: false,
      productxgvisviblecn: false,
      productxgvisvibleen: false,
      list1_id: 0,
      list2_id: 0,
      list1addcn: false,
      list1adden: false,
      list1uppcn: false,
      list1uppen: false,
      list2addcn: false,
      list2adden: false,
      list2uppcn: false,
      list2uppen: false,
      Menunsdatacn: [],
      Menunsdataen: [],
      valuecn: '',
      valueen: ''
    }
  },
  created () {
    this.Product_MenunsCn()
    this.Product_MenunsEn()
    this.Product_dataCn()
    this.Product_dataEn()
  },
  mounted () { },
  methods: {
    async Product_MenunsCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Product_Menuns`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.MenunsCn = res.response
      for (let index = 0; index < this.MenunsCn.length; index++) {
        if (this.queryInfo1.Id === 0) {
          this.valuecn = '所有分类'
        } else {
          if (this.MenunsCn[index].Id === parseInt(this.queryInfo1.Id)) {
            this.valuecn = this.MenunsCn[index].Menus
            return
          }
        }
      }
      console.log(this.MenunsCn)
    },
    async Product_MenunsEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Product_Menuns`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.MenunsEn = res.response
      for (let index = 0; index < this.MenunsEn.length; index++) {
        if (this.queryInfo2.Id === 0) {
          this.valueen = '所有分类'
        } else {
          if (this.MenunsEn[index].Id === parseInt(this.queryInfo2.Id)) {
            this.valueen = this.MenunsEn[index].Menus
            return
          }
        }
      }
    },
    handleNodeClick (data) {
      console.log(data)
    },
    async Product_dataCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Product`, { params: this.queryInfo1 })
      if (res.status !== 200) return this.$message.error('数据异常')
      this.ProductCn = res.response.Product
      this.total1 = res.response.Total
    },
    async Product_dataEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Product`, { params: this.queryInfo2 })
      if (res.status !== 200) return this.$message.error('数据异常')
      this.ProductEn = res.response.Product
      this.total2 = res.response.Total
    },
    // 监听pagesize的改变事件
    handleSizeChange1 (newSize) {
      this.queryInfo1.Pagesize = newSize
      this.Product_dataCn()
    },
    // 监听页面值的改变事件
    handleCurrentChange1 (newPage) {
      this.queryInfo1.Pagenum = newPage
      this.Product_dataCn()
    },
    // 监听pagesize的改变事件
    handleSizeChange2 (newSize) {
      this.queryInfo2.Pagesize = newSize
      this.Product_dataEn()
    },
    // 监听页面值的改变事件
    handleCurrentChange2 (newPage) {
      this.queryInfo2.Pagenum = newPage
      this.Product_dataEn()
    },
    async menusaddcn () {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_menusaddcn?Text=${this.menustext}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menustext = ''
      this.menusvisiblecn = false
      this.$message.success('添加成功')
      this.Product_MenunsCn()
    },
    async menusadden () {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_menusadden?Text=${this.menustext}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menustext = ''
      this.menusvisibleen = false
      this.$message.success('添加成功')
      this.Product_MenunsEn()
    },
    menusxgcn (row) {
      this.dialog = row
      this.menusxgvisvscn = true
    },
    menusxgen (row) {
      this.dialog = row
      this.menusxgvisvsen = true
    },
    async xgmenuscn (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Menus
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Product_menusxgcn`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menusxgvisvscn = false
      this.$message.success('修改成功')
      this.Product_MenunsCn()
    },
    async xgmenusen (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Menus
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Product_menusxgen`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menusxgvisvsen = false
      this.$message.success('修改成功')
      this.Product_MenunsEn()
    },
    async menusdelcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_menusdelcn?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Product_MenunsCn()
      this.$message.success('删除成功')
    },
    async menusdelen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_menusdelen?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Product_MenunsEn()
      this.$message.success('删除成功')
    },
    productaddcn (row) {
      this.form = row
      this.productvisviblecn = true
    },
    productadden (row) {
      this.form = row
      this.productvisvibleen = true
    },
    producimg1cn (param) {
      this.productimgfile1cn = param.file
    },
    producimg2cn (param) {
      this.productimgfile2cn = param.file
    },
    async productxgformcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.producimg1cn.submit()
      this.$refs.producimg2cn.submit()
      var File = new FormData()
      File.append('Cid', row.Id)
      File.append('Img1', this.productimgfile1cn)
      File.append('Img2', this.productimgfile2cn)
      File.append('Title', row.Title)
      File.append('Yongtu', row.Yongtu)
      const { data: res } = await this.$http.post(`${this.$admin}Product_productaddcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.productimgfile1cn = ''
      this.productimgfile2cn = ''
      this.productvisviblecn = false
      this.form = []
      this.$refs.producimg1cn.clearFiles()
      this.$refs.producimg2cn.clearFiles()
      this.Product_dataCn()
    },
    async productxgformen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Cid', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Imgsrc_1', row.Imgsrc_1)
      File.append('Title', row.Title)
      File.append('Yongtu', row.Yongtu)
      const { data: res } = await this.$http.post(`${this.$admin}Product_productadden`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.productvisvibleen = false
      this.form = []
      this.Product_dataEn()
    },
    async productdelcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_productdelcn?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Product_dataCn()
      this.$message.success('删除成功')
    },
    async productdelen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_productdelen?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Product_dataEn()
      this.$message.success('删除成功')
    },
    xgprodunccn (row) {
      this.form = row
      this.productxgvisviblecn = true
    },
    xgproduncen (row) {
      this.form = row
      this.productxgvisvibleen = true
    },
    async productgxformcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.producimg1cn.submit()
      this.$refs.producimg2cn.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Img1', this.productimgfile1cn)
      File.append('Img2', this.productimgfile2cn)
      File.append('Title', row.Title)
      File.append('Yongtu', row.Yongtu)
      const { data: res } = await this.$http.post(`${this.$admin}Product_productgxcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.productimgfile1cn = ''
      this.productimgfile2cn = ''
      this.productxgvisviblecn = false
      this.form = []
      this.$refs.producimg1cn.clearFiles()
      this.$refs.producimg2cn.clearFiles()
      this.Product_dataCn()
    },
    async productgxformen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Imgsrc_1', row.Imgsrc_1)
      File.append('Title', row.Title)
      File.append('Yongtu', row.Yongtu)
      const { data: res } = await this.$http.post(`${this.$admin}Product_productgxen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.productxgvisvibleen = false
      this.form = []
      this.Product_dataEn()
    },
    async Product_listselecn (row) {
      this.list1_id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listselecn?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.list1 = res.response
      this.list1xqcn = true
    },
    async Product_listseleen (row) {
      this.list1_id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listseleen?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.list1 = res.response
      this.list1xqen = true
    },
    async Product_listaddcn (row) {
      row.Cid = this.list1_id
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listaddcn`, row)
      if (res.status !== 200) return this.$message.success('添加异常')
      this.list1addcn = false
      this.Product_listselecn(this.list1_id)
      this.$message.success('添加成功')
    },
    async Product_listadden (row) {
      row.Cid = this.list1_id
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listadden`, row)
      if (res.status !== 200) return this.$message.success('添加异常')
      this.list1adden = false
      this.Product_listseleen(this.list1_id)
      this.$message.success('添加成功')
    },
    list1_addcn (row) {
      this.list1upp = row
      this.list1uppcn = true
    },
    list1_adden (row) {
      this.list1upp = row
      this.list1uppen = true
    },
    async Product_listuppcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listuppcn`, row)
      if (res.status !== 200) return this.$message.success('修改异常')
      this.list1uppcn = false
      this.Product_listselecn(this.list1_id)
      this.$message.success('修改成功')
    },
    async Product_listuppen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listuppen`, row)
      if (res.status !== 200) return this.$message.success('修改异常')
      this.list1uppen = false
      this.Product_listseleen(this.list1_id)
      this.$message.success('修改成功')
    },
    async Product_list2selecn (row) {
      this.list2_id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2selecn?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.list2 = res.response
      this.list2xqcn = true
    },
    async Product_list2seleen (row) {
      this.list2_id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2seleen?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.list2 = res.response
      this.list2xqen = true
    },
    async Product_list2addcn (row) {
      row.Cid = this.list2_id
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2addcn`, row)
      if (res.status !== 200) return this.$message.success('添加异常')
      this.list2addcn = false
      this.Product_list2selecn(this.list2_id)
      this.$message.success('添加成功')
    },
    async Product_list2adden (row) {
      row.Cid = this.list2_id
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2adden`, row)
      if (res.status !== 200) return this.$message.success('添加异常')
      this.list2adden = false
      this.Product_list2seleen(this.list2_id)
      this.$message.success('添加成功')
    },
    list2_addcn (row) {
      this.list2upp = row
      this.list2uppcn = true
    },
    list2_adden (row) {
      this.list2upp = row
      this.list2uppen = true
    },
    async Product_list2uppcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2uppcn`, row)
      if (res.status !== 200) return this.$message.success('修改异常')
      this.list2uppcn = false
      this.Product_list2selecn(this.list2_id)
      this.$message.success('修改成功')
    },
    async Product_list2uppen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2uppen`, row)
      if (res.status !== 200) return this.$message.success('修改异常')
      this.list2uppen = false
      this.Product_list2seleen(this.list2_id)
      this.$message.success('修改成功')
    },
    async Product_listdelcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listdelcn`, row)
      if (res.status !== 200) return this.$message.success('删除异常')
      this.$message.success('删除成功')
      this.Product_listselecn(this.list1_id)
    },
    async Product_listdelen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_listdelen`, row)
      if (res.status !== 200) return this.$message.success('删除异常')
      this.$message.success('删除成功')
      this.Product_listselecn(this.list1_id)
    },
    async Product_list2delcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2delcn`, row)
      if (res.status !== 200) return this.$message.success('删除异常')
      this.Product_list2selecn(this.list2_id)
      this.$message.success('删除成功')
    },
    async Product_list2delen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Product_list2delen`, row)
      if (res.status !== 200) return this.$message.success('删除异常')
      this.Product_list2seleen(this.list2_id)
      this.$message.success('删除成功')
    },
    async select_menuscn (data) {
      this.queryInfo1.Id = data
      this.Product_dataCn()
    },
    async select_menusen (data) {
      this.queryInfo2.Id = data
      this.Product_dataEn()
    }
  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }

  .el-image {
    width: 360px;
  }

  .el-form {
    margin: 0 auto;
    width: 80%;
  }

  .el-row {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }

  .titletext {
    font-size: 20px;
    color: rgb(8, 152, 248);
  }

  .el-image {
    width: 67pX;
    height: 57px;
  }

  .treeclass {
    width: 300px;
    margin: 0 auto;
  }
</style>
