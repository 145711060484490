<template>
<div>
    <el-card class="box-card">
      <span class="titletext">背景图</span>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                  <div>
                    <span class="demonstration">原背景图</span>
                    <div style="text-align: center;">
                        <el-image style="background-color: #00558c;"  :src="`${$FilesImg}${Career_dataCn.Backgroundimg}`"> <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i></div></el-image>
                    </div>
                  </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <span class="demonstration">修改背景图</span>
                <el-upload class="upload-demo" style="text-align: center;" ref="logofileref" action="" drag :limit="1"
                  :http-request="logofileref" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <div style="text-align:center">
            <el-button type="primary" @click="logoup">上传</el-button>
          </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
     <el-card class="box-card">
      <span class="titletext">中英文标题</span>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文标题</span>
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="Career_dataCn" label-width="80px">
              <el-form-item label="配图文字">
                <el-input  v-model="Career_dataCn.H1text"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Careeruppcn(Career_dataCn)" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文标题</span>
          <div class="grid-content bg-purple-light">
            <el-form ref="form" :model="Career_dataEn" label-width="80px">
              <el-form-item label="配图文字">
                <el-input  v-model="Career_dataEn.H1text"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Careeruppen(Career_dataEn)" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">菜单</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文菜单</span>
          <div class="grid-content bg-purple">
            <el-table :data="Career_dataCn.Career_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Text" label="菜单" align="center"></el-table-column>
            <el-table-column prop="Path" label="链接" align="center"></el-table-column>
            <el-table-column label="是否显示" align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Disabled" active-value="true" inactive-value="false" disabled></el-switch>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="list1cn(scope.row)" size="small">修改</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文菜单</span>
          <div class="grid-content bg-purple-light">
            <el-table :data="Career_dataEn.Career_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Text" label="菜单" align="center"></el-table-column>
            <el-table-column prop="Path" label="链接" align="center"></el-table-column>
            <el-table-column label="是否显示" align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Disabled" active-value="true" inactive-value="false" disabled></el-switch>
                </template>
              </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="list1en(scope.row)" size="small">修改</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文修改" :modal-append-to-body='false' :visible.sync="FormVisiblecn" width="500px">
        <el-form :model="dialog">
          <el-form-item label="菜单">
            <el-input v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="dialog.Path" disabled autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Disabled" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisiblecn = false">取 消</el-button>
          <el-button type="primary" @click="Career_menusuppcn(dialog)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文修改" :modal-append-to-body='false' :visible.sync="FormVisibleen" width="500px">
        <el-form :model="dialog">
          <el-form-item label="菜单">
            <el-input v-model="dialog.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="dialog.Path" disabled autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="dialog.Disabled" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisibleen = false">取 消</el-button>
          <el-button type="primary" @click="Career_menusuppen(dialog)">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Career_dataCn: {
        Backgroundimg: '',
        H1text: '',
        Career_list: []
      },
      Career_dataEn: {
        Backgroundimg: '',
        H1text: '',
        Career_list: []
      },
      logofile: '',
      dialog: '',
      FormVisiblecn: false,
      FormVisibleen: false
    }
  },
  created () {
    this.CareerCn()
    this.CareerEn()
  },
  mounted () {

  },
  methods: {
    async CareerCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Career`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Career_dataCn = res.response
    },
    async CareerEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Career`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Career_dataEn = res.response
    },
    logofileref (param) {
      this.logofile = param.file
    },
    async logoup () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.logofileref.submit()
      var File = new FormData()
      File.append('file', this.logofile)
      const { data: res } = await this.$http.post(`${this.$admin}Career_logoup`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.logofile = ''
      this.$refs.logofileref.clearFiles()
      this.CareerCn()
    },
    list1cn (row) {
      this.dialog = row
      this.FormVisiblecn = true
    },
    list1en (row) {
      this.dialog = row
      this.FormVisibleen = true
    },
    async Career_menusuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Career_menusuppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.FormVisiblecn = false
      this.CareerCn()
    },
    async Career_menusuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Career_menusuppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.FormVisibleen = false
      this.CareerEn()
    },
    async Careeruppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Careeruppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
    },
    async Careeruppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Careeruppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
