<template>
<div class="adminprod">
    <el-card class="box-card">
      <span class="titletext">背景图</span>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                  <div>
                    <span class="demonstration">原背景图</span>
                    <div style="text-align: center;">
                        <el-image style="background-color: #00558c;"  :src="`${$FilesImg}${Solution_dataCn.Backgroundimg}`"> <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i></div></el-image>
                    </div>
                  </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <span class="demonstration">修改</span>
                <el-upload class="upload-demo" style="text-align: center;" ref="backimg" action="" drag :limit="1"
                  :http-request="backimg" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </div>
              <div style="text-align:center">
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="background">上传</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">标题</span>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <span class="demonstration">中文标题</span>
                <div style="text-align: center;">
                  <span class="demonstration" v-html="Solution_dataCn.H1text"></span>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <span class="demonstration">英文标题</span>
                <div style="text-align: center;">
                  <span class="demonstration" v-html="Solution_dataEn.H1text"></span>
                </div>
              </div>
            </el-col>
          </el-row>
          <div style="text-align:center">
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="TiTconf">修改</el-button>
            </div>
          </div>
    </el-card>
    <!---中英文编辑ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="titlevisv" width="500px">
       <el-form :model="dTitle">
          <el-form-item label="修改文本">
            <el-input v-model="dTitle.Text1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="修改文本">
            <el-input v-model="dTitle.Text2" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="titlevisv = false">取 消</el-button>
        <el-button type="primary" @click="xgtitle(dTitle)">确 定</el-button>
      </div>
    </el-dialog>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">List中文</span>
        <el-row>
          <el-col :span="24"><div class="grid-content bg-purple-dark">
            <el-table :data="Solution_dataCn.Solution_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Imgsrc" label="图名称" align="center"></el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="文本" align="center"></el-table-column>
              <el-table-column align="center">
              <template slot="header">
                <el-button type="primary" @click="listvisaddvisCn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="xglistcn(scope.row)" size="small">修改</el-button>
                <el-button type="primary" @click="drawaercn(scope.row)" size="small">详情编辑</el-button>
                <el-button type="danger" @click="listdelcn(scope.row.Id)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div></el-col>
        </el-row>
        <!---List添加ul-->
    <el-dialog title="中文新增" :modal-append-to-body='false' :visible.sync="listvisaddvisCn" width="500px">
       <el-form :model="List">
         <el-form-item label="上传图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="listquecn" action="" drag :limit="1"
                  :http-request="listquecn" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input v-model="List.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="listvisaddvisCn = false">取 消</el-button>
        <el-button type="primary" @click="listaddcn(List)">确 定</el-button>
      </div>
    </el-dialog>
      <!---List修改ul-->
    <el-dialog title="中文新增" :modal-append-to-body='false' :visible.sync="listvisxgvisCn" width="500px">
       <el-form :model="List">
         <el-form-item label="上传图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="listxgquecn" action="" drag :limit="1"
                  :http-request="listxgquecn" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input v-model="List.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="listvisxgvisCn = false">取 消</el-button>
        <el-button type="primary" @click="listxgcn(List)">确 定</el-button>
      </div>
    </el-dialog>
    </el-card>
    <el-card class="box-card">
      <span class="titletext">List英文</span>
        <el-row>
          <el-col :span="24"><div class="grid-content bg-purple-dark">
            <el-table :data="Solution_dataEn.Solution_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Imgsrc" label="图名称" align="center"></el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="文本" align="center"></el-table-column>
              <el-table-column align="center">
              <template slot="header">
                <el-button type="primary" @click="listvisaddvisEn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="xglisten(scope.row)" size="small">修改</el-button>
                <el-button type="primary" @click="drawaeren(scope.row)" size="small">详情编辑</el-button>
                <el-button type="danger" @click="listdelen(scope.row.Id)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div></el-col>
        </el-row>
        <!---List添加ul-->
    <el-dialog title="英文新增" :modal-append-to-body='false' :visible.sync="listvisaddvisEn" width="500px">
       <el-form :model="List">
          <el-form-item label="图片名">
            <el-input v-model="List.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input v-model="List.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="listvisaddvisEn = false">取 消</el-button>
        <el-button type="primary" @click="listadden(List)">确 定</el-button>
      </div>
    </el-dialog>
    <!---List修改ul-->
    <el-dialog title="英文修改" :modal-append-to-body='false' :visible.sync="listvisxgvisEn" width="500px">
       <el-form :model="List">
          <el-form-item label="图片名">
            <el-input v-model="List.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input v-model="List.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="listvisxgvisEn = false">取 消</el-button>
        <el-button type="primary" @click="listxgen(List)">确 定</el-button>
      </div>
    </el-dialog>
    </el-card>
    <el-dialog title="中文修改详情页" :modal-append-to-body='false' :before-close="handleClose" :visible.sync="drawer1" width="80%">
        <el-form :model="Connectivity_datacn">
          <el-form-item label="文本">
            <el-input type="textarea" :rows="10" v-model="Connectivity_datacn.P1text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="Connectivity_datacn.Zhongs" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="Connectivity_datacn.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input type="textarea" :rows="5" v-model="Connectivity_datacn.H2text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-row>
              <el-col :span="12"><div class="grid-content bg-purple">
                  <el-upload class="upload-demo" style="text-align: center;" ref="list_listagx1quecn" action="" drag :limit="1"
                  :http-request="list_listagx1quecn" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
                </div></el-col>
              <el-col :span="12"><div class="grid-content bg-purple-light">
                  <el-image :src="`${$FilesImg}${Connectivity_datacn.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div></el-col>
            </el-row>
          </el-form-item>
           <el-form-item label="是否显示">
            <el-switch v-model="Connectivity_datacn.Zhong" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
           <el-form-item label="文本">
            <el-input type="textarea" :rows="5" v-model="Connectivity_datacn.P2text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="Connectivity_datacn.Bootom" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="背景颜色">
            <el-color-picker v-model="Connectivity_datacn.Bootombackgroundcolor"></el-color-picker>
          </el-form-item>
        </el-form>
        <el-table :data="Connectivity_datacn.Connectivity_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Imgsrc" label="图名称" align="center"></el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="文本" align="center"></el-table-column>
              <el-table-column prop="Switchable" label="图片左右默认左" align="center"></el-table-column>
              <el-table-column align="center">
              <template slot="header">
                <el-button type="primary" @click="list_listaddcn = true">新详情增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="listlist_listxg(scope.row)" size="small">修改</el-button>
                <el-button type="danger" @click="list_listdelcn(scope.row.Id)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="drawer1 = false">取 消</el-button>
          <el-button type="primary" @click="cillistupddcn(Connectivity_datacn)">确 定</el-button>
        </span>
    </el-dialog>
       <!---详情页List添加ul-->
    <el-dialog title="中文详情页新增" :modal-append-to-body='false' :visible.sync="list_listaddcn" width="500px">
       <el-form :model="List_list">
         <el-form-item label="上传图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="list_listaddquecn" action="" drag :limit="1"
                  :http-request="list_listaddquecn" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List_list.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input type="textarea" :rows="8" v-model="List_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input v-model="List_list.Switchable" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="list_listaddcn = false">取 消</el-button>
        <el-button type="primary" @click="list_list1addcn(List_list)">确 定</el-button>
      </div>
    </el-dialog>
      <!---详情页List修改ul-->
    <el-dialog title="中文详情页修改" :modal-append-to-body='false' :visible.sync="list_listgxcn" width="500px">
       <el-form :model="List_list">
         <el-form-item label="上传图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="list_listlstxg" action="" drag :limit="1"
                  :http-request="list_listlstxg" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List_list.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input type="textarea" :rows="5" v-model="List_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片左右">
            <el-input v-model="List_list.Switchable" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="list_listgxcn = false">取 消</el-button>
        <el-button type="primary" @click="listxgxgcn(List_list)">确 定</el-button>
      </div>
    </el-dialog>
      <!---详情页英文List修改ul-->
    <el-dialog title="英文详情页修改" :modal-append-to-body='false' :visible.sync="list_listgxen" width="500px">
       <el-form :model="List_list">
         <el-form-item label="图片名称">
            <el-input v-model="List_list.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List_list.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input type="textarea" :rows="5" v-model="List_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片左右">
            <el-input v-model="List_list.Switchable" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="list_listgxen = false">取 消</el-button>
        <el-button type="primary" @click="listxgxgen(List_list)">确 定</el-button>
      </div>
    </el-dialog>
      <!--英文修改详情页-->
        <el-dialog title="英文修改详情页" :modal-append-to-body='false' :before-close="handleClose" :visible.sync="drawer2" width="80%">
        <el-form :model="Connectivity_dataen">
          <el-form-item label="文本">
            <el-input type="textarea" :rows="10" v-model="Connectivity_dataen.P1text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="Connectivity_dataen.Zhongs" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="Connectivity_dataen.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input type="textarea" :rows="5" v-model="Connectivity_dataen.H2text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-input v-model="Connectivity_dataen.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片">
             <el-image :src="`${$FilesImg}${Connectivity_dataen.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="Connectivity_dataen.Zhong" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
           <el-form-item label="文本">
            <el-input type="textarea" :rows="5" v-model="Connectivity_dataen.P2text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="Connectivity_dataen.Bootom" active-value="true" inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="背景颜色">
            <el-color-picker v-model="Connectivity_dataen.Bootombackgroundcolor"></el-color-picker>
          </el-form-item>
        </el-form>
        <el-table :data="Connectivity_dataen.Connectivity_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Imgsrc" label="图名称" align="center"></el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="文本" align="center"></el-table-column>
              <el-table-column prop="Switchable" label="图片左右默认左" align="center"></el-table-column>
              <el-table-column align="center">
              <template slot="header">
                <el-button type="primary" @click="list_listadden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="listlist_listxgc(scope.row)" size="small">修改</el-button>
                <el-button type="danger" @click="list_listdelen(scope.row.Id)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="drawer2 = false">取 消</el-button>
          <el-button type="primary" @click="cillistupdden(Connectivity_dataen)">确 定</el-button>
        </span>
    </el-dialog>
       <!---详情页List添加ul-->
    <el-dialog title="英文详情页新增" :modal-append-to-body='false' :visible.sync="list_listadden" width="500px">
       <el-form :model="List_list">
         <el-form-item label="上传图片">
            <el-input v-model="List_list.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加标题">
            <el-input v-model="List_list.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input type="textarea" :rows="8" v-model="List_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="添加文本">
            <el-input  v-model="List_list.Switchable" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="list_listadden = false">取 消</el-button>
        <el-button type="primary" @click="list_list1adden(List_list)">确 定</el-button>
      </div>
    </el-dialog>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Solution_dataCn: {
        Backgroundimg: '',
        H1text: '',
        Solution_list1: []
      },
      Solution_dataEn: {
        Backgroundimg: '',
        H1text: '',
        Solution_list1: []
      },
      backimgfile: '',
      titlevisv: false,
      dTitle: {
        Text1: '',
        Text2: ''
      },
      List: {
        Id: '',
        Imgsrc: '',
        Title: '',
        Text: ''
      },
      listvisaddvisCn: false,
      listvisaddvisEn: false,
      listfileCn: '',
      listvisxgvisCn: false,
      listvisxgvisEn: false,
      dialog: [],
      drawer1: false,
      drawer2: false,
      Connectivity_datacn: [],
      Connectivity_dataen: [],
      List_list: {
        Id: '',
        Imgsrc: '',
        Title: '',
        Text: '',
        Cid: '',
        Switchable: 'switchable'
      },
      list_listaddcn: false,
      list_listadden: false,
      listimgfile: '',
      list_listgxcn: false,
      list_listgxen: false,
      list_lisgxgxfile: '',
      Cid: '',
      listflie123: ''
    }
  },
  created () {
    this.SolutionCn()
    this.SolutionEn()
  },
  mounted () {

  },
  methods: {
    async SolutionCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Solution`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Solution_dataCn = res.response
    },
    async SolutionEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Solution`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Solution_dataEn = res.response
    },
    backimg (param) {
      this.backimgfile = param.file
    },
    list_listlstxg (param) {
      this.list_lisgxgxfile = param.file
    },
    listlist_listxg (row) {
      this.List_list = row
      this.list_listgxcn = true
    },
    listlist_listxgc (row) {
      this.List_list = row
      this.list_listgxen = true
    },
    async background () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.backimg.submit()
      var File = new FormData()
      File.append('file', this.backimgfile)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_backudp`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.backimgfile = ''
      this.$refs.backimg.clearFiles()
      this.SolutionCn()
      this.SolutionEn()
    },
    TiTconf () {
      this.dTitle.Text1 = this.Solution_dataCn.H1text
      this.dTitle.Text2 = this.Solution_dataEn.H1text
      this.titlevisv = true
    },
    async xgtitle (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}Solution_text`, row)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.titlevisv = false
      this.$message.success('修改成功')
      this.SolutionCn()
      this.SolutionEn()
    },
    listquecn (param) {
      this.listfileCn = param.file
    },
    listxgquecn (param) {
      this.listfileCn = param.file
    },
    list_listaddquecn (param) {
      this.listimgfile = param.file
    },
    async listaddcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.listquecn.submit()
      var File = new FormData()
      File.append('Img', this.listfileCn)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_listaddcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.listfileCn = ''
      this.listvisaddvisCn = false
      this.$refs.listquecn.clearFiles()
      this.SolutionCn()
    },
    async listadden (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Imgsrc', row.Imgsrc)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_listadden`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.listvisaddvisEn = false
      this.$message.success('上传成功')
      this.SolutionEn()
    },
    xglistcn (row) {
      this.List = row
      this.listvisxgvisCn = true
    },
    xglisten (row) {
      this.List = row
      this.listvisxgvisEn = true
    },
    async listxgcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.listxgquecn.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Img', this.listfileCn)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_listxgcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.listfileCn = ''
      this.listvisxgvisCn = false
      this.$refs.listxgquecn.clearFiles()
      this.SolutionCn()
    },
    async listxgen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_listxgen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.listfileCn = ''
      this.listvisxgvisEn = false
      this.$message.success('上传成功')
      this.listfileCn = ''
      this.SolutionEn()
    },
    async listdelcn (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Solution_listdelcn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.$message.success('修改成功')
      this.SolutionCn()
    },
    async listdelen (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}Solution_listdelen?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.$message.success('修改成功')
      this.SolutionEn()
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async drawaercn (row) {
      this.Cid = row
      const { data: res } = await this.$http.get(`${this.$httpcn}Connectivity?Id=${row.Id}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Connectivity_datacn = res.response
      this.drawer1 = true
    },
    async drawaeren (row) {
      this.Cid = row
      const { data: res } = await this.$http.get(`${this.$httpen}Connectivity?Id=${row.Id}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Connectivity_dataen = res.response
      this.drawer2 = true
    },
    async list_list1addcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.list_listaddquecn.submit()
      var File = new FormData()
      File.append('Img', this.listimgfile)
      File.append('Title', row.Title)
      File.append('Cid', this.Connectivity_datacn.Id)
      File.append('Text', row.Text)
      File.append('Switchable', row.Switchable)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_lista_lsitddcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.listimgfile = ''
      this.list_listaddcn = false
      this.$refs.list_listaddquecn.clearFiles()
      this.drawaercn(this.Cid)
    },
    async list_list1adden (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Imgsrc', row.Imgsrc)
      File.append('Title', row.Title)
      File.append('Cid', this.Connectivity_dataen.Id)
      File.append('Text', row.Text)
      File.append('Switchable', row.Switchable)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_lista_lsitdden`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list_listadden = false
      this.drawaeren(this.Cid)
    },
    async listxgxgcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.list_listlstxg.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Img', this.list_lisgxgxfile)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('Switchable', row.Switchable)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_list_list1xgcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.backimgfile = ''
      this.$refs.list_listlstxg.clearFiles()
      this.list_listgxcn = false
      this.drawaercn(this.Cid)
    },
    async listxgxgen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('Switchable', row.Switchable)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_list_list1xgen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list_listgxen = false
      this.drawaeren(this.Cid)
    },
    async list_listdelcn (row) {
      var Id = row
      console.log(row)
      const { data: res } = await this.$axios.post(`${this.$admin}AdminCapabilitylist3__lisedelCn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.$message.success('删除成功')
      this.drawaercn(this.Cid)
    },
    async list_listdelen (row) {
      var Id = row
      const { data: res } = await this.$axios.post(`${this.$admin}AdminCapabilitylist3__lisedelEn?Id=${Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.$message.success('删除成功')
      this.SolutionEn()
      this.drawaeren(this.Cid)
    },
    list_listagx1quecn (param) {
      this.listflie123 = param.file
    },
    async cillistupddcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.list_listagx1quecn.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Img', this.listflie123)
      File.append('P1text', row.P1text)
      File.append('P2text', row.P2text)
      File.append('Title', row.Title)
      File.append('H2text', row.H2text)
      File.append('Zhongs', row.Zhongs)
      File.append('Zhong', row.Zhong)
      File.append('Bootom', row.Bootom)
      File.append('Bootombackgroundcolor', row.Bootombackgroundcolor)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_list_xg1cn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.backimgfile = ''
      this.$refs.list_listagx1quecn.clearFiles()
      this.drawer1 = false
      this.SolutionCn()
    },
    async cillistupdden (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('P1text', row.P1text)
      File.append('P2text', row.P2text)
      File.append('Title', row.Title)
      File.append('H2text', row.H2text)
      File.append('Zhongs', row.Zhongs)
      File.append('Zhong', row.Zhong)
      File.append('Bootom', row.Bootom)
      File.append('Bootombackgroundcolor', row.Bootombackgroundcolor)
      const { data: res } = await this.$http.post(`${this.$admin}Solution_list_xg1en`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.drawer2 = false
      this.SolutionEn()
    }
  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
  .el-drawer__header span:focus {
    outline: 0;
  }

</style>
