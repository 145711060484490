<template>
    <div>
        <section data-overlay="6" class="cover imagebg text-left space--sm">
            <div class="background-image-holder" :style="`background: url(${$FilesImg}${Global_data.Backgroundimg}); opacity: 1;`"><img alt="Nienyi"
                    :src="$FilesImg + Global_data.Backgroundimg"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="Global_data.H1text"></h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/#/Home" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li>{{this.$t('Pathyem.quaqjdian')}}</li>
                </ol>
            </div>
        </section>
        <section v-for="(item,index) in Global_data.Global_list1" :key="index">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="text-block py-4 mb-0">
                            <h4 class="font-weight-normal mb-0" v-html="item.Title"></h4>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-4" v-for="(itemm,indexx) in item.Global_list1_list" :key="indexx">
                                <h5 class="mb-0" v-html="itemm.Title"></h5>
                                <p v-html="itemm.Text"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Global_data: {
        Backgroundimg: '',
        H1text: '',
        Global_list1: []
      }
    }
  },
  created () {
    this.Global()
  },
  mounted () {

  },
  methods: {
    async Global () {
      const { data: res } = await this.$http.get('Global')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Global_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
