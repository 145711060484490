<template>
    <div>
        <section data-overlay="6" class="cover imagebg text-left space--sm">
            <div class="background-image-holder"
                :style="`background: url(${$FilesImg}${Career_data.Backgroundimg}); opacity: 1;`"><img alt="background"
                    :src="`${Career_data.Backgroundimg}`"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="Career_data.H1text"></h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/#/Home" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li>{{this.$t('Pathyem.rczp')}}</li>
                </ol>
            </div>
        </section>
        <section class="pb-0">
            <div class="container">
                <ul class="tabs">
                    <li v-for="(item,index) in Career_data.Career_list" :key="index" v-show="true ? item.Disabled == 'true':item.Disabled == 'false'" >
                        <div class="tab__title"><a :href="`/#/${item.Path}`" class="h5 router-link-exact-active router-link-active" v-html="item.Text" ></a></div>
                    </li>
                </ul>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Career_data: {
        Backgroundimg: '',
        Career_list: []
      }
    }
  },
  created () {
    this.Career()
  },
  mounted () {

  },
  methods: {
    async Career () {
      const { data: res } = await this.$http.get('Career')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Career_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
