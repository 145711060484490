<template>
  <div>
    <section v-for="(item,index) in Nienyi_data" :key="index" :class="item.Class">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-md-6"><img :alt="item.Name" :src="`${$FilesImg}${item.Imgsrc}`"
              class="border--round box-shadow-wide"></div>
          <div class="col-md-6 col-lg-5">
            <div class="switchable__text"><span v-html="item.Name"></span>
              <h2 class="h4" v-html="item.Title"></h2>
              <h3 class="h3" v-html="item.Text"></h3><a :href="`/#/Family/${item.Id}`" class="">看看他的故事 »</a>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
    </section>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Nienyi_data: {
        Class: '',
        Imgsrc: '',
        Name: '',
        Title: '',
        Text: ''
      }
    }
  },
  created () {
    this.Nienyi()
  },
  mounted () {
  },
  methods: {
    async Nienyi () {
      const { data: res } = await this.$http.get('Nienyi')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Nienyi_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
