<template>
  <div>
    <el-card class="box-card">
      <span class="titletext">首页视频</span>
      <el-row>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <span class="demonstration">修改webm视频</span>
              <el-upload class="upload-demo" ref="webm" action="" drag :limit="1" :http-request="webmquest"
                  :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将视频文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传webm文件
              </div>
            </el-upload>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            <span class="demonstration">修mp4视频</span>
            <el-upload class="upload-demo" ref="mp4" action="" drag :limit="1" :http-request="mp4quest"
                  :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将视频文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传MP4文件
              </div>
            </el-upload>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <span class="demonstration">修改ogv视频</span>
            <el-upload class="upload-demo" ref="ogv" action="" drag :limit="1" :http-request="ogvquest"
                  :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将视频文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传ogv文件
              </div>
            </el-upload>
          </div>
        </el-col>
      </el-row>
      <div style="text-align:center">
          <el-button type="primary" @click="tijiao">上传</el-button>
      </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">首页展示图片</span>
      <el-row>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <span class="demonstration">修改展示图片1</span>
            <el-upload class="upload-demo" ref="img1" action="" drag :limit="1" :http-request="mquestimg1"
                  :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将图片文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传图片文件
              </div>
            </el-upload>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            <span class="demonstration">修展示图片2</span>
            <el-upload class="upload-demo" ref="img2" action="" drag :limit="1" :http-request="mquestimg2"
                  :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将图片文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传图片文件
              </div>
            </el-upload>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <span class="demonstration">修改展示图片3</span>
            <el-upload class="upload-demo" ref="img3" action="" drag :limit="1" :http-request="mquestimg3"
                  :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将图片文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传图片文件
              </div>
            </el-upload>
          </div>
        </el-col>
      </el-row>
      <div style="text-align:center">
          <el-button type="primary" @click="tijiao2">上传</el-button>
      </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">首页解决方案LIst</span>
      <el-row>
        <el-table :data="Home_data.Xqdata" border style="width: 100%">
          <el-table-column prop="Span" label="数值" >
          </el-table-column>
          <el-table-column label="图片" >
            <template slot-scope="scope">
            <el-image  :src="`${$FilesImg}${scope.row.Imgsrc}`"> <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i></div></el-image></template>
          </el-table-column>
          <el-table-column prop="Imgsrc" label="图片名">
          </el-table-column>
          <el-table-column prop="H4" label="文本">
          </el-table-column>
          <el-table-column fixed="right" label="操作">
             <template slot-scope="scope">
              <el-button @click="xg(scope.row)" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible" width="500px">
      <el-form :model="dialog">
        <el-form-item label="修改数值" >
          <el-input v-model="dialog.Span" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改图片" >
          <el-upload class="upload-demo" ref="Listdataimg" action="" drag :limit="1" :http-request="Listdataimg"
                  :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传图片文件
              </div>
            </el-upload>
        </el-form-item>
        <el-form-item label="修改文本" >
          <el-input v-model="dialog.H4" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改英文文本" >
          <el-input v-model="dialog.H4" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="listdata(dialog)">确 定</el-button>
  </div>
    </el-dialog>
        <el-card class="box-card">
      <span class="titletext">首页解决方案英文</span>
      <el-row>
        <el-table :data="Home_dataEn.Xqdata" border style="width: 100%">
          <el-table-column prop="Span" label="数值" >
          </el-table-column>
          <el-table-column label="图片" >
            <template slot-scope="scope">
            <el-image  :src="`${$FilesImg}${scope.row.Imgsrc}`"> <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i></div></el-image></template>
          </el-table-column>
          <el-table-column prop="Imgsrc" label="图片名">
          </el-table-column>
          <el-table-column prop="H4" label="文本">
          </el-table-column>
          <el-table-column fixed="right" label="操作">
             <template slot-scope="scope">
              <el-button @click="xga(scope.row)" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible1" width="500px">
      <el-form :model="dialog">
        <el-form-item label="修改英文数值" >
          <el-input v-model="dialog.Span" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改英文图片" >
          <el-input v-model="dialog.Imgsrc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改英文文本" >
          <el-input v-model="dialog.H4" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible1 = false">取 消</el-button>
    <el-button type="primary" @click="listdata1(dialog)">确 定</el-button>
  </div>
    </el-dialog>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">首页文本</span>
      <el-row>
        <el-form ref="form" :model="Home_data" label-width="150px">
          <el-form-item label="视频上标题">
            <el-input v-model="Home_data.Home.Home_titeh1"></el-input>
          </el-form-item>
          <el-form-item label="视频上标题英文">
            <el-input v-model="Home_dataEn.Home.Home_titeh1"></el-input>
          </el-form-item>
          <el-form-item label="视频上文本">
            <el-input v-model="Home_data.Home.Home_text1"></el-input>
          </el-form-item>
          <el-form-item label="视频上文本英文">
            <el-input v-model="Home_dataEn.Home.Home_text1"></el-input>
          </el-form-item>
          <el-form-item label="视频上按钮文本">
            <el-input v-model="Home_data.Home.Home_but"></el-input>
          </el-form-item>
          <el-form-item label="视频上按钮文本英文">
            <el-input v-model="Home_dataEn.Home.Home_but"></el-input>
          </el-form-item>
          <el-form-item label="解决方案标题">
            <el-input v-model="Home_data.Home.Home_titeh2"></el-input>
          </el-form-item>
          <el-form-item label="解决方案标题英文">
            <el-input v-model="Home_dataEn.Home.Home_titeh2"></el-input>
          </el-form-item>
          <el-form-item label="解决方案文本">
            <el-input v-model="Home_data.Home.Home_text2"></el-input>
          </el-form-item>
          <el-form-item label="解决方案文本英文">
            <el-input v-model="Home_dataEn.Home.Home_text2"></el-input>
          </el-form-item>
          <el-form-item label="解决方案按钮文本">
            <el-input v-model="Home_data.Home.Home_but_span1"></el-input>
          </el-form-item>
          <el-form-item label="解决方案按钮文本英文">
            <el-input v-model="Home_dataEn.Home.Home_but_span1"></el-input>
          </el-form-item>
          <el-form-item label="样式展示1文本">
            <el-input v-model="Home_data.Listdata.Text1"></el-input>
          </el-form-item>
          <el-form-item label="样式展示1文本英文">
            <el-input v-model="Home_dataEn.Listdata.Text1"></el-input>
          </el-form-item>
          <el-form-item label="样式展示1按钮文本">
            <el-input v-model="Home_data.Listdata.Span1"></el-input>
          </el-form-item>
          <el-form-item label="样式展示1按钮文本英文">
            <el-input v-model="Home_dataEn.Listdata.Span1"></el-input>
          </el-form-item>
          <el-form-item label="样式展示1链接">
            <el-input v-model="Home_data.Listdata.Href1"></el-input>
          </el-form-item>
          <el-form-item label="样式展示2文本">
            <el-input v-model="Home_data.Listdata.Text2"></el-input>
          </el-form-item>
          <el-form-item label="样式展示2文本英文">
            <el-input v-model="Home_dataEn.Listdata.Text2"></el-input>
          </el-form-item>
          <el-form-item label="样式展示2按钮文本">
            <el-input v-model="Home_data.Listdata.Span2"></el-input>
          </el-form-item>
          <el-form-item label="样式展示2按钮文本英文">
            <el-input v-model="Home_dataEn.Listdata.Span2"></el-input>
          </el-form-item>
          <el-form-item label="样式展示2链接">
            <el-input v-model="Home_data.Listdata.Href2"></el-input>
          </el-form-item>
          <el-form-item label="样式展示3文本">
            <el-input v-model="Home_data.Listdata.Text3"></el-input>
          </el-form-item>
          <el-form-item label="样式展示3文本英文">
            <el-input v-model="Home_dataEn.Listdata.Text3"></el-input>
          </el-form-item>
          <el-form-item label="样式展示3按钮文本">
            <el-input v-model="Home_data.Listdata.Span2"></el-input>
          </el-form-item>
          <el-form-item label="样式展示3按钮文本英文">
            <el-input v-model="Home_dataEn.Listdata.Span2"></el-input>
          </el-form-item>
          <el-form-item label="样式展示3链接">
            <el-input v-model="Home_data.Listdata.Href3"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即修改</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5" />
     <el-card class="box-card teshu">
      <span class="titletext">首页下方文案LIst</span>
      <el-row>
        <el-table :data="Home_data.Newsdata" border style="width: 100% margin-bottom: auto;">
          <el-table-column prop="Home_href" label="链接" >
          </el-table-column>
          <el-table-column prop="Home_p" label="文案">
          </el-table-column>
          <el-table-column prop="Home_h4" label="按钮文案">
          </el-table-column>
          <el-table-column fixed="right" label="操作">
             <template slot-scope="scope">
              <el-button @click="xgaa(scope.row)" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible2" width="500px">
      <el-form :model="dialog">
        <el-form-item label="修改链接" >
          <el-input v-model="dialog.Home_href" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改文案" >
          <el-input type="textarea" v-model="dialog.Home_p" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改按钮文案" >
          <el-input v-model="dialog.Home_h4" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible2 = false">取 消</el-button>
    <el-button type="primary" @click="newdata(dialog)">确 定</el-button>
  </div>
    </el-dialog>
     <el-card class="box-card teshu">
      <span class="titletext">首页下方文案英文</span>
      <el-row>
        <el-table :data="Home_dataEn.Newsdata" border style="width: 100% margin-bottom: auto;">
          <el-table-column prop="Home_href" label="链接" >
          </el-table-column>
          <el-table-column prop="Home_p" label="文案">
          </el-table-column>
          <el-table-column prop="Home_h4" label="按钮文案">
          </el-table-column>
          <el-table-column fixed="right" label="操作">
             <template slot-scope="scope">
              <el-button @click="xgaaa(scope.row)" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="dialogFormVisible3" width="500px">
      <el-form :model="dialog">
        <el-form-item label="修改链接" >
          <el-input v-model="dialog.Home_href" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改文案" >
          <el-input type="textarea" v-model="dialog.Home_p" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="修改按钮文案" >
          <el-input v-model="dialog.Home_h4" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible3 = false">取 消</el-button>
    <el-button type="primary" @click="newdata1(dialog)">确 定</el-button>
  </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: [],
      Home_data: {
        Home: {
          Home_titeh1: ''
        },
        Listdata: {
          Text1: ''
        }
      },
      Home_dataEn: {
        Home: {
          Home_titeh1: ''
        },
        Listdata: {
          Text1: ''
        }
      },
      webm: '',
      mp4: '',
      ogv: '',
      img1: '',
      img2: '',
      img3: '',
      dialog: [],
      dialogFormVisible: false,
      Listdataimg1: '',
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false
    }
  },
  created () {
    this.Homecn()
    this.Homnen()
  },
  mounted () {},
  methods: {
    async Homecn () {
      const { data: res } = await this.$axios.get(`${this.$httpcn}Home`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Home_data = res.response
    },
    async Homnen () {
      const { data: res } = await this.$axios.get(`${this.$httpen}Home`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Home_dataEn = res.response
    },
    async onSubmit () {
      var home = {
        Homecn: this.Home_data,
        Homeen: this.Home_dataEn
      }
      const { data: res } = await this.$axios.post(`${this.$admin}Home`, home)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Home_dataEn = res.response
    },
    xg (row) {
      this.dialog = row
      this.dialogFormVisible = true
    },
    xga (row) {
      this.dialog = row
      this.dialogFormVisible1 = true
    },
    xgaa (row) {
      this.dialog = row
      this.dialogFormVisible2 = true
    },
    xgaaa (row) {
      this.dialog = row
      this.dialogFormVisible3 = true
    },
    webmquest (param) {
      this.webm = param.file
    },
    mp4quest (param) {
      this.mp4 = param.file
    },
    ogvquest (param) {
      this.ogv = param.file
    },
    async tijiao () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.webm.submit()
      this.$refs.mp4.submit()
      this.$refs.ogv.submit()
      var File = new FormData()
      File.append('Webm', this.webm)
      File.append('Mp4', this.mp4)
      File.append('Ogv', this.ogv)
      File.append('Id', this.Home_data.Home.Id)
      const { data: res } = await this.$http.post(`${this.$admin}Home_video`, File, config.headers)
      if (res.status !== 200) return alert('上传成功')
      this.$message.success('添加成功')
      this.webm = []
      this.mp4 = []
      this.ogv = []
      this.$refs.webm.clearFiles()
      this.$refs.mp4.clearFiles()
      this.$refs.ogv.clearFiles()
    },
    mquestimg1 (param) {
      this.webm = param.file
    },
    mquestimg2 (param) {
      this.mp4 = param.file
    },
    mquestimg3 (param) {
      this.ogv = param.file
    },
    async tijiao2 () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.img1.submit()
      this.$refs.img2.submit()
      this.$refs.img3.submit()
      var File = new FormData()
      File.append('Img1', this.webm)
      File.append('Img2', this.mp4)
      File.append('Img3', this.ogv)
      File.append('Id', this.Home_data.Listdata.Id)
      const { data: res } = await this.$http.post(`${this.$admin}Home_Img`, File, config.headers)
      if (res.status !== 200) return alert('上传成功')
      this.$message.success('添加成功')
      this.img1 = []
      this.img2 = []
      this.img3 = []
      this.$refs.img1.clearFiles()
      this.$refs.img2.clearFiles()
      this.$refs.img3.clearFiles()
    },
    Listdataimg (param) {
      this.Listdataimg1 = param.file
    },
    async listdata (row) {
      console.log(row)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.Listdataimg.submit()
      var File = new FormData()
      File.append('Img', this.Listdataimg1)
      File.append('Span', row.Span)
      File.append('H4', row.H4)
      File.append('Id', row.Id)
      const { data: res } = await this.$http.post(`${this.$admin}Home_1`, File, config.headers)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.Listdataimg1 = []
      this.$refs.Listdataimg.clearFiles()
      this.dialog = ''
      this.dialogFormVisible = false
    },
    async listdata1 (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Home_2`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.dialog = ''
      this.dialogFormVisible1 = false
    },
    async newdata (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Home_new`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.dialog = ''
      this.dialogFormVisible2 = false
    },
    async newdata1 (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Home_new1`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.dialog = ''
      this.dialogFormVisible3 = false
    }
  }
}
</script>
<style lang="less" scoped>
.el-card {
  margin: 0 auto;
  width: 95%;
}

.el-image {
  width: 360px;
}

.el-form {
  margin: 0 auto;
  width: 80%;
}

.el-row {
  padding-top: 5.57142857em;
  padding-bottom: 5.57142857em;
}

.titletext {
  font-size: 20px;
  color: rgb(8, 152, 248);
}
.el-image{
  width: 67pX;
  height: 57px;
}
</style>
