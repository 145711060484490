<template>
<div>
     <el-card class="box-card">
      <span class="titletext">中英文标题</span>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文标题</span>
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="Opport_dataCn" label-width="80px">
              <el-form-item label="配图文字">
                <el-input type="textarea" :rows="3"  v-model="Opport_dataCn.Title"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Opportuppcn(Opport_dataCn)" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文标题</span>
          <div class="grid-content bg-purple-light">
            <el-form ref="form" :model="Opport_dataEn" label-width="80px">
              <el-form-item label="配图文字">
                <el-input type="textarea" :rows="3"  v-model="Opport_dataEn.Title"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Opportuppen(Opport_dataEn)" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">菜单</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文菜单</span>
          <div class="grid-content bg-purple">
            <el-table :data="Opport_dataCn.Opport_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="菜单" align="center"></el-table-column>
            <el-table-column align="center">
               <template slot="header">
                  <el-button type="primary" @click="FormVisiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
              <template slot-scope="scope">
               <el-button type="primary" @click="oppdatacn(scope.row)" size="small">修改</el-button>
                <el-button type="warning" @click="Opport_listcn(scope.row.Id)" size="small">编辑</el-button>
                <el-button type="danger" @click="Opport_delcn(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文菜单</span>
          <div class="grid-content bg-purple-light">
            <el-table :data="Opport_dataEn.Opport_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="菜单" align="center"></el-table-column>
            <el-table-column align="center">
               <template slot="header">
                  <el-button type="primary" @click="FormVisibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="oppdataen(scope.row)" size="small">修改</el-button>
                <el-button type="warning" @click="Opport_listen(scope.row.Id)" size="small">编辑</el-button>
                <el-button type="danger" @click="Opport_delen(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文添加" :modal-append-to-body='false' :visible.sync="FormVisiblecn" width="500px">
        <el-form :model="dialoge">
          <el-form-item label="国家">
            <el-input v-model="dialoge.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisiblecn = false">取 消</el-button>
          <el-button type="primary" @click="Opport_addcn(dialoge)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文添加" :modal-append-to-body='false' :visible.sync="FormVisibleen" width="500px">
        <el-form :model="dialoge">
          <el-form-item label="国家">
            <el-input v-model="dialoge.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisibleen = false">取 消</el-button>
          <el-button type="primary" @click="Opport_adden(dialoge)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文修改" :modal-append-to-body='false' :visible.sync="list1visblecn" width="500px">
        <el-form :model="dialog">
          <el-form-item label="国家">
            <el-input v-model="dialog.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1visblecn = false">取 消</el-button>
          <el-button type="primary" @click="Opport_listuppcn(dialog)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文修改" :modal-append-to-body='false' :visible.sync="list1visbleen" width="500px">
        <el-form :model="dialog">
          <el-form-item label="国家">
            <el-input v-model="dialog.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1visbleen = false">取 消</el-button>
          <el-button type="primary" @click="Opport_listuppen(dialog)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文详情" :modal-append-to-body='false' :visible.sync="list_listxqcn" >
        <el-table :data="list1_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="招聘平台名称" align="center"></el-table-column>
          <el-table-column prop="Text" label="链接文本" align="center"></el-table-column>
          <el-table-column prop="Url" label="链接" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list_listaddcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="listlistuppcn(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Opport_list_delcn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="英文详情" :modal-append-to-body='false' :visible.sync="list_listxqen" >
        <el-table :data="list1_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="招聘平台名称" align="center"></el-table-column>
          <el-table-column prop="Text" label="链接文本" align="center"></el-table-column>
          <el-table-column prop="Url" label="链接" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list_listadden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="listlistuppen(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Opport_list_delen(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="中文详情添加" :modal-append-to-body='false' :visible.sync="list_listaddcn" width="500px">
        <el-form :model="list_list">
          <el-form-item label="招聘平台名称">
            <el-input v-model="list_list.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接文本">
            <el-input v-model="list_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="list_list.Url" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list_listaddcn = false">取 消</el-button>
          <el-button type="primary" @click="Opport_list_addcn(list_list)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文详情添加" :modal-append-to-body='false' :visible.sync="list_listadden" width="500px">
        <el-form :model="list_list">
          <el-form-item label="招聘平台名称">
            <el-input v-model="list_list.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接文本">
            <el-input v-model="list_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="list_list.Url" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list_listadden = false">取 消</el-button>
          <el-button type="primary" @click="Opport_list_adden(list_list)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文详情修改" :modal-append-to-body='false' :visible.sync="list_listuppcn" width="500px">
        <el-form :model="list_listxq">
          <el-form-item label="招聘平台名称">
            <el-input v-model="list_listxq.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接文本">
            <el-input v-model="list_listxq.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="list_listxq.Url" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list_listuppcn = false">取 消</el-button>
          <el-button type="primary" @click="Opport_list_uppcn(list_listxq)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文详情修改" :modal-append-to-body='false' :visible.sync="list_listuppen" width="500px">
        <el-form :model="list_listxq">
          <el-form-item label="招聘平台名称">
            <el-input v-model="list_listxq.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接文本">
            <el-input v-model="list_listxq.Text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input v-model="list_listxq.Url" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list_listuppen = false">取 消</el-button>
          <el-button type="primary" @click="Opport_list_uppen(list_listxq)">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Opport_dataCn: {
        Title: '',
        Opport_list1: []
      },
      Opport_dataEn: {
        Title: '',
        Opport_list1: []
      },
      dialog: {
        Title: '',
        Id: 0
      },
      dialoge: {
        Title: '',
        Id: 0
      },
      FormVisiblecn: false,
      FormVisibleen: false,
      list1visblecn: false,
      list1visbleen: false,
      list_listxqcn: false,
      list_listxqen: false,
      Id: '',
      list1_list: [],
      list_listaddcn: false,
      list_listadden: false,
      list_listuppcn: false,
      list_listuppen: false,
      list_list: {
        Id: 0,
        Cid: 0,
        Title: '',
        Text: '',
        Url: ''
      },
      list_listxq: {
        Id: 0,
        Cid: 0,
        Title: '',
        Text: '',
        Url: ''
      }
    }
  },
  created () {
    this.OpportCn()
    this.OpportEn()
  },
  mounted () {

  },
  methods: {
    async OpportCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Opport`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Opport_dataCn = res.response
    },
    async OpportEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Opport`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Opport_dataEn = res.response
    },
    async Opportuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opportuppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.OpportCn()
    },
    async Opportuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opportuppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.OpportEn()
    },
    async Opport_addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_addcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.OpportCn()
      this.FormVisiblecn = false
    },
    async Opport_adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_adden`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.OpportEn()
      this.FormVisibleen = false
    },
    oppdatacn (row) {
      this.dialog = row
      this.list1visblecn = true
    },
    oppdataen (row) {
      this.dialog = row
      this.list1visbleen = true
    },
    async Opport_listuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_listuppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.OpportCn()
      this.list1visblecn = false
    },
    async Opport_listuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_listuppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.OpportEn()
      this.list1visbleen = false
    },
    async Opport_listcn (row) {
      this.Id = row
      const { data: res } = await this.$http.post(`${this.$admin}Opport_listcn?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list1_list = res.response
      this.list_listxqcn = true
    },
    async Opport_listen (row) {
      this.Id = row
      const { data: res } = await this.$http.post(`${this.$admin}Opport_listen?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list1_list = res.response
      this.list_listxqen = true
    },
    async Opport_list_addcn (row) {
      row.Cid = this.Id
      const { data: res } = await this.$http.post(`${this.$admin}Opport_list_addcn`, row)
      if (res.status !== 200) return alert('获取失败')
      this.list_listaddcn = false
      this.Opport_listcn(this.Id)
    },
    async Opport_list_adden (row) {
      row.Cid = this.Id
      const { data: res } = await this.$http.post(`${this.$admin}Opport_list_adden`, row)
      if (res.status !== 200) return alert('获取失败')
      this.list_listadden = false
      this.Opport_listen(this.Id)
    },
    listlistuppcn (row) {
      this.list_listxq = row
      this.list_listuppcn = true
    },
    listlistuppen (row) {
      this.list_listxq = row
      this.list_listuppen = true
    },
    async Opport_list_uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_list_uppcn`, row)
      if (res.status !== 200) return alert('获取失败')
      this.list_listuppcn = false
      this.Opport_listcn(this.Id)
    },
    async Opport_list_uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_list_uppen`, row)
      if (res.status !== 200) return alert('获取失败')
      this.list_listuppen = false
      this.Opport_listen(this.Id)
    },
    async Opport_list_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_list_delcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.Opport_listcn(this.Id)
    },
    async Opport_list_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_list_delen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.Opport_listen(this.Id)
    },
    async Opport_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_delcn`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.OpportCn()
    },
    async Opport_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Opport_delen`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.OpportEn()
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
