import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/base.css'
import './assets/CSS/global.css'
import './assets/CSS/Nienyi.css'
import axios from 'axios'
import i18n from './i18n'
// 配置axios
var ipcn = 'https://www.nienyi.com/api/FrontendEn/'
var ipen = 'https://www.nienyi.com/api/FrontendCn/'
if (localStorage.getItem('locale') === 'en') {
  axios.defaults.baseURL = ipcn
} else {
  axios.defaults.baseURL = ipen
}
axios.interceptors.request.use(config => {
  if (config.url.indexOf('/admin/') !== -1) {
    config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('token')
  }
  return config
})
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$axios = axios
Vue.prototype.$admin = 'https://www.nienyi.com/api/admin/'
Vue.prototype.$FilesImg = 'https://www.nienyi.com/Files/img/'
Vue.prototype.$FilesVideo = 'https://www.nienyi.com/Files/video/'
Vue.prototype.$httpen = 'https://www.nienyi.com/api/FrontendEn/'
Vue.prototype.$httpcn = 'https://www.nienyi.com/api/FrontendCn/'
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
