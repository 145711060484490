<template>
    <div class="capability">
        <section data-overlay="6" class="cover imagebg text-left space--sm">
            <div class="background-image-holder"
            :style="`background: url(${$FilesImg}${Capability_data.Imgsrc}); opacity: 1;`"><img
                    alt="Manufacturing Solutions &amp; Services - Flexible Productino &amp; Design - SINBON"
                    src="/img/banner-capability.jpg"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="Capability_data.Title1"></h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/cn/" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li>{{this.$t('Pathyem.nenglieyufw')}}</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-12 col-lg-9">
                        <h2 class="h3" v-html="Capability_data.Text1"></h2>
                    </div>
                    <div class="col-12"><strong class="h5" v-html="Capability_data.Title2"></strong>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 col-md-6 p-0" v-for="(item,index) in Capability_data.Capability_list1" :key="index" v-html="item.Text"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section data-overlay="6" class="cover imagebg text-left space--md">
            <div class="background-image-holder"
                :style="`background: url(${$FilesImg}${Capability_data.Imgsrc1}); opacity: 1;`">
                <img alt="background" :src="`${$FilesImg}${Capability_data.Imgsrc1}`"></div>
        </section>
        <section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-12 col-lg-6">
                        <h2 class="h3" v-html="Capability_data.Text2"></h2>
                    </div>
                    <div class="col-12 col-lg-6">
                        <ul class="bullets">
                            <li v-for="(item,index) in Capability_data.Capability_list2" :key="index" v-html="item.Text"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg--secondary">
            <div class="container">
                <p class="boxed boxed--border" v-html="Capability_data.Title3"> </p>
                <ul class="accordion accordion-2 accordion--right mt-5 inited">
                    <li>
                        <el-collapse v-model="activeNames">
                            <el-collapse-item v-for="(item,index) in Capability_data.Capability_list3" :key="index" :title="item.Title" :name="index + 1 +''">
                                <div class="row">
                                    <p class="col-12" v-html="item.Text" v-if="item.Textvi"></p>
                                    <img alt="Image" v-if="item.Imgsrcvi" :src="`${$FilesImg}${item.Imgsrc}`" class="col-12 mt-3">
                                    <h4 class="col-12 h4 mt-5" v-html="item.Title2" v-if="item.Titlevi2"></h4>
                                    <p class="col-12" v-html="item.Text1" v-if="item.Textvi1"></p>
                                    <h4 class="col-12 h4 mt-5" v-html="item.Title3" v-if="item.Titlevi3"></h4>
                                    <p class="col-12 my-3" v-html="item.Text2" v-if="item.Textvi2"></p>
                                    <ul class="col-12 bullets row mx-0" v-if="item.Ulvi">
                                        <li class="col-12 col-md-6 p-0" v-for="(itemm,indexx) in item.Capability_list3_list" :key="indexx" v-html="itemm.Text"></li>
                                    </ul>
                                    <h4 class="col-12 h4 mt-5" v-html="item.Title4" v-if="item.Titlevi4"></h4>
                                    <p class="col-12 mt-3 my-3" v-html="item.Text3" v-if="item.Textvi3"></p>
                                    <p class="col-12 my-3" v-html="item.Text4" v-if="item.Textvi4"></p>
                                </div>
                        </el-collapse-item>
                        </el-collapse>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  data () {
    return {
      activeNames: ['1'],
      Capability_data: {
        Imgsrc: '',
        Title1: '',
        Text1: '',
        Imgsrc1: '',
        Title2: '',
        Text2: '',
        Title3: '',
        Capability_list1: [],
        Capability_list2: []
      }
    }
  },
  mounted () {
  },
  created () {
    this.Capability()
  },
  methods: {
    async Capability () {
      const { data: res } = await this.$http.get('Capability')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Capability_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
    footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
