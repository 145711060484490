<template>
    <div>
        <section>
            <div class="container">
                <h2 v-html="Student_data.H2text"></h2>
                <p v-html="Student_data.Ptext"></p>
            </div>
        </section>
        <section class="bg--secondary">
            <div v-for="(item,index) in Student_data.Student_list1" :key="index" class="container">
                <h4 v-html="item.Title"></h4>
                <div class="row">
                    <div v-for="(itemm,indexx) in item.Student_list1_list" :key="indexx" class="col-md-6">
                        <div class="feature  boxed boxed--border p-4">
                            <h5 class="h4 mb-0" v-html="itemm.Title"></h5>
                            <p v-html="itemm.Text"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!---->
        <section class="text-center">
            <div v-for="(item,index) in Student_data.Student_list2" :key="index" class="container text-left">
                <div class="col mb-5">
                    <h4 v-html="item.Title"></h4>
                </div>
                <el-carousel  :interval="5000" arrow="never">
                    <el-carousel-item style="text-align: center;" v-for="(itemm,indexx) in item.Student_list2_list" :key="indexx">
                        <img alt="itemm.Imgurl" :src="`${$FilesImg}${itemm.Imgurl}`" class="border--round">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Student_data: {
        H2text: '',
        Ptext: '',
        Student_list1: [],
        Student_list2: []
      }
    }
  },
  created () {
    this.Student()
  },
  mounted () {

  },
  methods: {
    async Student () {
      const { data: res } = await this.$http.get('Student')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Student_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
