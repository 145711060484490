<template>
<el-container>
  <el-aside class="menaside"  width="287px">
          <div class="aside_zuo">
            <div class="asidelogo">
              <img @click="imghome" :src="`${$FilesImg}/nienyilogo.png`" alt="年益" width="200px" height="100px">
            </div>
            <hr class="header__hr">
            <div class="cdtree admintree">
            <el-tree
              :data="data"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick">
            </el-tree>
          </div>
          </div>
        </el-aside>
  <el-container>
    <el-header  style="z-index: 0;">
      </el-header>
    <el-main>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-html="menustext"></el-breadcrumb-item>
      </el-breadcrumb>
      <router-view></router-view>
        <footer class="space--sm footer-2">
        <div class="container">
          <div class="text-center">
            <h3 style="font-family: cursive; font-size: 30px;" v-html="$t('Main.h3text1')"></h3>
          </div>
          <div class="d-flex justify-content-center flex-wrap flex-sm-nowrap mt-3 mt-sm-5">
            <div class="pr-2 pl-2"><a href="/cn/terms"
                class="type--fine-print" v-html="$t('Main.atext')"></a><a href="/#/privacy"
                class="type--fine-print" v-html="$t('Main.atext1')"></a><a href="/#/Contact"
                target="_blank" class="type--fine-print" v-html="$t('Main.atext2')"></a></div>
            <div class="pr-2 pl-2">
              <span class="type--fine-print">
                备案号: <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">
                  <span style="color: #FF7F00;">苏ICP备20008874号</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </el-main>
  </el-container>
</el-container>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      aside: false,
      defaultProps: {
        children: 'ChildList',
        label: 'Menus'
      },
      data: [],
      datatoo: [],
      options: [{
        value: 'cn',
        label: '简体中文'
      }, {
        value: 'en',
        label: 'English'
      }],
      value: localStorage.getItem('locale') || 'cn',
      menustext: ''
    }
  },
  created () {
    this.menus()
  },
  mounted () {},
  methods: {
    imghome () {
      if (this.$route.path === '/NienyiMain') return
      this.$router.push('/NienyiMain')
    },
    handleNodeClick (data) {
      if (data.Path === this.$route.path) return
      // if (data.ChildList.length !== 0) return
      this.menustext = data.Menus
      this.$router.push(data.Path)
    },
    async menus () {
      const { data: res } = await this.$axios.get(`${this.$admin}Menus`)
      if (res.status !== 200) return this.$message.success(this.$t('State.Eerr'))
      this.data = res.response
    },
    list3cilck () {
      if (this.$route.path === '/Contact') return
      this.$router.push('/Contact')
    },
    languageXZ () {
      this.$i18n.locale = this.value
      localStorage.setItem('locale', this.value)
      this.$router.go(0)
    }
  }
}
</script>
<style lang="less" scoped>
.el-container{
  width: 100%;
  height: 100%;
  overflow-x: visible;
}
.el-header{
  text-align:center;
  overflow:hidden;
  width: 100%;
  background: #0b3574;
  z-index: 10;
}
.el-aside{
  width: 100%;
  height: 100%;
  background-color:#00558c;
}
.el-main{
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #FFFF;
  padding: 0 !important;
}
.elfooter{
  width: 100%;
  height: 60px;
  background-color: blueviolet;
}
.imglogo{
  padding-top: 4px;
  margin: 0 auto;
  //transform: translate(50%,0);//根据这个div的本身长宽高来唯一，前面是左右，后面是上下
}
.asidelogo{
  text-align:center;
}
.header__hr{
  opacity: .1;
}
.aside_zuo{
  width: 233px;
  margin: 0 auto;
}
.stack-menu{
  margin-top: 14px !important;
  width: 25px !important;
  height: 3px !important;
  border-top: 3px solid #ffffff !important;
  border-radius: 0% !important;
  border-bottom: 3px solid #ffffff !important;
  background-color: #ffffff !important;
  padding: 5px 0 !important;
  background-clip:content-box !important;
  box-sizing: content-box!important;
}
@media only screen and (max-width: 992px){
.menaside {
    display: none!important;
  }
}
@media only screen and (min-width: 992px){
.minaside {
    display: none!important;
  }
}
.cdtree{
  margin: 0 auto;
}
.el-tree {
  color: #ffffff94;
  color:rgba(255, 255, 255, 0.58);
  padding-top: 20px;
}
li{
  margin-left: 40px;
  list-style-type:none;
  line-height: 30px;
  color: #ffffff94;
  color:rgba(255, 255, 255, 0.58);
  cursor: default;
}
li:hover {
  color: #ffffff;
}
.imgheder{
  padding-top: 3px;
}
.asidelogo{
  margin-top: 20px;
}
.el-header{
  background-color: #00558c;
  height: 60px;
}
.el-breadcrumb{
  margin: 0 auto;
  width: 95%;
  padding-top: 2.57142857em;
  padding-bottom: 2.57142857em;
}
</style>
