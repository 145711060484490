<template>
  <div>
      <el-card class="box-card">
      <span class="titletext">中英文标题</span>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文标题</span>
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="Student_dataCn" label-width="80px">
              <el-form-item label="配图文字">
                <el-input type="textarea" :rows="3"  v-model="Student_dataCn.H2text"></el-input>
              </el-form-item>
              <el-form-item label="配图文字">
                <el-input type="textarea" :rows="5"  v-model="Student_dataCn.Ptext"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Studentuppcn(Student_dataCn)" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文标题</span>
          <div class="grid-content bg-purple-light">
            <el-form ref="form" :model="Student_dataEn" label-width="80px">
              <el-form-item label="配图文字">
                <el-input type="textarea" :rows="3"  v-model="Student_dataEn.H2text"></el-input>
              </el-form-item>
              <el-form-item label="配图文字">
                <el-input type="textarea" :rows="5"  v-model="Student_dataEn.Ptext"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Studentuppen(Student_dataEn)" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">招聘历程</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文历程</span>
          <div class="grid-content bg-purple">
            <el-table :data="Student_dataCn.Student_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="年度招聘标题" align="center"></el-table-column>
            <el-table-column align="center">
               <template slot="header">
                  <el-button type="primary" @click="list1_addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
              <template slot-scope="scope">
               <el-button type="primary" @click="list1uppcn(scope.row)" size="small">修改</el-button>
                <el-button type="warning" @click="Student_list1cn(scope.row.Id)" size="small">编辑</el-button>
                <el-button type="danger" @click="Student_list1_delcn(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文历程</span>
          <div class="grid-content bg-purple-light">
            <el-table :data="Student_dataEn.Student_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="年度招聘标题" align="center"></el-table-column>
            <el-table-column align="center">
               <template slot="header">
                  <el-button type="primary" @click="list1_adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="list1uppen(scope.row)" size="small">修改</el-button>
                <el-button type="warning" @click="Student_list1en(scope.row.Id)" size="small">编辑</el-button>
                <el-button type="danger" @click="Student_list1_delen(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文招聘添加" :modal-append-to-body='false' :visible.sync="list1_addcn" width="500px">
        <el-form :model="list1add">
          <el-form-item label="标题">
            <el-input v-model="list1add.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_addcn = false">取 消</el-button>
          <el-button type="primary" @click="Student_list1_addcn(list1add)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文招聘添加" :modal-append-to-body='false' :visible.sync="list1_adden" width="500px">
        <el-form :model="list1add">
          <el-form-item label="标题">
            <el-input v-model="list1add.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_adden = false">取 消</el-button>
          <el-button type="primary" @click="Student_list1_adden(list1add)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文招聘修改" :modal-append-to-body='false' :visible.sync="list1_uppcn" width="500px">
        <el-form :model="list1upp">
          <el-form-item label="标题">
            <el-input v-model="list1upp.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_uppcn = false">取 消</el-button>
          <el-button type="primary" @click="Student_list1_uppcn(list1upp)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文招聘修改" :modal-append-to-body='false' :visible.sync="list1_uppen" width="500px">
        <el-form :model="list1upp">
          <el-form-item label="标题">
            <el-input v-model="list1upp.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_uppen = false">取 消</el-button>
          <el-button type="primary" @click="Student_list1_uppen(list1upp)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文详情" :modal-append-to-body='false' :visible.sync="list_listxqcn" >
        <el-table :data="list1_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="时间" align="center"></el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list1_addcnxq = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list1uppcnxq(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Student_list_listdelcn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="英文详情" :modal-append-to-body='false' :visible.sync="list_listxqen" >
        <el-table :data="list1_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="时间" align="center"></el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list1_addenxq = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list1uppenxq(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Student_list_listdelen(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
      <el-dialog title="中文详情添加" :modal-append-to-body='false' :visible.sync="list1_addcnxq" width="500px">
        <el-form :model="list1addxq">
          <el-form-item label="时间">
            <el-input v-model="list1addxq.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input v-model="list1addxq.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_addcnxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list_listaddcn(list1addxq)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文详情添加" :modal-append-to-body='false' :visible.sync="list1_addenxq" width="500px">
        <el-form :model="list1addxq">
          <el-form-item label="时间">
            <el-input v-model="list1addxq.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input v-model="list1addxq.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_addenxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list_listadden(list1addxq)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文详情修改" :modal-append-to-body='false' :visible.sync="list1_uppcnxq" width="500px">
        <el-form :model="list1uppxq">
          <el-form-item label="时间">
            <el-input v-model="list1uppxq.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input v-model="list1uppxq.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_uppcnxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list_listuppcn(list1uppxq)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文详情修改" :modal-append-to-body='false' :visible.sync="list1_uppenxq" width="500px">
        <el-form :model="list1uppxq">
          <el-form-item label="时间">
            <el-input v-model="list1uppxq.Title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input v-model="list1uppxq.Text" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list1_uppenxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list_listuppen(list1uppxq)">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">滚动图</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文滚动图</span>
          <div class="grid-content bg-purple">
            <el-table :data="Student_dataCn.Student_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="列表" align="center"></el-table-column>
            <el-table-column align="center">
               <template slot="header">
                  <el-button type="primary" @click="list2_addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
              <template slot-scope="scope">
               <el-button type="primary" @click="list2uppcn(scope.row)" size="small">修改</el-button>
                <el-button type="warning" @click="Student_list2cn(scope.row.Id)" size="small">编辑</el-button>
                <el-button type="danger" @click="Student_list2_delcn(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文滚动图</span>
          <div class="grid-content bg-purple-light">
            <el-table :data="Student_dataEn.Student_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="列表" align="center"></el-table-column>
            <el-table-column align="center">
               <template slot="header">
                  <el-button type="primary" @click="list2_adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="list2uppen(scope.row)" size="small">修改</el-button>
                <el-button type="warning" @click="Student_list2en(scope.row.Id)" size="small">编辑</el-button>
                <el-button type="danger" @click="Student_list2_delen(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文滚动图添加" :modal-append-to-body='false' :visible.sync="list2_addcn" width="500px">
        <el-form :model="list2add">
          <el-form-item label="标题">
            <el-input v-model="list2add.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_addcn = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_addcn(list2add)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文滚动图添加" :modal-append-to-body='false' :visible.sync="list2_adden" width="500px">
        <el-form :model="list2add">
          <el-form-item label="标题">
            <el-input v-model="list2add.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_adden = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_adden(list2add)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文滚动图修改" :modal-append-to-body='false' :visible.sync="list2_uppcn" width="500px">
        <el-form :model="list2upp">
          <el-form-item label="标题">
            <el-input v-model="list2upp.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_uppcn = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_uppcn(list2upp)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文滚动图修改" :modal-append-to-body='false' :visible.sync="list2_uppen" width="500px">
        <el-form :model="list2upp">
          <el-form-item label="标题">
            <el-input v-model="list2upp.Title" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_uppen = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_uppen(list2upp)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文滚动图详情" :modal-append-to-body='false' :visible.sync="list2_listxqcn" >
        <el-table :data="list2_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Imgurl" label="图片名" align="center"></el-table-column>
          <el-table-column  label="图片" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgurl}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list2_addcnxq = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list2uppimgcn(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Student_list2_listdelcn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="英文滚动图详情" :modal-append-to-body='false' :visible.sync="list2_listxqen" >
        <el-table :data="list2_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Imgurl" label="图片名" align="center"></el-table-column>
          <el-table-column  label="图片" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgurl}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list2_addenxq = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list2uppimgen(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Student_list2_listdelen(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="中文滚动图详情添加" :modal-append-to-body='false' :visible.sync="list2_addcnxq" width="500px">
        <el-form>
          <el-form-item label="上传图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="listimgfileref" action="" drag :limit="1"
              :http-request="listimgfileref" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_addcnxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_listaddcn()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文滚动图详情添加" :modal-append-to-body='false' :visible.sync="list2_addenxq" width="500px">
        <el-form>
          <el-form-item label="图片">
            <el-input v-model="list2imgsrc" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_addenxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_listadden()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="中文滚动图详情修改" :modal-append-to-body='false' :visible.sync="list2_uppcnxq" width="500px">
        <el-form>
          <el-form-item label="上传图片">
            <el-upload class="upload-demo" style="text-align: center;" ref="listxgimgfileref" action="" drag :limit="1"
              :http-request="listxgimgfileref" :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_uppcnxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_listuppcn(list2_listupp)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="英文滚动图详情修改" :modal-append-to-body='false' :visible.sync="list2_uppenxq" width="500px">
        <el-form>
          <el-form-item label="图片">
            <el-input v-model="list2_listupp.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="list2_uppenxq = false">取 消</el-button>
          <el-button type="primary" @click="Student_list2_listuppen(list2_listupp)">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Student_dataCn: {
        H2text: '',
        Ptext: '',
        Student_list1: [],
        Student_list2: []
      },
      Student_dataEn: {
        H2text: '',
        Ptext: '',
        Student_list1: [],
        Student_list2: []
      },
      list1_addcn: false,
      list1_adden: false,
      list1_addcnxq: false,
      list1_addenxq: false,
      list1_uppcnxq: false,
      list1_uppenxq: false,
      list1add: {
        Id: 0,
        Title: ''
      },
      list1addxq: {
        Id: 0,
        Cid: 0,
        Title: '',
        Text: ''
      },
      list1upp: {
        Id: 0,
        Title: ''
      },
      list2upp: {
        Id: 0,
        Title: ''
      },
      list1uppxq: {
        Id: 0,
        Cid: 0,
        Title: '',
        Text: ''
      },
      list1_uppcn: false,
      list1_uppen: false,
      list2_uppcn: false,
      list2_uppen: false,
      list1_Id: 0,
      list2_Id: 0,
      list_listxqcn: false,
      list_listxqen: false,
      list1_list: [],
      list2add: {
        Id: 0,
        Title: ''
      },
      list2_addcn: false,
      list2_adden: false,
      list2_list: [],
      list2_listxqcn: false,
      list2_listxqen: false,
      list2_addcnxq: false,
      list2_addenxq: false,
      listimgfile: '',
      list2imgsrc: '',
      listimgfile1: '',
      list2imgsrc1: '',
      list2_uppcnxq: false,
      list2_uppenxq: false,
      list2_listupp: {
        Id: 0,
        Cid: 0,
        Imgsrc: ''
      }
    }
  },
  created () {
    this.StudentCn()
    this.StudentEn()
  },
  mounted () {

  },
  methods: {
    async StudentCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Student`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Student_dataCn = res.response
    },
    async StudentEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Student`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Student_dataEn = res.response
    },
    async Studentuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Studentuppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.StudentCn()
    },
    async Studentuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Studentuppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.StudentEn()
    },
    async Student_list1_addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1_addcn`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.StudentCn()
      this.list1_addcn = false
    },
    async Student_list1_adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1_adden`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.StudentEn()
      this.list1_adden = false
    },
    list1uppcn (row) {
      this.list1upp = row
      this.list1_uppcn = true
    },
    list1uppen (row) {
      this.list1upp = row
      this.list1_uppen = true
    },
    async Student_list1_uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1_uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.StudentCn()
      this.list1_uppcn = false
    },
    async Student_list1_uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1_uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.StudentEn()
      this.list1_uppen = false
    },
    async Student_list1_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1_delcn`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.StudentCn()
    },
    async Student_list1_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1_delen`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.StudentEn()
    },
    async Student_list1cn (row) {
      this.list1_Id = row
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1cn?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list1_list = res.response
      this.list_listxqcn = true
    },
    async Student_list1en (row) {
      this.list1_Id = row
      const { data: res } = await this.$http.post(`${this.$admin}Student_list1en?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list1_list = res.response
      this.list_listxqen = true
    },
    async Student_list_listaddcn (row) {
      row.Cid = this.list1_Id
      const { data: res } = await this.$http.post(`${this.$admin}Student_list_listaddcn`, row)
      if (res.status !== 200) return alert('获取失败')
      this.Student_list1cn(this.list1_Id)
      this.list1_addcnxq = false
    },
    async Student_list_listadden (row) {
      row.Cid = this.list1_Id
      const { data: res } = await this.$http.post(`${this.$admin}Student_list_listadden`, row)
      if (res.status !== 200) return alert('获取失败')
      this.Student_list1en(this.list1_Id)
      this.list1_addenxq = false
    },
    list1uppcnxq (row) {
      this.list1uppxq = row
      this.list1_uppcnxq = true
    },
    list1uppenxq (row) {
      this.list1uppxq = row
      this.list1_uppenxq = true
    },
    async Student_list_listuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list_listuppcn`, row)
      if (res.status !== 200) return alert('获取失败')
      this.list1_uppcnxq = false
      this.Student_list1cn(this.list1_Id)
    },
    async Student_list_listuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list_listuppen`, row)
      if (res.status !== 200) return alert('获取失败')
      this.list1_uppenxq = false
      this.Student_list1en(this.list1_Id)
    },
    async Student_list_listdelcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list_listdelcn`, row)
      if (res.status !== 200) return alert('获取失败')
      this.Student_list1cn(this.list1_Id)
    },
    async Student_list_listdelen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list_listdelen`, row)
      if (res.status !== 200) return alert('获取失败')
      this.Student_list1en(this.list1_Id)
    },
    async Student_list2_addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_addcn`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.StudentCn()
      this.list2_addcn = false
    },
    async Student_list2_adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_adden`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.StudentEn()
      this.list2_adden = false
    },
    list2uppcn (row) {
      this.list2upp = row
      this.list2_uppcn = true
    },
    list2uppen (row) {
      this.list2upp = row
      this.list2_uppen = true
    },
    async Student_list2_uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.StudentCn()
      this.list2_uppcn = false
    },
    async Student_list2_uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.StudentEn()
      this.list2_uppen = false
    },
    async Student_list2_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_delcn`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.StudentCn()
    },
    async Student_list2_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_delen`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.StudentEn()
    },
    async Student_list2cn (row) {
      this.list2_Id = row
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2cn?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list2_list = res.response
      this.list2_listxqcn = true
    },
    async Student_list2en (row) {
      this.list2_Id = row
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2en?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list2_list = res.response
      this.list2_listxqen = true
    },
    listimgfileref (param) {
      this.listimgfile = param.file
    },
    async Student_list2_listaddcn () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.listimgfileref.submit()
      var File = new FormData()
      File.append('Cid', this.list2_Id)
      File.append('Img1', this.listimgfile)
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_listaddcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.$refs.listimgfileref.clearFiles()
      this.Student_list2cn(this.list2_Id)
      this.list2_addcnxq = false
    },
    async Student_list2_listadden () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Cid', this.list2_Id)
      File.append('Imgurl', this.list2imgsrc)
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_listadden`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.Student_list2en(this.list2_Id)
      this.list2_addenxq = false
    },
    async Student_list2_listdelcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_listdelcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.Student_list2cn(this.list2_Id)
    },
    async Student_list2_listdelen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_listdelen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.Student_list2en(this.list2_Id)
    },
    list2uppimgcn (row) {
      this.list2_listupp = row
      this.list2_uppcnxq = true
    },
    list2uppimgen (row) {
      this.list2_listupp = row
      this.list2_uppenxq = true
    },
    listxgimgfileref (param) {
      this.listimgfile1 = param.file
    },
    async Student_list2_listuppcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.listxgimgfileref.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Img1', this.listimgfile1)
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_listuppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.$refs.listxgimgfileref.clearFiles()
      this.Student_list2cn(this.list2_Id)
      this.list2_uppcnxq = false
    },
    async Student_list2_listuppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgurl', row.Imgsrc)
      const { data: res } = await this.$http.post(`${this.$admin}Student_list2_listuppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.Student_list2en(this.list2_Id)
      this.list2_uppenxq = false
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
