<template>
    <div>
        <!---->
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/#/" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li><a href="/#/Solution" class="">{{this.$t('Pathyem.jjfa')}}</a></li>
                    <li v-html="Connectivity_data.Solution_list1.Title"></li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-7">
                        <h4 class="h5 mb-3" v-html="Connectivity_data.Solution_list1.Text"></h4>
                        <h1 class="h2 mb-5" v-html="Connectivity_data.Solution_list1.Title"> </h1>
                        <p class="sub-lead" v-html="Connectivity_data.P1text"></p>
                    </div>
                </div>
            </div>
        </section>
        <section data-overlay="5" v-if="Connectivity_data.Zhongs" class="cover imagebg text-left"
            :style="`background-image: url(${$FilesImg}${Connectivity_data.Imgsrc});`">
            <div class="container">
                <div class="row">
                    <div class="col-12 title-bg">
                        <p class="sub-lead" v-html="Connectivity_data.Title"></p>
                        <h2 class="h3">
                            <p v-html="Connectivity_data.H2text"></p>
                        </h2>
                    </div>
                </div>
            </div>
        </section>
        <div>
            <div class="_editor-output" id="editor_description_lang_cn" v-if="Connectivity_data.Zhong">
                <div class="_editor-container">
                    <section v-for="(item,index) in Connectivity_data.Connectivity_list1" :key="index"
                        :class="`_editor-clip editor-cliptype__img__title-param__gray imageblock ${item.Switchable} bg--secondary`">
                        <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                            <div class="background-image-holder" :style="`background-image: url(${$FilesImg}${item.Imgsrc});opacity: 1;`"></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5 col-md-7">
                                    <h2 class="h3 _editor-field__textarea" v-html="item.Title"></h2>
                                    <p class="lead _editor-field__textarea" v-html="item.Text">
                                        </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <section class="cover imagebg text-left bg--primary-4" :style="`background: ${$FilesImg}${Connectivity_data.Bootombackgroundcolor};`" v-if="Connectivity_data.Bootom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h3" v-html="Connectivity_data.P2text"> </p>
                    </div>
                </div>
            </div>
        </section>
        <!---->
        <!---->
        <!---->
        <div>
            <Foorm></Foorm>
        </div>
    </div>
</template>
<script>
import Foorm from '../components/Foorm'
export default {
  data () {
    return {
      Connectivity_data: {
        P1text: '',
        P2text: '',
        Imgsrc: '',
        Title: '',
        H2text: '',
        Connectivity_list1: {
          Switchable: 'switchable',
          Imgsrc: '',
          Title: '',
          Text: ''
        },
        Solution_list1: [],
        Bootom: true,
        Zhong: true,
        Zhongs: true,
        Bootombackgroundcolor: '#67c23a'
      }

    }
  },
  created () {
    this.Connectivity()
  },
  components: {
    Foorm
  },
  mounted () {
  },
  methods: {
    async Connectivity () {
      const { data: res } = await this.$http.get(`Connectivity?Id=${this.$route.params.id}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Connectivity_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
