<template>
<div>
    <el-card class="box-card">
      <span class="titletext">背景图</span>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                  <div>
                    <span class="demonstration">原背景图</span>
                    <div style="text-align: center;">
                        <el-image style="background-color: #00558c;"  :src="`${$FilesImg}${Vision_dataCn.Backgroundimg}`"> <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i></div></el-image>
                    </div>
                  </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <span class="demonstration">修改背景图</span>
                <el-upload class="upload-demo" style="text-align: center;" ref="logofileref" action="" drag :limit="1"
                  :http-request="logofileref" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <div style="text-align:center">
            <el-button type="primary" @click="logoup">上传</el-button>
          </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
     <el-card class="box-card">
      <span class="titletext">中英文标题</span>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文标题</span>
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="Vision_dataCn" label-width="80px">
              <el-form-item label="大标题">
                <el-input v-model="Vision_dataCn.H2text"></el-input>
              </el-form-item>
              <el-form-item label="大标题">
                <el-input v-model="Vision_dataCn.H3text"></el-input>
              </el-form-item>
              <el-form-item label="配图文字">
                <el-input  v-model="Vision_dataCn.H4text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="Vision_dataCn.H5text"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Visionuppcn(Vision_dataCn)" >上传修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文标题</span>
          <div class="grid-content bg-purple-light">
            <el-form ref="form" :model="Vision_dataEn" label-width="80px">
              <el-form-item label="大标题">
                <el-input v-model="Vision_dataEn.H2text"></el-input>
              </el-form-item>
              <el-form-item label="大标题">
                <el-input v-model="Vision_dataEn.H3text"></el-input>
              </el-form-item>
              <el-form-item label="配图文字">
                <el-input  v-model="Vision_dataEn.H4text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="Vision_dataEn.H5text"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Visionuppen(Vision_dataEn)" >上传修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">列表一</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文列表</span>
          <div class="grid-content bg-purple">
            <el-table :data="Vision_dataCn.Vision_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
             <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="list1methoscn(scope.row)" size="small">修改</el-button>
                  <el-button type="warning" @click="Vision_list1_listcn(scope.row.Id)" size="small">编辑</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文列表</span>
          <div class="grid-content bg-purple-light">
            <el-table :data="Vision_dataEn.Vision_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="list1methosen(scope.row)" size="small">修改</el-button>
                  <el-button type="warning" @click="Vision_list1_listen(scope.row.Id)" size="small">编辑</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文列表一新增" :modal-append-to-body='false' :visible.sync="list1visiblecn" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1visiblecn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1_addcn(list1)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文列表一新增" :modal-append-to-body='false' :visible.sync="list1visibleen" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1visibleen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1_adden(list1)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="中文列表一修改" :modal-append-to-body='false' :visible.sync="list1visibleupcn" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1visibleupcn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1_uppen(list1)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文列表一修改" :modal-append-to-body='false' :visible.sync="list1visibleupen" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1visibleupen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1_uppen(list1)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="中文列表详情" :modal-append-to-body='false' :visible.sync="list1_listxqcn" width="500px">
        <el-table :data="list1_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list1_listvisiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list_listupcn(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Vision_list1_listdelcn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="英文列表详情" :modal-append-to-body='false' :visible.sync="list1_listxqen" width="500px">
        <el-table :data="list1_list" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list1_listvisibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list_listupen(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="Vision_list1_listdelen(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="中文详情列表一新增" :modal-append-to-body='false' :visible.sync="list1_listvisiblecn" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1_listvisiblecn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1__listaddcn(list1)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文详情列表一新增" :modal-append-to-body='false' :visible.sync="list1_listvisibleen" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1_listvisibleen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1__listadden(list1)">确 定</el-button>
       </div>
     </el-dialog>
          <el-dialog title="中文详情列表一修改" :modal-append-to-body='false' :visible.sync="list1_listvisibleupcn" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1_listvisibleupcn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1_listuppcn(list1)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文详情列表一修改" :modal-append-to-body='false' :visible.sync="list1_listvisibleupen" width="500px">
        <el-form :model="list1">
           <el-form-item label="标题">
             <el-input  v-model="list1.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list1_listvisibleupen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list1_listuppen(list1)">确 定</el-button>
       </div>
     </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">列表二</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文列表</span>
          <div class="grid-content bg-purple">
            <el-table :data="Vision_dataCn.Vision_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Span" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="标题" align="center"></el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column fixed="right" align="center">
                <template slot="header">
                  <el-button type="primary" @click="list2visiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="list2methoscn(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="Vision_list2_delcn(scope.row)" size="small">删除</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文列表</span>
          <div class="grid-content bg-purple-light">
            <el-table :data="Vision_dataEn.Vision_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Span" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="标题" align="center"></el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column fixed="right" align="center">
                <template slot="header">
                  <el-button type="primary" @click="list2visibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="list2methosen(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="Vision_list2_delen(scope.row)" size="small">删除</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文列表二新增" :modal-append-to-body='false' :visible.sync="list2visiblecn" width="500px">
        <el-form :model="list2">
          <el-form-item label="标题">
             <el-input  v-model="list2.Span" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="标题">
             <el-input  v-model="list2.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input  v-model="list2.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list2visiblecn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list2__addcn(list2)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文列表二新增" :modal-append-to-body='false' :visible.sync="list2visibleen" width="500px">
        <el-form :model="list2">
           <el-form-item label="标题">
             <el-input  v-model="list2.Span" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="标题">
             <el-input  v-model="list2.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input  v-model="list2.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list2visibleen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list2__adden(list2)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="中文列表二修改" :modal-append-to-body='false' :visible.sync="list2visibleuppcn" width="500px">
        <el-form :model="list2">
          <el-form-item label="标题">
             <el-input  v-model="list2.Span" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="标题">
             <el-input  v-model="list2.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input  v-model="list2.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list2visibleuppcn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list2_uppcn(list2)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文列表二修改" :modal-append-to-body='false' :visible.sync="list2visibleuppen" width="500px">
        <el-form :model="list2">
           <el-form-item label="标题">
             <el-input  v-model="list2.Span" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="标题">
             <el-input  v-model="list2.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input  v-model="list2.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list2visibleuppen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list2_uppen(list2)">确 定</el-button>
       </div>
     </el-dialog>
    </el-card>
    <el-card class="box-card">
      <span class="titletext">列表三</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文列表</span>
          <div class="grid-content bg-purple">
            <el-table :data="Vision_dataCn.Vision_list3" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Text" label="标题" align="center"></el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column fixed="right" align="center">
                <template slot="header">
                  <el-button type="primary" @click="list3visiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="list3methoscn(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="Vision_list3_delcn(scope.row)" size="small">删除</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文列表</span>
          <div class="grid-content bg-purple-light">
            <el-table :data="Vision_dataEn.Vision_list3" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Text" label="标题" align="center"></el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <el-button type="primary" @click="list3visibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="list3methosen(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="Vision_list3_delen(scope.row)" size="small">删除</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
      </el-row>
            <el-dialog title="中文列表三新增" :modal-append-to-body='false' :visible.sync="list3visiblecn" width="500px">
        <el-form :model="list3">
           <el-form-item label="标题">
             <el-input  v-model="list3.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input  v-model="list3.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3visiblecn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list3__addcn(list3)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文列表三新增" :modal-append-to-body='false' :visible.sync="list3visibleen" width="500px">
        <el-form :model="list3">
           <el-form-item label="标题">
             <el-input  v-model="list3.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input  v-model="list3.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3visibleen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list3__adden(list3)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="中文列表三修改" :modal-append-to-body='false' :visible.sync="list3visibleuppcn" width="500px">
          <el-form :model="list3">
           <el-form-item label="标题">
             <el-input  v-model="list3.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input type="textarea" :rows="3"  v-model="list3.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3visibleuppcn = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list3_uppcn(list3)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文列表三修改" :modal-append-to-body='false' :visible.sync="list3visibleuppen" width="500px">
        <el-form :model="list3">
           <el-form-item label="标题">
             <el-input  v-model="list3.Title" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="文本">
             <el-input type="textarea" :rows="3"  v-model="list3.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3visibleuppen = false">取 消</el-button>
         <el-button type="primary" @click="Vision_list3_uppen(list3)">确 定</el-button>
       </div>
     </el-dialog>
    </el-card>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Vision_dataCn: {
        H2text: '',
        H3text: '',
        H4text: '',
        Backgroundimg: '',
        H5text: '',
        Vision_list1: [],
        Vision_list2: [],
        Vision_list3: []
      },
      Vision_dataEn: {
        H2text: '',
        H3text: '',
        H4text: '',
        Backgroundimg: '',
        H5text: '',
        Vision_list1: [],
        Vision_list2: [],
        Vision_list3: []
      },
      logofile: '',
      dialog: '',
      FormVisiblecn: false,
      FormVisibleen: false,
      list1visiblecn: false,
      list1visibleen: false,
      list2visiblecn: false,
      list2visibleen: false,
      list3visiblecn: false,
      list3visibleen: false,
      list1: {
        Id: 0,
        Cid: 0,
        Title: '',
        Text: ''
      },
      list1_list: {
        Id: 0,
        Cid: 0,
        Title: ''
      },
      list1visibleupcn: false,
      list1visibleupen: false,
      list1_listxqcn: false,
      list1_listxqen: false,
      list1_id: '',
      list1_listvisiblecn: false,
      list1_listvisibleen: false,
      list1_listvisibleupcn: false,
      list1_listvisibleupen: false,
      list2: {
        Id: 0,
        Title: '',
        Text: '',
        Span: ''
      },
      list3: {
        Id: 0,
        Title: '',
        Text: ''
      },
      list2visibleuppcn: false,
      list2visibleuppen: false,
      list3visibleuppcn: false,
      list3visibleuppen: false
    }
  },
  created () {
    this.VisionCn()
    this.VisionEn()
  },
  mounted () {

  },
  methods: {
    async VisionCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Vision`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Vision_dataCn = res.response
    },
    async VisionEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Vision`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Vision_dataEn = res.response
    },
    logofileref (param) {
      this.logofile = param.file
    },
    async logoup () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.logofileref.submit()
      var File = new FormData()
      File.append('file', this.logofile)
      const { data: res } = await this.$http.post(`${this.$admin}Vision_logoup`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.logofile = ''
      this.$refs.logofileref.clearFiles()
      this.VisionCn()
    },
    async Visionuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Visionuppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
    },
    async Visionuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Visionuppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
    },
    list1methoscn (row) {
      this.list1 = row
      this.list1visibleupcn = true
    },
    list1methosen (row) {
      this.list1 = row
      this.list1visibleupen = true
    },
    async Vision_list1_addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_addcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
      this.list1visiblecn = false
    },
    async Vision_list1_adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_adden`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
      this.list1visibleen = false
    },
    async Vision_list1_uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
      this.list1visibleupcn = false
    },
    async Vision_list1_uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
      this.list1visibleupen = false
    },
    async Vision_list1_listcn (row) {
      this.list1_id = row
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_listcn?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list1_list = res.response
      this.list1_listxqcn = true
    },
    async Vision_list1_listen (row) {
      this.list1_id = row
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_listen?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list1_list = res.response
      this.list1_listxqen = true
    },
    async Vision_list1__listaddcn (row) {
      row.Cid = this.list1_id
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1__listaddcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.Vision_list1_listcn(this.list1_id)
      this.list1_listvisiblecn = false
    },
    async Vision_list1__listadden (row) {
      row.Cid = this.list1_id
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1__listadden`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.Vision_list1_listen(this.list1_id)
      this.list1_listvisibleen = false
    },
    list_listupcn (row) {
      this.list1 = row
      this.list1_listvisibleupcn = true
    },
    list_listupen (row) {
      this.list1 = row
      this.list1_listvisibleupen = true
    },
    async Vision_list1_listuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_listuppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list1_listvisibleupcn = false
      this.Vision_list1_listcn(this.list1_id)
    },
    async Vision_list1_listuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_listuppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list1_listvisibleupen = false
      this.Vision_list1_listen(this.list1_id)
    },
    async Vision_list1_listdelcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_listdelcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.Vision_list1_listcn(this.list1_id)
    },
    async Vision_list1_listdelen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_listdelen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.Vision_list1_listen(this.list1_id)
    },
    async Vision_list1_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_delcn`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
    },
    async Vision_list1_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list1_delen`, row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
    },
    async Vision_list2__addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list2__addcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
      this.list2visiblecn = false
    },
    async Vision_list2__adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list2__adden`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
      this.list2visibleen = false
    },
    list2methoscn (row) {
      this.list2 = row
      this.list2visibleuppcn = true
    },
    list2methosen (row) {
      this.list2 = row
      this.list2visibleuppen = true
    },
    async Vision_list2_uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list2_uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
      this.list2visibleuppcn = false
    },
    async Vision_list2_uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list2_uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
      this.list2visibleuppen = false
    },
    async Vision_list2_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list2_delcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
    },
    async Vision_list2_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list2_delen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
    },
    async Vision_list3__addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list3__addcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
      this.list3visiblecn = false
    },
    async Vision_list3__adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list3__adden`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
      this.list3visibleen = false
    },
    list3methoscn (row) {
      this.list3 = row
      this.list3visibleuppcn = true
    },
    list3methosen (row) {
      this.list3 = row
      this.list3visibleuppen = true
    },
    async Vision_list3_uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list3_uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
      this.list3visibleuppcn = false
    },
    async Vision_list3_uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list3_uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
      this.list3visibleuppen = false
    },
    async Vision_list3_delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list3_delcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionCn()
    },
    async Vision_list3_delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Vision_list3_delen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.VisionEn()
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
