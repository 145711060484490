<template>
    <div>
        <section>
            <div class="container">
                <div>
                    <h2 class="mb-5" v-html="Vision_data.H2text"></h2>
                </div>
                <div class="process-2 row">
                    <div v-for="(itme,index) in Vision_data.Vision_list1" :key="index" class="col-md-4">
                        <div class="process__item">
                            <h5 v-html="itme.Title"></h5>
                            <ul>
                                <li v-for="(itmee,indexx) in itme.Vision_list1_list" :key="indexx" v-html="itmee.Text"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg--secondary">
            <div class="container">
                <h2 class="mb-5" v-html="Vision_data.H3text"></h2>
                <div class="row">
                    <div v-for="(itme,index) in Vision_data.Vision_list2" :key="index" class="col-md-4">
                        <div class="feature  boxed boxed--lg boxed--border"><span v-html="itme.Span"></span>
                            <h3 class="mb-3" v-html="itme.Title"></h3>
                            <p v-html="itme.Text"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row">
                    <div data-overlay="3" class="col height-20 feature boxed text-left imagebg">
                        <div class="background-image-holder" :style="`background: url(${$FilesImg}${Vision_data.Backgroundimg}); opacity: 1;`">
                            <img alt="background" :src="`${$FilesImg}${Vision_data.Backgroundimg}`"></div>
                        <h3 class="pos-vertical-center" v-html="Vision_data.H4text"></h3>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <h4 v-html="Vision_data.H5text"></h4>
                <ul>
                    <li v-for="(itme,index) in Vision_data.Vision_list3" :key="index" class="mb-4">
                        <h5 class="mb-1" v-html="itme.Title"></h5>
                        <p v-html="itme.Text"></p>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Vision_data: {
        H2text: '',
        H3text: '',
        H4text: '',
        Backgroundimg: '',
        H5text: '',
        Vision_list1: [],
        Vision_list2: [],
        Vision_list3: []
      }
    }
  },
  created () {
    this.Vision()
  },
  mounted () {

  },
  methods: {
    async Vision () {
      const { data: res } = await this.$http.get('Vision')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Vision_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
