<template>
    <div>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="#/Home" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li><a href="#/Product" class="">{{this.$t('Pathyem.chanpin')}}</a></li>
                    <li><a :href="`#/Product/${Automotive_data.Meuns.Id}`" class="" v-html="Automotive_data.Meuns.Menus"></a></li>
                    <li v-html="Automotive_data.Product.Title"></li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-5 col-lg-7">
                        <div class="switchable__text">
                            <h1 v-html="Automotive_data.Product.Title"></h1>
                            <hr class="short">
                            <p class="lead" v-html="Automotive_data.Product.Yongtu"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pb-0">
            <div class="row justify-content-center align-items-start"><img alt=""
                    :data-src="`${$FilesImg}${Automotive_data.Product.Imgsrc_1}`"
                    class="w-100 h-auto" :src="`${$FilesImg}${Automotive_data.Product.Imgsrc_1}`"
                    lazy="loaded"></div>
        </section>
        <section class="bg--secondary product-detail__accordion">
            <div class="container">
                <div class="row justify-content-around">
                    <div class="col">
                        <div class="d-flex flex-wrap align-self-stretch">
                            <div v-for="(item,index) in Automotive_data.Product_list" :key="index" class="w-50 product-detail__accordion-item">
                                <div class="accordion__title"><span class="h5" v-html="item.Title"></span></div>
                                <div class="accordion__content">
                                    <p>
                                    <ul>
                                        <li v-for="(itemm,indexx) in item.Product_listdata" :key="indexx" v-html="itemm.Text"></li>
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-12 col-md-6">
                        <div class="w-100 pt-5">
                            <div data-notification-link="contact"
                                class="btn btn--primary block  type--uppercase pt-3 pb-3 mt-5"><span
                                    class="btn__text">{{this.$t('Pathyem.zixun')}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <hr class="m-0">
        </div>
        <div>
           <Foorm></Foorm>
        </div>
    </div>
</template>
<script>
import Foorm from '../components/Foorm'
export default {
  data () {
    return {
      CId: this.$route.params.cid,
      Automotive_data: {
        Meuns: [],
        Product: [],
        Product_list: []
      }
    }
  },
  updated () {
  },
  created () {
    this.Product_data()
  },
  components: {
    Foorm
  },
  mounted () {
  },
  methods: {
    async Product_data () {
      const { data: res } = await this.$http.get(`Product_data?Id=${this.CId}`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Automotive_data = res.response
    }
  },
  watch: {
    '$route' (to, from) {
      this.CId = this.$route.params.cid
      this.Product_data()
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
.w-50{
    padding-bottom: 20px;
}
</style>
