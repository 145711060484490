<template>
<div>
    <el-card class="box-card">
      <span class="titletext">Logo</span>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                  <div>
                    <span class="demonstration">原图标</span>
                    <div style="text-align: center;">
                        <el-image style="background-color: #00558c;"  :src="`${$FilesImg}/nienyilogo.png`"> <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i></div></el-image>
                    </div>
                  </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <span class="demonstration">修改图标</span>
                <el-upload class="upload-demo" style="text-align: center;" ref="logofileref" action="" drag :limit="1"
                  :http-request="logofileref" :auto-upload="false" list-type="picture">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <div style="text-align:center">
            <el-button type="primary" @click="logoup">上传</el-button>
          </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    <el-card class="box-card">
      <span class="titletext">中英文底部宣言</span>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文底部</span>
          <div class="grid-content bg-purple">
            <el-form ref="Foorm_dataCn" :model="Foorm_dataCn" label-width="100px">
              <el-form-item label="图片一名">
                <el-input v-model="Foorm_dataCn.Imgsrc1" disabled></el-input>
              </el-form-item>
              <el-form-item label="图片一">
                <el-image :src="`${$FilesImg}${Foorm_dataCn.Imgsrc1}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片一文案">
                <el-input v-model="Foorm_dataCn.Text1"></el-input>
              </el-form-item>
              <el-form-item label="图片一链接">
                <el-input  v-model="Foorm_dataCn.Href1"></el-input>
              </el-form-item>
              <el-form-item label="上传图片一">
                <el-upload class="upload-demo" style="text-align: center;" ref="img1" action="" drag :limit="1"
                  :http-request="img1" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="图片二名">
                <el-input v-model="Foorm_dataCn.Imgsrc2" disabled></el-input>
              </el-form-item>
              <el-form-item label="图片二">
                <el-image :src="`${$FilesImg}${Foorm_dataCn.Imgsrc2}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片二文案">
                <el-input v-model="Foorm_dataCn.Text2"></el-input>
              </el-form-item>
              <el-form-item label="图片二链接">
                <el-input  v-model="Foorm_dataCn.Href2"></el-input>
              </el-form-item>
              <el-form-item label="上传图片二">
                <el-upload class="upload-demo" style="text-align: center;" ref="img2" action="" drag :limit="1"
                  :http-request="img2" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="图片三名">
                <el-input v-model="Foorm_dataCn.Imgsrc3" disabled></el-input>
              </el-form-item>
              <el-form-item label="图片三">
                <el-image :src="`${$FilesImg}${Foorm_dataCn.Imgsrc3}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片三文案">
                <el-input v-model="Foorm_dataCn.Text3"></el-input>
              </el-form-item>
              <el-form-item label="图片三链接">
                <el-input  v-model="Foorm_dataCn.Href3"></el-input>
              </el-form-item>
              <el-form-item label="上传图片三">
                <el-upload class="upload-demo" style="text-align: center;" ref="img3" action="" drag :limit="1"
                  :http-request="img3" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Foorm_uppcn(Foorm_dataCn)" >上传修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文底部</span>
          <div class="grid-content bg-purple-light">
            <el-form ref="Foorm_dataEn" :model="Foorm_dataEn" label-width="100px">
              <el-form-item label="图片一名">
                <el-input v-model="Foorm_dataEn.Imgsrc1" ></el-input>
              </el-form-item>
              <el-form-item label="图片一">
                <el-image :src="`${$FilesImg}${Foorm_dataEn.Imgsrc1}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片一文案">
                <el-input v-model="Foorm_dataEn.Text1"></el-input>
              </el-form-item>
              <el-form-item label="图片一链接">
                <el-input  v-model="Foorm_dataEn.Href1"></el-input>
              </el-form-item>
              <el-form-item label="图片二名">
                <el-input v-model="Foorm_dataEn.Imgsrc2" ></el-input>
              </el-form-item>
              <el-form-item label="图片二">
                <el-image :src="`${$FilesImg}${Foorm_dataEn.Imgsrc2}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片二文案">
                <el-input v-model="Foorm_dataEn.Text2"></el-input>
              </el-form-item>
              <el-form-item label="图片二链接">
                <el-input  v-model="Foorm_dataEn.Href2"></el-input>
              </el-form-item>
              <el-form-item label="图片三名">
                <el-input v-model="Foorm_dataEn.Imgsrc3" ></el-input>
              </el-form-item>
              <el-form-item label="图片三">
                <el-image :src="`${$FilesImg}${Foorm_dataEn.Imgsrc3}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片三文案">
                <el-input v-model="Foorm_dataEn.Text3"></el-input>
              </el-form-item>
              <el-form-item label="图片三链接">
                <el-input  v-model="Foorm_dataEn.Href3"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Foorm_uppen(Foorm_dataEn)" >上传修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    </div>
</template>
<script>
export default {
  data () {
    return {
      Foorm_dataCn: {
        Href1: '',
        Href2: '',
        Href3: '',
        Id: 0,
        Imgsrc1: '',
        Imgsrc2: '',
        Imgsrc3: '',
        Text1: '',
        Text2: '',
        Text3: ''
      },
      Foorm_dataEn: {
        Href1: '',
        Href2: '',
        Href3: '',
        Id: 0,
        Imgsrc1: '',
        Imgsrc2: '',
        Imgsrc3: '',
        Text1: '',
        Text2: '',
        Text3: ''
      },
      logofile: '',
      imgfile1: '',
      imgfile2: '',
      imgfile3: ''
    }
  },
  created () {
    this.FoormCn()
    this.FoormEn()
  },
  mounted () {

  },
  methods: {
    async FoormCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Foorm`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Foorm_dataCn = res.response
    },
    async FoormEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Foorm`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Foorm_dataEn = res.response
    },
    logofileref (param) {
      this.logofile = param.file
    },
    async logoup () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.logofileref.submit()
      var File = new FormData()
      File.append('file', this.logofile)
      const { data: res } = await this.$http.post(`${this.$admin}AdminLogo`, File, config.headers)
      if (res.status === 230) return this.$message.success(res.msg)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功,Logo不会立即显示，需要Shift + F5刷新页面才会替换！')
      this.logofile = ''
      this.$refs.logofileref.clearFiles()
    },
    img1 (param) {
      this.imgfile1 = param.file
    },
    img2 (param) {
      this.imgfile2 = param.file
    },
    img3 (param) {
      this.imgfile3 = param.file
    },
    async Foorm_uppcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.img1.submit()
      this.$refs.img2.submit()
      this.$refs.img3.submit()
      var File = new FormData()
      File.append('Img1', this.imgfile1)
      File.append('Img2', this.imgfile2)
      File.append('Img3', this.imgfile3)
      File.append('Href1', row.Href1)
      File.append('Href2', row.Href2)
      File.append('Href3', row.Href3)
      File.append('Id', row.Id)
      File.append('Text1', row.Text1)
      File.append('Text2', row.Text2)
      File.append('Text3', row.Text3)
      const { data: res } = await this.$http.post(`${this.$admin}Foorm_uppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.imgfile1 = ''
      this.imgfile2 = ''
      this.imgfile3 = ''
      this.$refs.img1.clearFiles()
      this.$refs.img2.clearFiles()
      this.$refs.img3.clearFiles()
      this.FoormCn()
      this.$message.success('修改成功')
    },
    async Foorm_uppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Imgsrc1', row.Imgsrc1)
      File.append('Imgsrc2', row.Imgsrc2)
      File.append('Imgsrc3', row.Imgsrc3)
      File.append('Href1', row.Href1)
      File.append('Href2', row.Href2)
      File.append('Href3', row.Href3)
      File.append('Id', row.Id)
      File.append('Text1', row.Text1)
      File.append('Text2', row.Text2)
      File.append('Text3', row.Text3)
      const { data: res } = await this.$http.post(`${this.$admin}Foorm_uppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.FoormEn()
      this.$message.success('修改成功')
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
