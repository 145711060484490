<template>
    <div>
        <section data-overlay="6" class="cover imagebg text-left space--sm">
            <div class="background-image-holder"
            :style="`background: url(${$FilesImg}${Solution_data.Backgroundimg}); opacity: 1;`"><img alt="background"
                    :src="`${$FilesImg}${Solution_data.Backgroundimg}`"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="Solution_data.H1text"></h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-5 pb-3 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/#/Home" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li>{{this.$t('Pathyem.jjfa')}}</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <div>
                    <div class="masonry__container row masonry--active">
                        <div class="masonry__item col-lg-4 col-md-6" v-for="(item,index) in Solution_data.Solution_list1" :key="index">
                            <div class="project-thumb"><a
                                    :href="`/#/Connectivity/${item.Id}`"
                                    class=""><img :alt="`${item.Title}`"
                                        :src="`${$FilesImg}${item.Imgsrc}`"
                                        class="border--round">
                                    <h4 v-html="item.Title"> </h4><span v-html="item.Text"></span>
                                </a></div>
                        </div>
                    </div>
                </div>
            </div>
            <!---->
        </section>
        <div>
           <Foorm></Foorm>
        </div>
    </div>
</template>
<script>
import Foorm from '../components/Foorm'
export default {
  data () {
    return {
      Solution_data: {
        Backgroundimg: '',
        H1text: '',
        Solution_list1: []
      }

    }
  },
  mounted () {
    this.Solution()
  },
  components: {
    Foorm
  },
  methods: {
    async Solution () {
      const { data: res } = await this.$http.get('Solution')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Solution_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
</style>
