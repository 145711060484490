import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main.vue'
import Home from '../components/Home.vue'
import Capability from '../components/Capability.vue'
import Solution from '../components/Solution.vue'
import About from '../components/About.vue'
import Career from '../components/Career.vue'
import News from '../components/News.vue'
import Product from '../components/Product.vue'
import Automotive from '../components/Automotive.vue'
import Vision from '../components/career/Vision.vue'
import Opport from '../components/career/Opport.vue'
import Student from '../components/career/Student.vue'
import Internship from '../components/career/Internship.vue'
import Nienyi from '../components/career/Nienyi.vue'
import Family from '../components/career/Family.vue'
import Contact from '../components/Contact.vue'
import Global from '../components/Global.vue'
import NewsData from '../components/NewsData.vue'
import Connectivity from '../components/Connectivity.vue'
import NienyiLogin from '../components/NienyiLogin.vue'
import NienyiMain from '../components/NienyiMain.vue'
import Nyhome from '../components/admin/Nyhome.vue'
import NyMain from '../components/admin/NyMain.vue'
import NyCapability from '../components/admin/NyCapability.vue'
import NyProduct from '../components/admin/NyProduct.vue'
import NySolution from '../components/admin/NySolution.vue'
import NyGlobal from '../components/admin/NyGlobal.vue'
import NyNews from '../components/admin/NyNews.vue'
import NyAbout from '../components/admin/NyAbout.vue'
import NyCareer from '../components/admin/NyCareer.vue'
import NyVision from '../components/admin/NyVision.vue'
import NyOpport from '../components/admin/NyOpport.vue'
import NyStudent from '../components/admin/NyStudent.vue'
import NyInternship from '../components/admin/NyInternship.vue'
import NyNienyi from '../components/admin/NyNienyi.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Main' // 当用户访问网址后的/时转到登录页面
  },
  {
    path: '/Main',
    component: Main,
    redirect: '/Home',
    children: [{
      path: '/Home',
      component: Home
    },
    {
      path: '/Capability',
      component: Capability
    },
    {
      path: '/Solution',
      component: Solution
    },
    {
      path: '/About',
      component: About
    },
    {
      path: '/Career',
      component: Career,
      redirect: '/Vision',
      children: [{
        path: '/Vision',
        component: Vision
      },
      {
        path: '/Opport',
        component: Opport
      },
      {
        path: '/Student',
        component: Student
      },
      {
        path: '/Internship',
        component: Internship
      },
      {
        path: '/Nienyi',
        component: Nienyi
      }]
    },
    {
      path: '/News',
      component: News
    },
    {
      path: '/Product/:id?',
      component: Product
    },
    {
      path: '/Automotive/:cid?',
      component: Automotive
    },
    {
      path: '/Family/:id?',
      component: Family
    },
    {
      path: '/Contact',
      component: Contact
    },
    {
      path: '/Global',
      component: Global
    },
    {
      path: '/NewsData/:id?',
      component: NewsData
    },
    {
      path: '/Connectivity/:id?',
      component: Connectivity
    }]
  },
  {
    path: '/NienyiLogin',
    component: NienyiLogin
  },
  {
    path: '/NienyiMain',
    component: NienyiMain,
    redirect: '/Nyhome',
    children: [{
      path: '/Nyhome',
      component: Nyhome
    }, {
      path: '/NyMain',
      component: NyMain
    },
    {
      path: '/NyCapability',
      component: NyCapability
    },
    {
      path: '/NyProduct',
      component: NyProduct
    },
    {
      path: '/NySolution',
      component: NySolution
    },
    {
      path: '/NyGlobal',
      component: NyGlobal
    },
    {
      path: '/NyNews',
      component: NyNews
    },
    {
      path: '/NyAbout',
      component: NyAbout
    },
    {
      path: '/NyCareer',
      component: NyCareer
    },
    {
      path: '/NyVision',
      component: NyVision
    },
    {
      path: '/NyOpport',
      component: NyOpport
    },
    {
      path: '/NyStudent',
      component: NyStudent
    },
    {
      path: '/NyInternship',
      component: NyInternship
    },
    {
      path: '/NyNienyi',
      component: NyNienyi
    }]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
