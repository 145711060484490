<template>
  <div class="main-container" v-if="Home_data.Home.Videowebm">
    <section data-overlay="4" class="cover imagebg videobg justify-content-center home_banner d-table video-active">
      <div class="home_banner-cell">
        <div class="home_banner__con">
          <video playsinline="" autoplay="autoplay" loop="loop" muted="muted">
            <source :src="`${$FilesVideo}${Home_data.Home.Videowebm}`" type="video/webm">
            <source :src="`${$FilesVideo}${Home_data.Home.Videomp4}`" type="video/mp4">
            <source :src="`${$FilesVideo}${Home_data.Home.Videoogv}`" type="video/ogv">
          </video>
        </div>
        <transition name="el-fade-in-linear">
          <div v-show="show" class="container home_banner-txt">
            <div class="row">
              <div class="col-md-8">
                <h1 v-html="Home_data.Home.Home_titeh1"></h1>
                <p class="lead" v-html="Home_data.Home.Home_text1"></p>
                <a @click="butluyou(Home_data.Home.Buturl1)" class="btn btn--lg p-4"><span class="btn__text"
                    v-html="Home_data.Home.Home_but"></span></a>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
    <section class="text-center space--md pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-lg-8">
            <h2 v-html="Home_data.Home.Home_titeh2"></h2>
            <p class="lead" v-html="Home_data.Home.Home_text2"></p>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <div class="col-md-4" v-for="(item,index) in Home_data.Xqdata" :key="index">
          <div class="feature feature-3 boxed boxed--lg">
            <span class="h1 home-intro__number" v-html="item.Span"></span>
            <img :data-src="`${$FilesImg}${item.Imgsrc}`" :src="`${$FilesImg}${item.Imgsrc}`" lazy="loaded">
            <h4 v-html="item.H4"></h4>
          </div>
        </div>
      </div>
      <div class="row mt-5 justify-content-center space--md">
        <a @click="butluyou(Home_data.Home.Buturl2)" class="btn btn--lg btn--primary p-4 home-intro__btn">
          <span class="btn__text" v-html="Home_data.Home.Home_but_span1"></span>
        </a>
      </div>
    </div>

    <section class="imageblock switchable bg--primary height-50">
      <div class="imageblock__content col-lg-6 col-md-4 pos-right">
        <div class="background-image-holder" :style="`background: url(${$FilesImg}${Home_data.Listdata.Imgsrc1}); opacity: 1;`"><img
          :alt="Home_data.Listdata.Imgsrc1" :src="`${$FilesImg}${Home_data.Listdata.Imgsrc1}`"></div>
    </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-lg-5 col-md-7">
            <h3 class="h2" v-html="Home_data.Listdata.Text1"></h3><a :href="Home_data.Listdata.Href1"
              class="type--uppercase" v-html="Home_data.Listdata.Span2"></a>
          </div>
        </div>
      </div>
    </section>

    <section class="imageblock switchable switchable--switch height-50">
      <div class="imageblock__content col-lg-6 col-md-4 pos-right">
        <div class="background-image-holder" :style="`background: url(${$FilesImg}${Home_data.Listdata.Imgsrc2}); opacity: 1;`">
          <img :alt="Home_data.Listdata.Imgsrc1" :src="`${$FilesImg}${Home_data.Listdata.Imgsrc2}`">
        </div>
      </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-lg-5 col-md-7">
            <h3 class="h2" v-html="Home_data.Listdata.Text2"></h3>
            <a :href="Home_data.Listdata.Href2" class="type--uppercase" v-html="Home_data.Listdata.Span2"></a>
          </div>
        </div>
      </div>
    </section>

    <section class="imageblock switchable height-50 bg--dark">
      <div class="imageblock__content col-lg-6 col-md-4 pos-right">
        <div class="background-image-holder" :style="`background: url(${$FilesImg}${Home_data.Listdata.Imgsrc3}); opacity: 1;`"><img
            :alt="Home_data.Listdata.Imgsrc3" :src="`${$FilesImg}${Home_data.Listdata.Imgsrc3}`"></div>
      </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-lg-5 col-md-7 ">
            <h3 class="h2" v-html="Home_data.Listdata.Text3"></h3><a :href="Home_data.Listdata.Href3"
              class="type--uppercase"><span class="btn__text" v-html="Home_data.Listdata.Span3"></span></a>
          </div>
        </div>
      </div>
    </section>

    <section class="home-investors bg--secondary">
      <div class="container">
        <div v-for="(item,index) in Home_data.Newsdata" :key="index">
          <hr class="mt-4"><a :href="item.Home_href" class="">
            <div class="pt-5 pb-5">
              <h4 v-html="item.Home_h4"></h4>
              <p class="home-news" v-html="item.Home_p"></p>
            </div>
          </a>
        </div>
        <!---->
        <!---->
        <!--<hr><a href="https://tw.stock.yahoo.com/q/bc?s=3023" target="_blank" class="mt-3">前往查询股价(公司代号：3023)</a>-->
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data () {
    return {
      show: false,
      Home_data: {
        Home: {
          Videowebm: '',
          Videomp4: '',
          Videoogv: '',
          Home_titeh1: '',
          Home_text1: '',
          Home_but: '',
          Home_titeh2: '',
          Home_text2: '',
          Home_but_span1: '',
          Buturl1: '',
          Buturl2: ''
        },
        Xqdata: [],
        Listdata: {
          Imgsrc1: '',
          Imgsrc2: '',
          Imgsrc3: '',
          Text1: '',
          Text2: '',
          Text3: '',
          Href1: '',
          Href2: '',
          Href3: '',
          Span1: '',
          Span2: '',
          Span3: ''
        },
        Newsdata: []
      }
    }
  },
  created () {
    this.Home()
  },
  mounted () {
    setTimeout(() => {
      this.show = true
    }, 1000)
  },
  methods: {
    butluyou (data) {
      this.$router.push('/' + data)
    },
    async Home () {
      const { data: res } = await this.$http.get('Home')
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Home_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
  .main-container {
    padding: 0;
    margin: 0;
  }

  .feature {
    text-align: center;
  }

  .space--md,
  section.space--md {
    padding-top: 8.35714286em;
    padding-bottom: 8.35714286em;
  }
</style>
