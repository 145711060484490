<template>
    <div>
        <section>
            <div class="container">
                <h2 v-html="Opport_data.Title"></h2>
            </div>
        </section>
        <section class="bg--secondary">
            <div class="container">
                <div class="row">
                    <div v-for="(itme,index) in Opport_data.Opport_list1" :key="index" class="col-md-4">
                        <div class="feature boxed boxed--lg boxed--border">
                            <h5 class="h3 mb-4" v-html="itme.Title"></h5>
                            <div v-for="(itmee,indexx) in itme.Opport_list1_list" :key="indexx" class="row">
                                <div class="col" v-html="itmee.Title"></div>
                                <div class="col text-right"><a :href="itmee.Url" target="_blank" v-html="itmee.Text"></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>
<script>
export default {
  data () {
    return {
      Opport_data: {
        H2text: '',
        Opport_list1: []
      }
    }
  },
  created () {
    this.Opport()
  },
  mounted () {

  },
  methods: {
    async Opport () {
      const { data: res } = await this.$http.get('Opport')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Opport_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
