<template>
<el-container>
   <el-aside class="menaside"  width="287px">
          <div class="aside_zuo">
            <div class="asidelogo">
              <img @click="imghome" :src="`${$FilesImg}/nienyilogo.png`" alt="年益" width="200px" height="100px">
            </div>
            <hr class="header__hr">
            <div class="cdtree">
            <el-tree
              :data="data"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick">
            </el-tree>
          </div>
          <hr class="header__hr">
          <div class="cdtree">
            <el-tree
              :data="datatoo"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick">
            </el-tree>
          </div>
          <hr class="header__hr">
          <div>
              <ul class="la-select_maine">
                <li @click="list3cilck" v-html="$t('Main.li1')"></li>
                <li><el-select popper-class="la-select_main"  v-model="value" @change="languageXZ" :placeholder="this.$t('Main.placeholder')">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select></li>
              </ul>
          </div>
          </div>
        </el-aside>
        <el-aside v-show="aside" class="minaside" width="287px">
          <div class="aside_zuo">
            <div class="asidelogo">
              <img @click="imghome"  :src="Main_data.Login" alt="年益" width="200px" height="100px">
            </div>
            <hr class="header__hr">
             <div class="cdtree">
            <el-tree
              :data="data"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick">
            </el-tree>
          </div>
           <hr class="header__hr">
          <div class="cdtree">
            <el-tree
              :data="datatoo"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick">
            </el-tree>
          </div>
          <hr class="header__hr">
          <div>
              <ul class="la-select_maine">
                <li v-html="$t('Main.li1')"></li>
                <li><el-select popper-class="la-select_main"  v-model="value" @change="languageXZ" :placeholder="this.$t('Main.placeholder')">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select></li>
              </ul>
          </div>
          </div>
        </el-aside>
  <el-container>
    <el-header style="height:47.53px !important" >
        <div class="imglogo">
          <i @click="stack_menu" style="float: left;font-family:Comic Sans MS;font-size:14px;" class="stack-menu"></i>
          <img class="imgheder" :src="Main_data.Login" alt="年益" width="100px" height="47px">
        </div>
      </el-header>
    <el-main>
      <router-view></router-view>
      <div>
      <section class="space--sm bg--dark">
        <div class="container">
          <form class="cta cta--horizontal text-center">
            <div class="row mb-3">
              <div class="col text-center">
                <h3 v-html="$t('Main.h3text')"></h3>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-7 offset-sm-1 col-lg-5 offset-lg-2 footer-subscribe-input"><input
                  type="email" :placeholder="$t('Main.placeholdermail')" class="validate-required validate-email"></div>
              <div class="col-sm-3 col-lg-3 text-center"><input type="submit"
                  class="btn btn--primary type--uppercase inner-link" :value="$t('Main.value')"></div>
            </div>
          </form>
        </div>
      </section>
    </div>
        <footer class="space--sm footer-2">
        <div class="container">
          <div class="text-center">
            <h3 style="font-family: cursive; font-size: 30px;" v-html="$t('Main.h3text1')"></h3>
          </div>
          <div class="d-flex justify-content-center flex-wrap flex-sm-nowrap mt-3 mt-sm-5">
            <div class="pr-2 pl-2"><a href="/#/Home"
                class="type--fine-print" v-html="$t('Main.atext')"></a><a href="/#/Contact"
                class="type--fine-print" v-html="$t('Main.atext1')"></a><a href="/#/Contact"
                target="_blank" class="type--fine-print" v-html="$t('Main.atext2')"></a></div>
            <div class="pr-2 pl-2">
              <span class="type--fine-print">
                {{$t('Main.beian')}}: <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">
                  <span style="color: #002fff;">苏ICP备20008874号</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </el-main>
  </el-container>
</el-container>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      aside: false,
      defaultProps: {
        children: 'ChildList',
        label: 'Menus'
      },
      data: [],
      datatoo: [],
      options: [{
        value: 'cn',
        label: '简体中文'
      }, {
        value: 'en',
        label: 'English'
      }],
      Main_data: {
        Login: `${this.$FilesImg}/nienyilogo.png`
      },
      value: localStorage.getItem('locale') || 'cn'
    }
  },
  created () {
    this.menus()
    this.menustoo()
  },
  mounted () {},
  methods: {
    imghome () {
      if (this.$route.path === '/Home') return
      this.$router.push('/Home')
    },
    stack_menu () {
      this.aside = !this.aside
    },
    handleNodeClick (data) {
      if (data.Path === this.$route.path) return
      // if (data.ChildList.length !== 0) return
      if (data.Cid !== 0) {
        if (data.Path === '/product') {
          this.$router.push(data.Path)
        } else {
          if ((data.Path + data.Id) === this.$route.path) return
          this.$router.push(data.Path + data.Id)
        }
      } else {
        this.$router.push(data.Path)
      }
    },
    async menus () {
      const { data: res } = await this.$http.get('Menus')
      if (res.status !== 200) return this.$message.success(this.$t('State.Eerr'))
      this.data = res.response
    },
    async menustoo () {
      const { data: res } = await this.$http.get('MenusToo')
      if (res.status !== 200) return this.$message.success(this.$t('State.Eerr'))
      this.datatoo = res.response
    },
    list3cilck () {
      if (this.$route.path === '/Contact') return
      this.$router.push('/Contact')
    },
    languageXZ () {
      this.$i18n.locale = this.value
      localStorage.setItem('locale', this.value)
      this.$router.go(0)
    }
  }
}
</script>
<style lang="less" scoped>
.el-container{
  width: 100%;
  height: 100%;
  overflow-x: visible;
}
.el-header{
  text-align:center;
  overflow:hidden;
  width: 100%;
  background: #0b3574;
  z-index: 10;
}
.el-aside{
  width: 100%;
  height: 100%;
  background-color:#00558c;
}
.el-main{
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #FFFF;
  padding: 0 !important;
}
.elfooter{
  width: 100%;
  height: 60px;
  background-color: blueviolet;
}
.imglogo{
  padding-top: 4px;
  margin: 0 auto;
  //transform: translate(50%,0);//根据这个div的本身长宽高来唯一，前面是左右，后面是上下
}
.asidelogo{
  text-align:center;
}
.header__hr{
  opacity: .1;
}
.aside_zuo{
  width: 233px;
  margin: 0 auto;
}
.stack-menu{
  margin-top: 14px !important;
  width: 25px !important;
  height: 3px !important;
  border-top: 3px solid #ffffff !important;
  border-radius: 0% !important;
  border-bottom: 3px solid #ffffff !important;
  background-color: #ffffff !important;
  padding: 5px 0 !important;
  background-clip:content-box !important;
  box-sizing: content-box!important;
}
@media only screen and (max-width: 992px){
.menaside {
    display: none!important;
  }
}
@media only screen and (min-width: 992px){
.el-header {
      display: none!important;
  }
.minaside {
    display: none!important;
  }
}
.cdtree{
  margin: 0 auto;
}
.el-tree {
  color: #ffffff94;
  color:rgba(255, 255, 255, 0.58);
  padding-top: 20px;
}
li{
  margin-left: 40px;
  list-style-type:none;
  line-height: 30px;
  color: #ffffff94;
  color:rgba(255, 255, 255, 0.58);
  cursor: default;
}
li:hover {
  color: #ffffff;
}
.imgheder{
  padding-top: 3px;
}
.asidelogo{
  margin-top: 20px;
}
</style>
