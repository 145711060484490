import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: {
      user: '', // 当前登录的用户名
      username: '', // 当前登录的用户名字
      region: '', // 当前登录用户的厂区
      rolename: '', // 当前登陆用属于管理员还是普通用户
      ykregion: '', // 游客
      parent: 5, // 当前权限
      token: ''
    }
  },
  mutations: {
    loginlist (state, data) {
      state.login = data
    }
  },
  actions: {
  },
  modules: {
  }
})
