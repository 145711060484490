<template>
  <div class="adminprod">
  <el-card class="box-card">
      <span class="titletext">背景图</span>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                  <div>
                    <span class="demonstration">原背景图</span>
                    <div style="text-align: center;">
                        <el-image style="background-color: #00558c;"  :src="`${$FilesImg}${About_dataCn.Backgroundimg1}`"> <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i></div></el-image>
                    </div>
                  </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <span class="demonstration">修背景图</span>
                <el-upload class="upload-demo" style="text-align: center;" ref="logofileref" action="" drag :limit="1"
                  :http-request="logofileref" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <div style="text-align:center">
            <el-button type="primary" @click="logoup">上传</el-button>
          </div>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card">
      <span class="titletext">中文英文标题</span>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文标题</span>
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="About_dataCn" label-width="100px">
              <el-form-item label="图标题">
                <el-input v-model="About_dataCn.Htitle"></el-input>
              </el-form-item>
              <el-form-item label="图文本">
                <el-input type="textarea" :rows="8" v-model="About_dataCn.Htext"></el-input>
              </el-form-item>
              <el-form-item label="文章标题">
                <el-input v-model="About_dataCn.Title"></el-input>
              </el-form-item>
              <el-form-item label="文章一">
                <el-input type="textarea" :rows="8" v-model="About_dataCn.P1text"></el-input>
              </el-form-item>
              <el-form-item label="文章二">
                <el-input type="textarea" :rows="8" v-model="About_dataCn.P2text"></el-input>
              </el-form-item>
               <el-form-item label="文章三">
                <el-input type="textarea" :rows="8" v-model="About_dataCn.List2_p"></el-input>
              </el-form-item>
              <el-form-item label="发展历程标题">
                <el-input v-model="About_dataCn.List1_title"></el-input>
              </el-form-item>
              <el-form-item label="底层品质标题">
                <el-input v-model="About_dataCn.List2_title"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="About_text1upcn(About_dataCn)">修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文标题</span>
          <div class="grid-content bg-purple-light">
            <el-form ref="form" :model="About_dataEn" label-width="100px">
              <el-form-item label="图标题">
                <el-input v-model="About_dataCn.Htitle"></el-input>
              </el-form-item>
              <el-form-item label="图文本">
                <el-input type="textarea" :rows="8" v-model="About_dataCn.Htext"></el-input>
              </el-form-item>
              <el-form-item label="文章标题">
                <el-input v-model="About_dataEn.Title"></el-input>
              </el-form-item>
              <el-form-item label="文章一">
                <el-input type="textarea" :rows="8" v-model="About_dataEn.P1text"></el-input>
              </el-form-item>
              <el-form-item label="文章二">
                <el-input type="textarea" :rows="8" v-model="About_dataEn.P2text"></el-input>
              </el-form-item>
               <el-form-item label="文章三">
                <el-input type="textarea" :rows="8" v-model="About_dataEn.List2_p"></el-input>
              </el-form-item>
              <el-form-item label="发展历程标题">
                <el-input v-model="About_dataEn.List1_title"></el-input>
              </el-form-item>
              <el-form-item label="底层品质标题">
                <el-input v-model="About_dataEn.List2_title"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="About_text1upen(About_dataEn)">修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
     <el-card class="box-card">
      <span class="titletext">List中文图标</span>
      <el-row>
        <el-col :span="24"><div class="grid-content bg-purple-dark">
          <el-table :data="About_dataCn.About_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column  label="图片" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Imgsrc" label="图片名" align="center"></el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="list1filemetscn(scope.row)" size="small">修改</el-button>
            </template>
          </el-table-column>
          </el-table>
        </div></el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
     <el-card class="box-card">
      <span class="titletext">List英文图标</span>
      <el-row>
        <el-col :span="24"><div class="grid-content bg-purple-dark">
          <el-table :data="About_dataEn.About_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column  label="图片" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Imgsrc" label="图片名" align="center"></el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="list1filemetsen(scope.row)" size="small">修改</el-button>
            </template>
          </el-table-column>
          </el-table>
        </div></el-col>
      </el-row>
    </el-card>
    <el-dialog title="List中文图标修改" :modal-append-to-body='false' :visible.sync="tubvisicn" width="500px">
        <el-form :model="form">
           <el-form-item label="修改文本">
             <el-input type="textarea" :rows="5" v-model="form.Text" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="修改图片">
             <el-upload class="upload-demo" style="text-align: center;" ref="list1fileref" action="" drag :limit="1"
                  :http-request="list1fileref" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="tubvisicn = false">取 消</el-button>
         <el-button type="primary" @click="listfileuppcn(form)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="List中文图标修改" :modal-append-to-body='false' :visible.sync="tubvisien" width="500px">
        <el-form :model="form">
           <el-form-item label="修改文本">
             <el-input type="textarea" :rows="5" v-model="form.Text" autocomplete="off"></el-input>
           </el-form-item>
           <el-form-item label="修改图片">
             <el-input  v-model="form.Imgsrc" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="tubvisien = false">取 消</el-button>
         <el-button type="primary" @click="listfileuppen(form)">确 定</el-button>
       </div>
     </el-dialog>
    <hr class="w-100 mt-5 mb-5">
     <el-card class="box-card">
      <span class="titletext">发展历程</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文发展历程</span>
          <div class="grid-content bg-purple">
            <el-table :data="About_dataCn.About_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Datetitle" label="时间" align="center"></el-table-column>
            <el-table-column prop="Text" label="文本" align="center"></el-table-column>
            <el-table-column align="center">
              <template slot="header">
                <el-button type="primary" @click="licvisiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="list2uppcn(scope.row)" size="small">修改</el-button>
                <el-button type="danger" @click="About_list2delcn(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文发展历程</span>
          <div class="grid-content bg-purple-light">
              <el-table :data="About_dataEn.About_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Datetitle" label="时间" align="center"></el-table-column>
              <el-table-column prop="Text" label="文本" align="center"></el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <el-button type="primary" @click="licvisibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="list2uppen(scope.row)" size="small">修改</el-button>
                  <el-button type="danger" @click="About_list2delen(scope.row)" size="small">删除</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文发展历程添加" :modal-append-to-body='false' :visible.sync="licvisiblecn" width="500px">
        <el-form :model="licform">
           <el-form-item label="时间">
             <el-date-picker
              v-model="licform.Datetitle"
              type="date"
              value-format="yyyy/MM/dd"
              placeholder="选择日期">
            </el-date-picker>
           </el-form-item>
           <el-form-item label="文本">
             <el-input type="textarea" :rows="5" v-model="licform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="licvisiblecn = false">取 消</el-button>
         <el-button type="primary" @click="About_list2addcn(licform)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文发展历程添加" :modal-append-to-body='false' :visible.sync="licvisibleen" width="500px">
        <el-form :model="licform">
           <el-form-item label="时间">
             <el-date-picker
              v-model="licform.Datetitle"
              type="date"
              value-format="yyyy/MM/dd"
              placeholder="选择日期">
            </el-date-picker>
           </el-form-item>
           <el-form-item label="文本">
             <el-input type="textarea" :rows="5" v-model="licform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="licvisibleen = false">取 消</el-button>
         <el-button type="primary" @click="About_list2adden(licform)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="中文发展历程修改" :modal-append-to-body='false' :visible.sync="list2visiblecn" width="500px">
        <el-form :model="licform">
           <el-form-item label="时间">
             <el-date-picker
              v-model="licform.Datetitle"
              type="date"
              value-format="yyyy/MM/dd"
              placeholder="选择日期">
            </el-date-picker>
           </el-form-item>
           <el-form-item label="文本">
             <el-input type="textarea" :rows="5" v-model="licform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list2visiblecn = false">取 消</el-button>
         <el-button type="primary" @click="About_list2uppcn(licform)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文发展历程修改" :modal-append-to-body='false' :visible.sync="list2visibleen" width="500px">
        <el-form :model="licform">
           <el-form-item label="时间">
             <el-date-picker
              v-model="licform.Datetitle"
              type="date"
              value-format="yyyy/MM/dd"
              placeholder="选择日期">
            </el-date-picker>
           </el-form-item>
           <el-form-item label="文本">
             <el-input type="textarea" :rows="5" v-model="licform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list2visibleen = false">取 消</el-button>
         <el-button type="primary" @click="About_list2uppen(licform)">确 定</el-button>
       </div>
     </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
     <el-card class="box-card">
      <span class="titletext">品质策略</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <span class="titletext">中文品质策略</span>
          <div class="grid-content bg-purple">
            <el-table :data="About_dataCn.About_listtitle" border style="width: 100%; margin-bottom: auto; margin: auto;">
            <el-table-column type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="Title" label="标题" align="center"></el-table-column>
            <el-table-column align="center">
              <template slot="header">
                <el-button type="primary" @click="list3visiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="list3xgcn(scope.row)" size="small">修改</el-button>
                <el-button type="warning" @click="About_list3_listcn(scope.row.Id)" size="small">编辑</el-button>
                <el-button type="danger" @click="About_list3delcn(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文品质策略</span>
          <div class="grid-content bg-purple-light">
              <el-table :data="About_dataEn.About_listtitle" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <el-button type="primary" @click="list3visibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" @click="list3xgen(scope.row)" size="small">修改</el-button>
                  <el-button type="warning" @click="About_list3_listen(scope.row.Id)" size="small">编辑</el-button>
                  <el-button type="danger" @click="About_list3delen(scope.row)" size="small">删除</el-button>
                </template>
              </el-table-column>
              </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="中文新增" :modal-append-to-body='false' :visible.sync="list3visiblecn" width="500px">
        <el-form :model="list3form">
           <el-form-item label="标题">
             <el-input  v-model="list3form.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3visiblecn = false">取 消</el-button>
         <el-button type="primary" @click="About_list3addcn(list3form)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文新增" :modal-append-to-body='false' :visible.sync="list3visibleen" width="500px">
        <el-form :model="list3form">
           <el-form-item label="标题">
             <el-input  v-model="list3form.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3visibleen = false">取 消</el-button>
         <el-button type="primary" @click="About_list3adden(list3form)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="中文修改" :modal-append-to-body='false' :visible.sync="list3xgvisiblecn" width="500px">
        <el-form :model="list3form">
           <el-form-item label="标题">
             <el-input  v-model="list3form.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3xgvisiblecn = false">取 消</el-button>
         <el-button type="primary" @click="About_list3uppcn(list3form)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文修改" :modal-append-to-body='false' :visible.sync="list3xgvisibleen" width="500px">
        <el-form :model="list3form">
           <el-form-item label="标题">
             <el-input  v-model="list3form.Title" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3xgvisibleen = false">取 消</el-button>
         <el-button type="primary" @click="About_list3uppen(list3form)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="中文详情" :modal-append-to-body='false' :visible.sync="list3_listvisiblecn" width="500px">
        <el-table :data="list3_listcn" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Text" label="标题" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list3_list_listvisiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list3uppcn(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="About_list3_listdelcn(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="英文详情" :modal-append-to-body='false' :visible.sync="list3_listvisibleen" width="500px">
        <el-table :data="list3_listen" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Text" label="标题" align="center"></el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <el-button type="primary" @click="list3_list_listvisibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" @click="list3uppen(scope.row)" size="small">修改</el-button>
              <el-button type="danger" @click="About_list3_listdelen(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
     </el-dialog>
     <el-dialog title="中文详情新增" :modal-append-to-body='false' :visible.sync="list3_list_listvisiblecn" width="500px">
        <el-form :model="list3_listform">
           <el-form-item label="标题">
             <el-input  v-model="list3_listform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3_list_listvisiblecn = false">取 消</el-button>
         <el-button type="primary" @click="About_list3_listaddcn(list3_listform)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文详情新增" :modal-append-to-body='false' :visible.sync="list3_list_listvisibleen" width="500px">
        <el-form :model="list3_listform">
           <el-form-item label="标题">
             <el-input  v-model="list3_listform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3_list_listvisibleen = false">取 消</el-button>
         <el-button type="primary" @click="About_list3_listadden(list3_listform)">确 定</el-button>
       </div>
     </el-dialog>
          <el-dialog title="中文详情修改" :modal-append-to-body='false' :visible.sync="list3_listxgvisiblecn" width="500px">
        <el-form :model="list3_listform">
           <el-form-item label="文本">
             <el-input  v-model="list3_listform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3_listxgvisiblecn = false">取 消</el-button>
         <el-button type="primary" @click="About_list3_listuppcn(list3_listform)">确 定</el-button>
       </div>
     </el-dialog>
     <el-dialog title="英文详情修改" :modal-append-to-body='false' :visible.sync="list3_listxgvisibleen" width="500px">
        <el-form :model="list3_listform">
           <el-form-item label="文本">
             <el-input  v-model="list3_listform.Text" autocomplete="off"></el-input>
           </el-form-item>
         </el-form>
       <div slot="footer" class="dialog-footer">
         <el-button @click="list3_listxgvisibleen = false">取 消</el-button>
         <el-button type="primary" @click="About_list3_listuppen(list3_listform)">确 定</el-button>
       </div>
     </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5">
    <el-card class="box-card" v-for="(item,index) in About_dataCn.About_forr" :key="index">
      <div style="display: flex; flex-direction: row;justify-content: space-between;">
        <span class="titletext">中文英文安全宣言{{index + 1}}</span>
        <div style="margin-right: 5%;">
          <el-button type="primary" size="mini" @click="Addsafe">新增</el-button>
          <el-button type="danger" size="mini" v-if="About_dataCn.About_forr.length > 1" @click="Deletesafe(item.Id, item.Imgsrc, About_dataEn.About_forr[index].Id)" >删除</el-button>
        </div>
      </div>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文安全宣言{{index + 1}}</span>
          <div class="grid-content bg-purple">
            <el-form ref="form" label-width="80px">
              <el-form-item label="大标题">
                <el-input v-model="item.Forr_h3"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="item.Foor_h41"></el-input>
              </el-form-item>
              <el-form-item label="文本">
                <el-input type="textarea" :rows="8" v-model="item.Foor_p2"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="item.Forr_h4"></el-input>
              </el-form-item>
              <el-form-item label="文本">
                <el-input v-model="item.Forr_p1"></el-input>
              </el-form-item>
              <el-form-item label="图片">
                <el-image :src="`${$FilesImg}${item.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片名">
                <el-input v-model="item.Imgsrc" disabled></el-input>
              </el-form-item>
              <el-form-item label="上传图片">
                <el-upload class="upload-demo" style="text-align: center;" :ref="`bootomimgfilere${index}`" action="" drag :limit="1"
                  :http-request="bootomimgfilere" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="About_list4_listuppcn(item, index)" >上传修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文安全宣言{{ index + 1 }}</span>
          <div class="grid-content bg-purple-light">
            <el-form label-width="80px">
              <el-form-item label="大标题">
                <el-input v-model="About_dataEn.About_forr[index].Forr_h3"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="About_dataEn.About_forr[index].Foor_h41"></el-input>
              </el-form-item>
              <el-form-item label="文本">
                <el-input type="textarea" :rows="8" v-model="About_dataEn.About_forr[index].Foor_p2"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="About_dataEn.About_forr[index].Forr_h4"></el-input>
              </el-form-item>
              <el-form-item label="文本">
                <el-input v-model="About_dataEn.About_forr[index].Forr_p1"></el-input>
              </el-form-item>
              <el-form-item label="图片名">
                <el-input v-model="About_dataEn.About_forr[index].Imgsrc"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="About_list4_listuppen(About_dataEn.About_forr[index])" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    </div>
</template>
<script>
export default {
  data () {
    return {
      About_dataCn: {
      },
      About_dataEn: {
      },
      logofile: '',
      form: [],
      tubvisicn: false,
      tubvisien: false,
      list1file: '',
      licvisiblecn: false,
      licvisibleen: false,
      licform: {
        Id: 0,
        Datetitle: '',
        Text: ''
      },
      list2visiblecn: false,
      list2visibleen: false,
      list3form: {
        Id: 0,
        Title: ''
      },
      list3visiblecn: false,
      list3visibleen: false,
      list3xgvisiblecn: false,
      list3xgvisibleen: false,
      list3_listcn: [],
      list3_listen: [],
      list3_listvisiblecn: false,
      list3_listvisibleen: false,
      list3_id: '',
      list3_list_listvisiblecn: false,
      list3_list_listvisibleen: false,
      list3_listform: {
        Id: 0,
        Cid: 0,
        Text: ''
      },
      list3_listxgvisiblecn: false,
      list3_listxgvisibleen: false,
      bootomimgfile: ''
    }
  },
  created () {
    this.AboutCn()
    this.AboutEn()
  },
  mounted () {

  },
  methods: {
    async AboutCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}About`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.About_dataCn = res.response
    },
    async AboutEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}About`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.About_dataEn = res.response
    },
    logofileref (param) {
      this.logofile = param.file
    },
    async logoup () {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.logofileref.submit()
      var File = new FormData()
      File.append('file', this.logofile)
      const { data: res } = await this.$http.post(`${this.$admin}About_logoup`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.logofile = ''
      this.$refs.logofileref.clearFiles()
      this.AboutCn()
    },
    async About_text1upcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_text1upcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.AboutCn()
    },
    async About_text1upen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_text1upen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.AboutEn()
    },
    list1fileref (param) {
      this.list1file = param.file
    },
    list1filemetscn (row) {
      this.form = row
      this.tubvisicn = true
    },
    list1filemetsen (row) {
      this.form = row
      this.tubvisien = true
    },
    async listfileuppcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.list1fileref.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Img1', this.list1file)
      File.append('Text', row.Text)
      const { data: res } = await this.$http.post(`${this.$admin}About_list1uppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list1file = ''
      this.$refs.list1fileref.clearFiles()
      this.tubvisicn = false
      this.AboutCn()
    },
    async listfileuppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Text', row.Text)
      const { data: res } = await this.$http.post(`${this.$admin}About_list1uppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.tubvisien = false
      this.AboutEn()
    },
    async About_list2addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list2addcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.licvisiblecn = false
      this.AboutCn()
    },
    async About_list2adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list2adden`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.licvisibleen = false
      this.AboutEn()
    },
    async About_list2delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list2delcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.licvisiblecn = false
      this.AboutCn()
    },
    async About_list2delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list2delen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.licvisibleen = false
      this.AboutEn()
    },
    list2uppcn (row) {
      this.licform = row
      this.list2visiblecn = true
    },
    list2uppen (row) {
      this.licform = row
      this.list2visibleen = true
    },
    async About_list2uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list2uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list2visiblecn = false
      this.AboutCn()
    },
    async About_list2uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list2uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list2visibleen = false
      this.AboutEn()
    },
    async About_list3addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3addcn`, row)
      if (res.status !== 200) return alert('新增失败')
      this.$message.success('新增成功')
      this.list3visiblecn = false
      this.AboutCn()
    },
    async About_list3adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3adden`, row)
      if (res.status !== 200) return alert('新增失败')
      this.$message.success('新增成功')
      this.list3visibleen = false
      this.AboutEn()
    },
    list3xgcn (row) {
      this.list3form = row
      this.list3xgvisiblecn = true
    },
    list3xgen (row) {
      this.list3form = row
      this.list3xgvisibleen = true
    },
    async About_list3uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list3xgvisiblecn = false
      this.AboutCn()
    },
    async About_list3uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list3xgvisibleen = false
      this.AboutEn()
    },
    async About_list3delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3delcn`, row)
      if (res.status === 201) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.list2visiblecn = false
      this.AboutCn()
    },
    async About_list3delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3delen`, row)
      if (res.status === 201) return this.$message.error(res.msg)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.list2visibleen = false
      this.AboutEn()
    },
    async About_list3_listcn (row) {
      this.list3_id = row
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listcn?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list3_listcn = res.response
      this.list3_listvisiblecn = true
    },
    async About_list3_listen (row) {
      this.list3_id = row
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listen?Id=${row}`)
      if (res.status !== 200) return alert('获取失败')
      this.list3_listen = res.response
      this.list3_listvisibleen = true
    },
    async About_list3_listaddcn (row) {
      row.Cid = this.list3_id
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listaddcn`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.About_list3_listcn(this.list3_id)
      this.list3_list_listvisiblecn = false
    },
    async About_list3_listadden (row) {
      row.Cid = this.list3_id
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listadden`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.About_list3_listen(this.list3_id)
      this.list3_list_listvisibleen = false
    },
    list3uppcn (row) {
      this.list3_listform = row
      this.list3_listxgvisiblecn = true
    },
    list3uppen (row) {
      this.list3_listform = row
      this.list3_listxgvisibleen = true
    },
    async About_list3_listuppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listuppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list3_listxgvisiblecn = false
      this.About_list3_listcn(this.list3_id)
    },
    async About_list3_listuppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listuppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.list3_listxgvisibleen = false
      this.About_list3_listen(this.list3_id)
    },
    async About_list3_listdelcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listdelcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.About_list3_listcn(this.list3_id)
    },
    async About_list3_listdelen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list3_listdelen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.About_list3_listen(this.list3_id)
    },
    bootomimgfilere (param) {
      this.bootomimgfile = param.file
    },
    async About_list4_listuppcn (row, index) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs[`bootomimgfilere${index}`][0].submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Img1', this.bootomimgfile)
      File.append('Forr_h3', row.Forr_h3)
      File.append('Forr_h4', row.Forr_h4)
      File.append('Forr_p1', row.Forr_p1)
      File.append('Foor_h41', row.Foor_h41)
      File.append('Foor_p2', row.Foor_p2)
      const { data: res } = await this.$http.post(`${this.$admin}About_list4_listuppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.bootomimgfile = ''
      this.$refs[`bootomimgfilere${index}`][0].clearFiles()
      this.AboutCn()
      this.AboutEn()
    },
    async About_list4_listuppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Forr_h3', row.Forr_h3)
      File.append('Forr_h4', row.Forr_h4)
      File.append('Forr_p1', row.Forr_p1)
      File.append('Foor_h41', row.Foor_h41)
      File.append('Foor_p2', row.Foor_p2)
      const { data: res } = await this.$http.post(`${this.$admin}About_list4_listuppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.AboutEn()
    },
    Addsafe () {
      this.About_dataCn.About_forr.push({
        Id: 0,
        Imgsrc: '',
        Forr_h3: '',
        Forr_h4: '',
        Forr_p1: '',
        Foor_h41: '',
        Foor_p2: ''
      })
      this.About_dataEn.About_forr.push({
        Id: 0,
        Imgsrc: '',
        Forr_h3: '',
        Forr_h4: '',
        Forr_p1: '',
        Foor_h41: '',
        Foor_p2: ''
      })
    },
    async Deletesafe (cnid, imgname, enid) {
      const { data: res } = await this.$http.post(`${this.$admin}About_list4_listuppcn_del?cnid=${cnid}&imgname=${imgname}&enid=${enid}`)
      if (res.status !== 200) return alert('删除失败')
      this.AboutCn()
      this.AboutEn()
      // console.log(cnid, imgname, enid)
    }
  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
