<template>
<div id="app">

    <!--路由暂位符-->
    <router-view v-if="isRouterAlicve"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlicve: true
    }
  },
  created () {
    // this.IPfun()
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  methods: {
    reload () {
      this.isRouterAlicve = false
      this.$nextTick(function () {
        this.isRouterAlicve = true
      })
    },
    async IPfun () {
      const { data: res } = await this.$http.get('/IP')
      if (res.status !== 200) return
      if (res.response.Data.country === '中国') {
        if (res.response.Data.region === '台湾' || res.response.Data.region === '香港' || res.response.Data.region === '澳门') {
          window.location.href = 'http://www.nienyi.com'
        }
      } else {
        window.location.href = 'http://www.nienyi.com'
      }
    }

  }
}
</script>

<style>

</style>
