<template>
  <div>
    <el-card class="box-card">
      <span class="titletext">中文英文安全宣言</span>
      <el-row>
        <el-col :span="12">
          <span class="titletext">中文安全宣言</span>
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="Internship_dataCn.About_forr" label-width="80px">
              <el-form-item label="大标题">
                <el-input v-model="Internship_dataCn.H2text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="Internship_dataCn.H3text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input  v-model="Internship_dataCn.H4text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input  v-model="Internship_dataCn.H5text"></el-input>
              </el-form-item>
              <el-form-item label="图片">
                <el-image style="width: 360px;height: auto;" :src="`${$FilesImg}${Internship_dataCn.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-form-item>
              <el-form-item label="图片名">
                <el-input v-model="Internship_dataCn.Imgsrc" disabled></el-input>
              </el-form-item>
              <el-form-item label="上传图片">
                <el-upload class="upload-demo" style="text-align: center;" ref="logofileref" action="" drag :limit="1"
                  :http-request="logofileref" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Internship_uppcn(Internship_dataCn)" >上传修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <span class="titletext">英文安全宣言</span>
          <div class="grid-content bg-purple-light">
            <el-form ref="form" :model="Internship_dataEn" label-width="80px">
              <el-form-item label="大标题">
                <el-input v-model="Internship_dataEn.H2text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input v-model="Internship_dataEn.H3text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input  v-model="Internship_dataEn.H4text"></el-input>
              </el-form-item>
              <el-form-item label="小标题">
                <el-input  v-model="Internship_dataEn.H5text"></el-input>
              </el-form-item>
              <el-form-item label="图片名">
                <el-input v-model="Internship_dataEn.Imgsrc"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="Internship_uppen(Internship_dataEn)" >修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    <el-card class="box-card">
      <span class="titletext">列表一</span>
          <el-row>
            <el-col :span="12"><div class="grid-content bg-purple teshu">
              <span class="titletext">中文</span>
              <el-table :data="Internship_dataCn.Internship_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="文章" align="center"></el-table-column>
              <el-table-column align="center"  width="150"  fixed="right">
              <template slot="header">
                <el-button type="primary" @click="list1_addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="list1funcn(scope.row)" size="small">修改</el-button>
                <el-button type="danger"  @click="Internship_list1delcn(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
              </div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light teshu">
              <span class="titletext">英文</span>
              <el-table :data="Internship_dataEn.Internship_list1" border style="width: 100%; margin-bottom: auto; margin: auto;">
              <el-table-column type="index" width="50" align="center"> </el-table-column>
              <el-table-column prop="Title" label="标题" align="center"></el-table-column>
              <el-table-column prop="Text" label="文章" align="center"></el-table-column>
              <el-table-column align="center"  width="150"  fixed="right">
              <template slot="header">
                <el-button type="primary" @click="list1_adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
              </template>
              <template slot-scope="scope">
                <el-button type="primary" @click="list1funen(scope.row)" size="small">修改</el-button>
                <el-button type="danger" @click="Internship_list1delen(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
            </div>
          </el-col>
        </el-row>
        <el-dialog title="添加中文列表一" :modal-append-to-body='false' :visible.sync="list1_addcn" width="500px">
          <el-form :model="addlist1_list">
            <el-form-item label="标题">
              <el-input v-model="addlist1_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="3" v-model="addlist1_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list1_addcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list1addcn(addlist1_list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="添加英文列表一" :modal-append-to-body='false' :visible.sync="list1_adden" width="500px">
          <el-form :model="addlist1_list">
            <el-form-item label="标题">
              <el-input v-model="addlist1_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="3" v-model="addlist1_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list1_adden = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list1adden(addlist1_list)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改中文列表一" :modal-append-to-body='false' :visible.sync="list1_uppcn" width="500px">
          <el-form :model="upplist1_list">
            <el-form-item label="标题">
              <el-input v-model="upplist1_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="3" v-model="upplist1_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list1_uppcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list1uppcn(upplist1_list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改英文列表一" :modal-append-to-body='false' :visible.sync="list1_uppen" width="500px">
          <el-form :model="upplist1_list">
            <el-form-item label="标题">
              <el-input v-model="upplist1_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="3" v-model="upplist1_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list1_uppen = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list1uppen(upplist1_list)">确 定</el-button>
          </div>
        </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    <el-card class="box-card">
      <span class="titletext">列表二</span>
      <el-row>
        <el-col :span="12"><div class="grid-content bg-purple teshu">
          <span class="titletext">中文</span>
           <el-table :data="Internship_dataCn.Internship_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="标题" align="center"></el-table-column>
          <el-table-column prop="Text" label="文章" align="center"></el-table-column>
          <el-table-column align="center"  width="150" fixed="right">
          <template slot="header">
            <el-button type="primary" @click="list2_addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="list2funcn(scope.row)" size="small">修改</el-button>
            <el-button type="danger"  @click="Internship_list2delcn(scope.row)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-light teshu">
          <span class="titletext">英文</span>
           <el-table :data="Internship_dataEn.Internship_list2" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="标题" align="center"></el-table-column>
          <el-table-column prop="Text" label="文章" align="center"></el-table-column>
          <el-table-column align="center"  width="150" fixed="right">
          <template slot="header">
            <el-button type="primary" @click="list2_adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="list2funen(scope.row)" size="small">修改</el-button>
            <el-button type="danger" @click="Internship_list2delen(scope.row)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
        </div></el-col>
        <el-dialog title="添加中文列表二" :modal-append-to-body='false' :visible.sync="list2_addcn" width="600px">
          <el-form :model="addlist2_list">
            <el-form-item label="标题">
              <el-input v-model="addlist2_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="addlist2_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list2_addcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list2addcn(addlist2_list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="添加英文列表二" :modal-append-to-body='false' :visible.sync="list2_adden" width="600px">
          <el-form :model="addlist2_list">
            <el-form-item label="标题">
              <el-input v-model="addlist2_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="addlist2_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list2_adden = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list2adden(addlist2_list)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改中文列表二" :modal-append-to-body='false' :visible.sync="list2_uppcn" width="600px">
          <el-form :model="upplist2_list">
            <el-form-item label="标题">
              <el-input v-model="upplist2_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="upplist2_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list2_uppcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list2uppcn(upplist2_list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改英文列表二" :modal-append-to-body='false' :visible.sync="list2_uppen" width="600px">
          <el-form :model="upplist2_list">
            <el-form-item label="标题">
              <el-input v-model="upplist2_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="upplist2_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list2_uppen = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list2uppen(upplist2_list)">确 定</el-button>
          </div>
        </el-dialog>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    <el-card class="box-card">
      <span class="titletext">列表三</span>
      <el-row>
        <el-col :span="24"><div class="grid-content bg-purple-dark teshu">
          <span class="titletext">中文</span>
          <el-table :data="Internship_dataCn.Internship_list3" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="Imgsrc" label="图名" align="center"></el-table-column>
          <el-table-column  label="图片" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Title" label="按钮文案" align="center"></el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column prop="H3text" label="宣言" align="center"></el-table-column>
          <el-table-column label="是否展开" align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Vishow" active-value="true" inactive-value="false" disabled></el-switch>
                </template>
          </el-table-column>
          <el-table-column prop="Xname" label="学校" align="center"></el-table-column>
          <el-table-column fixed="right" width="150" align="center">
          <template slot="header">
            <el-button type="primary" @click="list3_addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="list3funcn(scope.row)" size="small">修改</el-button>
            <el-button type="danger"  @click="Internship_list3delcn(scope.row)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
      </el-row>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    <el-card class="box-card">
      <span class="titletext">列表三</span>
      <el-row>
        <el-col :span="24"><div class="grid-content bg-purple-dark teshu">
          <span class="titletext">英文</span>
           <el-table :data="Internship_dataEn.Internship_list3" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="Imgsrc" label="图名" align="center"></el-table-column>
          <el-table-column  label="图片" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Title" label="按钮文案" align="center"></el-table-column>
          <el-table-column prop="Text" label="文本" align="center"></el-table-column>
          <el-table-column prop="H3text" label="宣言" align="center"></el-table-column>
          <el-table-column label="是否展开" align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.Vishow" active-value="true" inactive-value="false" disabled></el-switch>
                </template>
            </el-table-column>
          <el-table-column prop="Xname" label="学校" align="center"></el-table-column>
          <el-table-column fixed="right" width="150" align="center">
            <template slot="header">
            <el-button type="primary" @click="list3_adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="list3funen(scope.row)" size="small">修改</el-button>
            <el-button type="danger" @click="Internship_list3delen(scope.row)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
      </el-row>
      <el-dialog title="添加中文列表三" :modal-append-to-body='false' :visible.sync="list3_addcn">
          <el-form :model="addlist3_list">
            <el-form-item label="姓名">
              <el-input v-model="addlist3_list.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="上传图片">
                <el-upload class="upload-demo" style="text-align: center;" ref="list3fileref" action="" drag :limit="1"
                  :http-request="list3fileref" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </el-form-item>
            <el-form-item label="按钮文案">
              <el-input v-model="addlist3_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="addlist3_list.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="宣言">
              <el-input v-model="addlist3_list.H3text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否展开">
              <el-switch v-model="addlist3_list.Vishow" active-value="true" inactive-value="false" ></el-switch>
            </el-form-item>
            <el-form-item label="学校名称">
              <el-input v-model="addlist3_list.Xname" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list3_addcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list3addcn(addlist3_list)" >确 定</el-button>
          </div>
      </el-dialog>
      <el-dialog title="添加英文列表三" :modal-append-to-body='false' :visible.sync="list3_adden">
          <el-form :model="addlist3_list">
            <el-form-item label="姓名">
              <el-input v-model="addlist3_list.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图片">
               <el-input v-model="addlist3_list.Imgsrc" autocomplete="off"></el-input>
              </el-form-item>
            <el-form-item label="按钮文案">
              <el-input v-model="addlist3_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="addlist3_list.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="宣言">
              <el-input v-model="addlist3_list.H3text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否展开">
              <el-switch v-model="addlist3_list.Vishow" active-value="true" inactive-value="false"></el-switch>
            </el-form-item>
            <el-form-item label="学校名称">
              <el-input v-model="addlist3_list.Xname" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list3_adden = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list3adden(addlist3_list)" >确 定</el-button>
          </div>
      </el-dialog>
      <el-dialog title="修改中文列表三" :modal-append-to-body='false' :visible.sync="list3_uppcn">
          <el-form :model="upplist3_list">
            <el-form-item label="姓名">
              <el-input v-model="upplist3_list.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="上传图片">
                <el-upload class="upload-demo" style="text-align: center;" ref="list3filerefgx" action="" drag :limit="1"
                  :http-request="list3filerefgx" :auto-upload="false">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </el-form-item>
            <el-form-item label="按钮文案">
              <el-input v-model="upplist3_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="upplist3_list.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="宣言">
              <el-input v-model="upplist3_list.H3text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否展开">
              <el-switch v-model="upplist3_list.Vishow" active-value="true" inactive-value="false" ></el-switch>
            </el-form-item>
            <el-form-item label="学校名称">
              <el-input v-model="upplist3_list.Xname" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list3_uppcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list3uppcn(upplist3_list)" >确 定</el-button>
          </div>
      </el-dialog>
      <el-dialog title="修改英文列表三" :modal-append-to-body='false' :visible.sync="list3_uppen">
          <el-form :model="upplist3_list">
            <el-form-item label="姓名">
              <el-input v-model="upplist3_list.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图片">
               <el-input v-model="upplist3_list.Imgsrc" autocomplete="off"></el-input>
              </el-form-item>
            <el-form-item label="按钮文案">
              <el-input v-model="upplist3_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="upplist3_list.Text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="宣言">
              <el-input v-model="upplist3_list.H3text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否展开">
              <el-switch v-model="upplist3_list.Vishow" active-value="true" inactive-value="false"></el-switch>
            </el-form-item>
            <el-form-item label="学校名称">
              <el-input v-model="upplist3_list.Xname" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list3_uppen = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list3uppen(upplist3_list)" >确 定</el-button>
          </div>
      </el-dialog>
    </el-card>
    <hr class="w-100 mt-5 mb-5"/>
    <el-card class="box-card">
      <span class="titletext">列表四</span>
      <el-row>
        <el-col :span="12"><div class="grid-content bg-purple">
          <span class="titletext">中文</span>
           <el-table :data="Internship_dataCn.Internship_list4" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="名称" align="center"></el-table-column>
          <el-table-column prop="Text" label="名称" align="center"></el-table-column>
          <el-table-column fixed="right"  width="150" align="center">
          <template slot="header">
            <el-button type="primary" @click="list4_addcn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="list4funcn(scope.row)" size="small">修改</el-button>
            <el-button type="danger"  @click="Internship_list4delcn(scope.row)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-light">
          <span class="titletext">英文</span>
           <el-table :data="Internship_dataEn.Internship_list4" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Title" label="名称" align="center"></el-table-column>
          <el-table-column prop="Text" label="名称" align="center"></el-table-column>
          <el-table-column fixed="right"  width="150" align="center">
          <template slot="header">
            <el-button type="primary" @click="list4_adden = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="list4funen(scope.row)" size="small">修改</el-button>
            <el-button type="danger" @click="Internship_list4delen(scope.row)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
      </el-row>
      <el-dialog title="添加中文列表四" :modal-append-to-body='false' :visible.sync="list4_addcn" width="600px">
          <el-form :model="addlist4_list">
            <el-form-item label="标题">
              <el-input v-model="addlist4_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="addlist4_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list4_addcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list4addcn(addlist4_list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="添加英文列表四" :modal-append-to-body='false' :visible.sync="list4_adden" width="600px">
          <el-form :model="addlist4_list">
            <el-form-item label="标题">
              <el-input v-model="addlist4_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="addlist4_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list4_adden = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list4adden(addlist4_list)">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改中文列表四" :modal-append-to-body='false' :visible.sync="list4_uppcn" width="600px">
          <el-form :model="upplist4_list">
            <el-form-item label="标题">
              <el-input v-model="upplist4_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="upplist4_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list4_uppcn = false">取 消</el-button>
              <el-button type="primary"  @click="Internship_list4uppcn(upplist4_list)" >确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改英文列表四" :modal-append-to-body='false' :visible.sync="list4_uppen" width="600px">
          <el-form :model="upplist4_list">
            <el-form-item label="标题">
              <el-input v-model="upplist4_list.Title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章">
            <el-input type="textarea" :rows="8" v-model="upplist4_list.Text" autocomplete="off"></el-input>
          </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="list4_uppen = false">取 消</el-button>
              <el-button type="primary" @click="Internship_list4uppen(upplist4_list)">确 定</el-button>
          </div>
        </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Internship_dataCn: {
        H2text: '',
        H3text: '',
        H4text: '',
        H5text: '',
        Imgsrc: '',
        Internship_list1: [],
        Internship_list2: [],
        Internship_list3: [],
        Internship_list4: []
      },
      Internship_dataEn: {
        H2text: '',
        H3text: '',
        H4text: '',
        H5text: '',
        Imgsrc: '',
        Internship_list1: [],
        Internship_list2: [],
        Internship_list3: [],
        Internship_list4: []
      },
      logofile: '',
      list1_addcn: false,
      list1_adden: false,
      list1_uppcn: false,
      list1_uppen: false,
      list2_addcn: false,
      list2_adden: false,
      list2_uppcn: false,
      list2_uppen: false,
      list3_addcn: false,
      list3_adden: false,
      list3_uppcn: false,
      list3_uppen: false,
      list4_addcn: false,
      list4_adden: false,
      list4_uppcn: false,
      list4_uppen: false,
      addlist1_list: {
        Title: '',
        Text: ''
      },
      addlist2_list: {
        Title: '',
        Text: ''
      },
      addlist3_list: {
        Id: 0,
        Name: '',
        Imgsrc: '',
        Title: '',
        Text: '',
        H3text: '',
        Vishow: '',
        Xname: ''
      },
      addlist4_list: {
        Title: '',
        Text: ''
      },
      upplist3_list: {
        Id: 0,
        Name: '',
        Imgsrc: '',
        Title: '',
        Text: '',
        H3text: '',
        Vishow: '',
        Xname: ''
      },
      upplist1_list: {
        Id: '',
        Title: '',
        Text: ''
      },
      upplist2_list: {
        Id: '',
        Title: '',
        Text: ''
      },
      upplist4_list: {
        Id: '',
        Title: '',
        Text: ''
      },
      list3file: ''
    }
  },
  created () {
    this.InternshipCn()
    this.InternshipEn()
  },
  mounted () {

  },
  methods: {
    async InternshipCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}Internship`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Internship_dataCn = res.response
    },
    async InternshipEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}Internship`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Internship_dataEn = res.response
    },
    logofileref (param) {
      this.logofile = param.file
    },
    async Internship_uppcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.logofileref.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('H2text', row.H2text)
      File.append('H3text', row.H3text)
      File.append('H4text', row.H4text)
      File.append('H5text', row.H5text)
      File.append('Img1', this.logofile)
      const { data: res } = await this.$http.post(`${this.$admin}Internship_uppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.logofile = ''
      this.$refs.logofileref.clearFiles()
      this.InternshipCn()
    },
    async Internship_uppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('H2text', row.H2text)
      File.append('H3text', row.H3text)
      File.append('H4text', row.H4text)
      File.append('H5text', row.H5text)
      File.append('Imgsrc', row.Imgsrc)
      const { data: res } = await this.$http.post(`${this.$admin}Internship_uppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.logofile = ''
      this.InternshipEn()
    },
    async Internship_list1addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list1addcn`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.InternshipCn()
      this.list1_addcn = false
    },
    async Internship_list1adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list1adden`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.InternshipEn()
      this.list1_adden = false
    },
    list1funcn (row) {
      this.upplist1_list = row
      this.list1_uppcn = true
    },
    list1funen (row) {
      this.upplist1_list = row
      this.list1_uppen = true
    },
    async Internship_list1uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list1uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.InternshipCn()
      this.list1_uppcn = false
    },
    async Internship_list1uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list1uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.InternshipEn()
      this.list1_uppen = false
    },
    async Internship_list1delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list1delcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipCn()
    },
    async Internship_list1delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list1delen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipEn()
    },
    async Internship_list2addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list2addcn`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.InternshipCn()
      this.list2_addcn = false
    },
    async Internship_list2adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list2adden`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.InternshipEn()
      this.list2_adden = false
    },
    async Internship_list2delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list2delcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipCn()
    },
    async Internship_list2delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list2delen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipEn()
    },
    list2funcn (row) {
      this.upplist2_list = row
      this.list2_uppcn = true
    },
    list2funen (row) {
      this.upplist2_list = row
      this.list2_uppen = true
    },
    async Internship_list2uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list2uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.InternshipCn()
      this.list2_uppcn = false
    },
    async Internship_list2uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list2uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.InternshipEn()
      this.list2_uppen = false
    },
    list3funcn (row) {
      this.upplist3_list = row
      this.list3_uppcn = true
    },
    list3funen (row) {
      this.upplist3_list = row
      this.list3_uppen = true
    },
    list3fileref (param) {
      this.list3file = param.file
    },
    async Internship_list3addcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.list3fileref.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('H3text', row.H3text)
      File.append('Vishow', row.Vishow)
      File.append('Xname', row.Xname)
      File.append('Img1', this.list3file)
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list3addcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list3file = ''
      this.$refs.list3fileref.clearFiles()
      this.list3_addcn = false
      this.InternshipCn()
    },
    async Internship_list3adden (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('H3text', row.H3text)
      File.append('Vishow', row.Vishow)
      File.append('Xname', row.Xname)
      File.append('Imgsrc', row.Imgsrc)
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list3adden`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list3_adden = false
      this.InternshipEn()
    },
    list3filerefgx (param) {
      this.list3file = param.file
    },
    async Internship_list3uppcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.list3filerefgx.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('H3text', row.H3text)
      File.append('Vishow', row.Vishow)
      File.append('Xname', row.Xname)
      File.append('Img1', this.list3file)
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list3uppcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list3file = ''
      this.$refs.list3filerefgx.clearFiles()
      this.list3_uppcn = false
      this.InternshipCn()
    },
    async Internship_list3uppen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Name', row.Name)
      File.append('Title', row.Title)
      File.append('Text', row.Text)
      File.append('H3text', row.H3text)
      File.append('Vishow', row.Vishow)
      File.append('Xname', row.Xname)
      File.append('Imgsrc', row.Imgsrc)
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list3uppen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.list3_uppen = false
      this.InternshipEn()
    },
    async Internship_list3delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list3delcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipCn()
    },
    async Internship_list3delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list3delen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipEn()
    },
    async Internship_list4addcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list4addcn`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.InternshipCn()
      this.list4_addcn = false
    },
    async Internship_list4adden (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list4adden`, row)
      if (res.status !== 200) return alert('添加失败')
      this.$message.success('添加成功')
      this.InternshipEn()
      this.list4_adden = false
    },
    async Internship_list4delcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list4delcn`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipCn()
    },
    async Internship_list4delen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list4delen`, row)
      if (res.status !== 200) return alert('删除失败')
      this.$message.success('删除成功')
      this.InternshipEn()
    },
    list4funcn (row) {
      this.upplist4_list = row
      this.list4_uppcn = true
    },
    list4funen (row) {
      this.upplist4_list = row
      this.list4_uppen = true
    },
    async Internship_list4uppcn (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list4uppcn`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.InternshipCn()
      this.list4_uppcn = false
    },
    async Internship_list4uppen (row) {
      const { data: res } = await this.$http.post(`${this.$admin}Internship_list4uppen`, row)
      if (res.status !== 200) return alert('修改失败')
      this.$message.success('修改成功')
      this.InternshipEn()
      this.list4_uppen = false
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 100px;
    height: 100px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
