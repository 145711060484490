<template>
  <div>
    <section class="unpad text-center ">
      <div class="row--gapless row no-gutters">
        <div class="col-sm-4 col-12"><a :href="Foorm_data.Href1" class="block">
            <div data-overlay="5" class="feature feature-7 boxed imagebg mb-0">
              <div class="background-image-holder" :style="`background: url(${$FilesImg}${Foorm_data.Imgsrc1}); opacity: 1;`">
                <img alt="Putting two puzzles together with sunset pouring in the room" :src="`${$FilesImg}${Foorm_data.Imgsrc1}`"></div>
              <h4 class="pos-vertical-center" v-html="Foorm_data.Text1"></h4>
            </div>
          </a>
        </div>
        <!---->
        <div class="col-sm-4 col-12"><a :href="Foorm_data.Href2" data-notification-link="contact" class="block">
            <div data-overlay="5" class="feature feature-7 boxed imagebg mb-0">
              <div class="background-image-holder" :style="`background: url(${$FilesImg}${Foorm_data.Imgsrc2}); opacity: 1;`">
                <img alt="People are brainstorming in the meeting room while a woman is writing her idea on the glass"
                  :src="`${$FilesImg}${Foorm_data.Imgsrc2}`"></div>
              <h4 class="pos-vertical-center" v-html="Foorm_data.Text2"></h4>
            </div>
          </a>
        </div>
        <div class="col-sm-4 col-12"><a :href="Foorm_data.Href3" class="block">
            <div data-overlay="5" class="feature feature-7 boxed imagebg mb-0">
              <div class="background-image-holder" :style="`background: url(${$FilesImg}${Foorm_data.Imgsrc3}); opacity: 1;`">
                <img alt="Blue world map with international connections" :src="`${$FilesImg}${Foorm_data.Imgsrc3}`">
              </div>
              <h4 class="pos-vertical-center" v-html="Foorm_data.Text3"></h4>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data () {
    return {
      Foorm_data: []
    }
  },
  created () {
    this.Foorm()
  },
  mounted () {
  },
  methods: {
    async Foorm () {
      const { data: res } = await this.$http.get('Foorm')
      if (res.status !== 200) return this.$message.success('访问异常')
      this.Foorm_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
</style>
